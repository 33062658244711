import React from "react";
import Styles from "./passwordResetLinkExpiry.module.scss";
import emptyStateStyle from "../../../Shared/empty-state/emptyState.module.scss";
import { linkBroken } from "../../../constants/emptyStatesVariables";
import EmptyState from "../../../Shared/empty-state/emptyState";
import Toast from "../../../Shared/toast/toast";
import Button from "../../../Shared/button/button";
import {
  primaryButtonHoverStyle,
  primaryButtonStyle,
} from "../../../Shared/buttonStyles";
import { MEDIUM } from "../../../Shared/buttonSize";
import ErrorMessage from "../../../Shared/error-message/errorMessage";
import Input from "../../../Shared/input/input";
import {
  checkIsEmpty,
  isvalidEmailFirstChar,
  validEmail,
} from "../../../utils/validation";
import { publicEmail } from "../../../constants/publicEmail";
import { callApi } from "../../../api/fetch";
import { useHistory } from "react-router";

function PasswordResetLinkExpiry() {
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [isValidEmail, setIsValidEmail] = React.useState(false);
  const [toggleEmailInput, setToggleEmailInput] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [error, setError] = React.useState("");
  const [toast, setToast] = React.useState({
    message: "",
    type: "",
    show: false,
  });

  function toastTimeout(
    showToastValue: boolean,
    toastType: string,
    toastMessage: string
  ) {
    setToast((toast: any) => ({
      ...toast,
      type: toastType,
      message: toastMessage,
      show: showToastValue,
    }));
    setTimeout(() => {
      setToast((toast: any) => ({
        ...toast,
        show: !showToastValue,
      }));
    }, 8000);
    return;
  }

  function checkEmail() {
    if (checkIsEmpty(email)) {
      setIsValidEmail(false);
      setError("Email cannot be empty");
      return false;
    }
    if (!validEmail(email)) {
      setIsValidEmail(false);
      setError("Enter a valid email");
      return false;
    }
    if (isvalidEmailFirstChar(email[0])) {
      setIsValidEmail(false);
      setError("Email cannot start with a number");
      return false;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, domain] = email.split("@");
    if (publicEmail.includes(domain.toLocaleLowerCase())) {
      setError("Only work emails are allowed");
      return false;
    }
    return true;
  }

  async function handleResetPassword() {
    setLoading(true);
    try {
      await callApi("/user/password-reset", {
        method: "POST",
        data: {
          email: email,
          success_redirect: `${window.location.origin}/create-password`,
          failure_redirect: `${window.location.origin}/password-reset-link-expiry`,
        },
      })
        .then(() => {
          setLoading(false);
          history.replace("/password-reset-link-success");
        })
        .catch((error) => {
          setLoading(false);
          toastTimeout(true, "error", error.message);
        });
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div className={Styles.background}>
      {toast.show && (
        <Toast
          message={toast.message}
          type={toast.type}
          click={() =>
            setToast((toast: any) => ({
              ...toast,
              show: false,
            }))
          }
        />
      )}
      <EmptyState type={linkBroken}>
        <div>
          <div className={"py-2"}>
            <p className={`${emptyStateStyle.emptyStateHeadingText} mb-0`}>
              Password Reset Link Expired
            </p>
          </div>
          <div className="py-3">
            <p className={`mb-0 ${emptyStateStyle.emptyStateBodyText}`}>
              Hi there, looks like you are late in resetting your password. This
              link expires in 30 minutes.
            </p>
          </div>
          {toggleEmailInput ? (
            <div className="px-5">
              <Input
                type="email"
                name="email"
                id="email-reset-password"
                autoComplete="off"
                placeholder="Enter your work email"
                labelname="Registered Email"
                hasError={error}
                isValid={isValidEmail}
                onChange={(event: any) => {
                  setEmail(event.target.value);
                  setIsValidEmail(true);
                  setError("");
                }}
                onBlur={checkEmail}
                clear={false}
              />
              {error && <ErrorMessage>{error}</ErrorMessage>}
              <div className="py-4 text-center">
                <div className="pb-2">
                  <Button
                    id="resend-link-button"
                    hoveredStyle={primaryButtonHoverStyle}
                    size={MEDIUM}
                    type="submit"
                    isLoading={loading}
                    style={primaryButtonStyle}
                    onClick={handleResetPassword}
                  >
                    Resend Link
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <div className="py-2">
              <p className={`mb-0 ${emptyStateStyle.emptyStateBodyText}`}>
                To make a new request click{" "}
                <button
                  id="resend-link"
                  className={Styles.resendEmailLink}
                  onClick={() => setToggleEmailInput(true)}
                >
                  Resend Link
                </button>
                .
              </p>
            </div>
          )}
        </div>
      </EmptyState>
    </div>
  );
}

export default PasswordResetLinkExpiry;
