// Hooks
import React, { useEffect, useRef } from "react";

// Styles
import { ACCENTCOLOR } from "../colors";
import Styles from "./datatable.module.scss";

// Components
import Loading from "../loading/loading";
import Pagination from "../pagination/pagination";

// Constants
import NotFoundImg from "../../assets/images/no-record-found.png";

type Props = {
  headers: any;
  children: any;
  loading: boolean;
  rows: any;
  pageNumberLimit?: number;
  setActivePageNumber?: (value: any) => void;
  totalNumberOfPages?: number;
  postPerPage?: number;
  tableHeader?: any;
  headerTitle?: string;
  purpose?: string;
  bodyClassName?: string;
  pagination?: boolean;
  customStyle?: any;
  headerClass?: string;
  emptyState?: any;
};

const DataTable = ({
  headers,
  children,
  loading,
  rows,
  pageNumberLimit,
  setActivePageNumber,
  totalNumberOfPages = 0,
  postPerPage,
  tableHeader,
  headerTitle,
  purpose = "",
  bodyClassName,
  customStyle,
  pagination = true,
  headerClass,
  emptyState,
}: Props) => {
  const defaultPageNumberLimit = pageNumberLimit || 5;
  const [defaultPostPerPage] = React.useState(postPerPage || 50);
  const [minPageNumberLimit, setMinPageNumberLimit] = React.useState(1);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = React.useState(
    defaultPageNumberLimit
  );
  const [defaultActivePageNumber, defaultSetActivePageNumber] = React.useState(
    1
  );

  const tableBodyRef: any = useRef();

  // TO set the fading class on scroll
  function setClasses() {
    const el = tableBodyRef?.current;
    if (!el) {
      return;
    }
    const isScrollable = el.scrollHeight > el.clientHeight;

    // GUARD: If element is not scrollable, remove all classes
    if (!isScrollable) {
      el.classList.remove(Styles.topOverflow, Styles.bottomOverflow);
      return;
    }

    // Otherwise, the element is overflowing!
    // Now we just need to find out which direction it is overflowing to (can be both)
    const isScrolledToBottom =
      el.scrollHeight <= el.clientHeight + el.scrollTop;
    const isScroledlToTop = el.scrollTop === 0;

    el.classList.toggle(Styles.topOverflow, !isScroledlToTop);
    el.classList.toggle(Styles.bottomOverflow, !isScrolledToBottom);
  }

  useEffect(() => {
    if (rows?.length > 0 && !loading) {
      tableBodyRef?.current?.addEventListener("scroll", () => {
        setClasses();
      });

      setClasses();
    }
  }, [loading, rows]);
  return (
    <>
      <div className={`${Styles.card} ${Styles.cardAnimation}`}>
        <div
          className={
            ["forDashboard_mini", "forDashboard"].includes(purpose)
              ? `pt-2 px-3 pb-0 ${Styles.cardHeader}`
              : `${
                  headerClass || ""
                } d-flex align-items-center pt-4 pl-3 pb-0 pr-3 ${
                  Styles.cardHeader
                }`
          }
        >
          {tableHeader ? (
            tableHeader
          ) : (
            <div>
              <p className={"mb-0 card_title_bold_text"}>
                {headerTitle || "Table Title"}
              </p>
            </div>
          )}
        </div>
        {loading ? (
          <div
            style={{ height: "200px" }}
            className="d-flex align-items-center justify-content-center"
          >
            <Loading loadingColor={ACCENTCOLOR} />
          </div>
        ) : rows?.length === 0 ? (
          <div
            style={
              purpose === "forDashboard"
                ? { height: "35vh" }
                : { height: "40vh", overflow: "auto" }
            }
            className="d-flex align-items-center justify-content-center"
          >
            {emptyState ? (
              emptyState
            ) : (
              <div className="d-flex flex-column justify-content-center align-items-center">
                <img
                  src={NotFoundImg}
                  alt="no-record-found"
                  width={purpose === "forDashboard" ? "100px" : "250px"}
                />

                <h5 className={"mb-0 mt-3 " + Styles.noRecordTitle}>
                  No records found!
                </h5>
                <span className={Styles.noRecordBody}>
                  Looks like you have no records yet in this category.
                </span>
              </div>
            )}
          </div>
        ) : (
          <div>
            <div className={Styles.tableOverlay} style={customStyle}>
              <table className={Styles.table}>
                <thead className={Styles.tableHeaderWrapper}>
                  <tr>
                    {headers.map((data: any, index: number) => {
                      return (
                        <th
                          key={"Datatable" + index}
                          className={"pb-1" + " " + (data?.className || "")}
                        >
                          <span className={"mb-0 table_header_text"}>
                            {data?.name}
                          </span>
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody
                  ref={tableBodyRef}
                  className={`${bodyClassName || Styles.tbodyScroll} ${
                    purpose === "forReport" || purpose === "forDevDoc"
                      ? ""
                      : "fadingeffect-table-"
                  }
                  `}
                >
                  {/* ${
                    purpose === "forDashboard_mini"
                      ? "scrollable-mini gradient_transparent"
                      : "scrollable gradient_transparent"
                  } */}
                  {children}
                </tbody>
              </table>
            </div>
          </div>
        )}
        {!loading &&
          rows?.length > 0 &&
          !["forDashboard", "forDashboard_mini", "forDevDoc"].includes(
            purpose
          ) &&
          pagination && (
            <div className="p-3">
              <div className=" m-0 d-flex align-items-center px-2">
                <p className={"mb-0 body_regular_text text-secondary-color"}>
                  {rows.length} rows per page
                </p>
                <div className="px-2 ml-auto">
                  {totalNumberOfPages > defaultPostPerPage && (
                    <Pagination
                      pageNumberLimit={defaultPageNumberLimit}
                      minPageNumberLimit={minPageNumberLimit}
                      maxPageNumberLimit={maxPageNumberLimit}
                      setMinPageNumberLimit={(value: number) =>
                        setMinPageNumberLimit(value)
                      }
                      setMaxPageNumberLimit={(value: number) =>
                        setMaxPageNumberLimit(value)
                      }
                      activePageNumber={defaultActivePageNumber}
                      total={totalNumberOfPages}
                      postPerPage={defaultPostPerPage}
                      navigateToPage={(value: number) => {
                        defaultSetActivePageNumber(value);
                        setActivePageNumber && setActivePageNumber(value);
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
      </div>
    </>
  );
};

export default DataTable;
