// Library Utilities
import React from "react";

// Components
import Input from "../../../../../Shared/input/input";
import ErrorMessage from "../../../../../Shared/error-message/errorMessage";
import Button from "../../../../../Shared/button/button";

// Constants
import CloseSvg from "../../../../../Shared/svg/close";
import Switch from "../../../../../Shared/toggle-switch/switch";
import { MEDIUM } from "../../../../../Shared/buttonSize";
import minusSvgSrc from "../../../../../assets/images/minus.svg";
import Toast from "../../../../../Shared/toast/toast";
import IpWhitelistingHelperText from "../ip-whitelisting-helper-text/ipWhitelistingHelperText";
import DisableConfirmationPopUp from "./disable-confirmation-popup/disableConfirmationPopUp";
import Info from "../../app-info/info-message/info";

// Styles
import Styles from "../app-settings.module.scss";
import {
  primaryButtonHoverStyle,
  primaryButtonStyle,
} from "../../../../../Shared/buttonStyles";
import { BACKGROUNDCOLOR, SECONDARYCOLOR } from "../../../../../Shared/colors";

// Functions
import { callApi } from "../../../../../api/fetch";

function IpWhiteListing({
  onAddingMultipleIpAddress,
  addIpAddressOnceRemoved,
  listOfIpAddress,
  removeIpAddress,
  setIPAddresses,
  ipAddresses,
  enableAddingIpAddress,
  setEnableAddingIpAddress,
  error,
  setError,
  onCancel,
  onSubmit,
  allIpWhitelisted,
  setToggleAllIpWhitelisting,
  setIsIpAddressUpdated,
  listOfRemovedIpAddress,
  setListOfRemovedIpAddress,
  zpinExists,
  savedIpCount,
}: any) {
  const helperPoints = [
    {
      question: "1. An API key at the front end.",
      answer:
        "If you are using an API key on your frontend, there is a possibility of theft. We reccomend that you use the key on your backend and individually whitelist your IPs. This will keep your system from any possible security breach.",
    },
    {
      question: "2. If you are using Auto-Scaling in your systems.",
      answer:
        "In that case, you can route the traffic through a NAT gateway to create a static IP Address for whitelisting, this can limit the number of hits to your system and make it robust.",
    },
    {
      question: "3. Disable IP whitelisting and allow traffic from ALL IPs.",
      answer:
        "Please note that we don't recommend to allow All IPs in your app. This is not secure and can lead to leaked information.",
    },
  ];

  const [toggleConfirmationPopUp, setToggleConfirmationPopUp] = React.useState(
    false
  );
  const [inlineLoading, setInlineLoading] = React.useState(false);

  const [loading, setLoading] = React.useState(false);

  const [toast, setToast] = React.useState({
    message: "",
    type: "",
    show: false,
  });

  function toastTimeout(
    showToastValue: boolean,
    toastType: string,
    toastMessage: string
  ) {
    setToast((toast: any) => ({
      ...toast,
      type: toastType,
      message: toastMessage,
      show: showToastValue,
    }));
    setTimeout(() => {
      setToast((toast: any) => ({
        ...toast,
        show: !showToastValue,
      }));
    }, 8000);
    return;
  }
  async function sendOtp(purpose: string, listOfIps: any) {
    try {
      if (toggleConfirmationPopUp) {
        setToggleConfirmationPopUp(false);
      }
      setInlineLoading(false);
      setLoading(false);

      if (!zpinExists) {
        const res = await callApi("/events?action=send_otp", {
          method: "POST",
          data: {
            purpose: purpose,
          },
        });
        const { relay_id } = res;
        onSubmit({ relay_id: relay_id, ips: listOfIps });
      } else {
        onSubmit({ withZpin: true, ips: listOfIps });
      }
    } catch (err) {
      setLoading(false);
      setInlineLoading(false);
      toastTimeout(true, "error", (err as Error).message);
    }
  }
  return (
    <React.Fragment>
      {toggleConfirmationPopUp && (
        <DisableConfirmationPopUp
          isLoading={inlineLoading}
          onConfirm={() => {
            setInlineLoading(true);
            sendOtp("Updating the IP list", ["ALL"]);
          }}
          onCancel={() => setToggleConfirmationPopUp(false)}
        />
      )}
      <div className={Styles.formBG}>
        {toast.show ? (
          <Toast
            type={toast.type}
            message={toast.message}
            click={() =>
              setToast((toast: any) => ({
                ...toast,
                show: false,
              }))
            }
          />
        ) : null}
        <div className="ml-3">
          <Info message="Zoop has whitelisted all the IPs by default. We strongly recommend that you enable limited whitelisting of IP addresses to secure your system and be threat-free."></Info>
        </div>
        <div className="d-flex align-items-center px-3 mt-4 pr-5">
          {/* {!enableAddingIpAddress && ( */}
          <div>
            <p className="mb-0 title_semibold_text">Limited IP Whitelisting</p>
            <p className={Styles.subText}>
              Enable limited whitelisting to increase system security.
            </p>
          </div>
          {/* )} */}
          <div className="ml-auto">
            <Switch
              positiveLabel="Enable"
              negativeLabel="Disable"
              id="disable-ip-whitelisting-switch"
              checked={allIpWhitelisted}
              onChange={() => {
                if (allIpWhitelisted === true && savedIpCount !== 0) {
                  setToggleConfirmationPopUp(true);
                } else if (allIpWhitelisted === true) {
                  setToggleAllIpWhitelisting(false);
                  setListOfRemovedIpAddress([]);
                } else {
                  setToggleAllIpWhitelisting(true);
                }
              }}
            />
          </div>
        </div>
        {allIpWhitelisted ? (
          <div className="pb-3 ">
            {enableAddingIpAddress ? (
              <div className="container-fluid p-0">
                <div>
                  <div className="col-md-6 px-3 d-flex align-items-start relative">
                    <div className="mr-2 w-100">
                      <Input
                        showInfo={true}
                        tooltipMessage="You can add multiple IP Address comma separated"
                        type="text"
                        name="ipAddress"
                        id="ipAddress"
                        value={ipAddresses}
                        autoComplete="off"
                        placeholder="Enter single or multiple IP Address"
                        hasError={error}
                        // labelname="IP Address"
                        onChange={(event: any) => {
                          setIPAddresses(event.target.value);
                          setError("");
                        }}
                        customInputClass="border-radius-10 w-100"
                      />
                      {error ? <ErrorMessage>{error}</ErrorMessage> : null}
                    </div>
                    {/* Show this check mark icon only if the input is filled  */}
                    {ipAddresses.length !== 0 && (
                      <div>
                        <div
                          id="ip-whitelisting"
                          className={`${Styles.checkIPAddressBack} d-flex align-items-center justify-content-center`}
                          onClick={() => onAddingMultipleIpAddress(ipAddresses)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="15"
                            viewBox="0 0 29.257 23.772"
                            className={Styles.iconColor}
                          >
                            <g>
                              <path
                                d="M1139.877 288.908a3 3 0 1 1 4.237-4.237l4.179 4.179 15.019-15.019a2.893 2.893 0 1 1 4.091 4.091l-18.614 18.614a1.5 1.5 0 0 1-1.4-.117z"
                                transform="translate(-1139 -272.975)"
                              />
                            </g>
                          </svg>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="py-2 px-3">
                <div
                  className={`${Styles.addIpAddressButton} d-flex align-items-center justify-content-center`}
                  id="ip-whitelisting-add"
                  onClick={() => {
                    setEnableAddingIpAddress(!enableAddingIpAddress);
                    setIPAddresses("");
                    setIsIpAddressUpdated(false);
                  }}
                >
                  <div className="px-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="8"
                      height="8"
                      viewBox="0 0 32.806 32.806"
                      className={Styles.iconColor}
                    >
                      <path d="M19.813 1.453V12.67a.637.637 0 0 0 .646.646h10.894a1.447 1.447 0 0 1 1.453 1.453v3.268a1.447 1.447 0 0 1-1.453 1.453H20.458a.637.637 0 0 0-.646.646v11.217a1.447 1.447 0 0 1-1.453 1.453h-3.913a1.447 1.447 0 0 1-1.453-1.453V20.136a.637.637 0 0 0-.646-.646H1.453A1.447 1.447 0 0 1 0 18.037v-3.268a1.447 1.447 0 0 1 1.453-1.453h10.895a.637.637 0 0 0 .646-.646V1.453A1.447 1.447 0 0 1 14.446 0h3.914a1.447 1.447 0 0 1 1.453 1.453z" />
                    </svg>
                  </div>
                  <div className="px-1">
                    <p className={`mb-0 ${Styles.addIpAddressButtonText}`}>
                      Add IP
                    </p>
                  </div>
                </div>
              </div>
            )}
            <div className="py-2 px-3 mt-4">
              {listOfIpAddress.length === 0 &&
              listOfRemovedIpAddress.length !== 0 ? null : (
                <>
                  {listOfIpAddress.length ? (
                    <div className={Styles.ipWhiteListTitle}>IP Whitelist</div>
                  ) : null}
                  <div className="d-flex align-items-center flex-wrap mt-2">
                    {listOfIpAddress.map((ipAddress: any) => {
                      return (
                        <div key={ipAddress}>
                          <div
                            className={`${Styles.addedService} px-3 py-2 d-flex align-items-center`}
                          >
                            <p className={`mb-0 px-2 ${Styles.serviceName}`}>
                              {ipAddress}
                            </p>
                            {enableAddingIpAddress && (
                              <div
                                className="px-1"
                                style={{ marginBottom: "2px" }}
                              >
                                <CloseSvg
                                  width="7"
                                  height="7"
                                  classes={Styles.svgColor}
                                  id="ip-whitelisting-close"
                                  onClick={() => {
                                    removeIpAddress(ipAddress);
                                    setIsIpAddressUpdated(true);
                                    setListOfRemovedIpAddress(
                                      listOfRemovedIpAddress.concat(ipAddress)
                                    );
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
            {listOfRemovedIpAddress.length !== 0 && enableAddingIpAddress ? (
              <div className="py-2 px-3">
                <p className={`mb-0 pt-2 ${Styles.markedForRemoval}`}>
                  Marked for removal
                </p>
                <div className="d-flex align-items-center flex-wrap mt-2">
                  {listOfRemovedIpAddress.map((ipAddress: any) => {
                    return (
                      <div className={"mr-2"} key={ipAddress}>
                        <div
                          className={`${Styles.removedService} px-3 py-2 d-flex align-items-center`}
                        >
                          <p
                            className={`mb-0 px-2 ${Styles.removedServiceText}`}
                          >
                            {ipAddress}
                          </p>
                          <div className="px-1" style={{ marginBottom: "2px" }}>
                            <img
                              src={minusSvgSrc}
                              alt="minus"
                              height="10"
                              style={{ cursor: "pointer" }}
                              id="ip-whitelisting-remove"
                              onClick={() => {
                                setListOfRemovedIpAddress(
                                  listOfRemovedIpAddress.filter((ip: any) => {
                                    return ipAddress !== ip;
                                  })
                                );
                                addIpAddressOnceRemoved(ipAddress);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : null}
            {enableAddingIpAddress && (
              <div className="p-4 d-flex align-items-center">
                {listOfIpAddress.length !== savedIpCount ||
                listOfRemovedIpAddress.length !== 0 ? (
                  <div className="py-2 mx-2">
                    <p
                      className={`mb-0 ${Styles.warningMessageForIPWhitelisting}`}
                    >
                      *Changes made in this section will require OTP
                      verification
                    </p>
                  </div>
                ) : null}
                <div className="ml-auto mr-3">
                  <Button
                    size={MEDIUM}
                    id="ip-whitelisting-remove-all"
                    onClick={() => {
                      if (listOfRemovedIpAddress.length !== 0) {
                        setListOfRemovedIpAddress([]);
                      }
                      onCancel();
                      setIsIpAddressUpdated(false);
                      // setEnableAddingIpAddress(!enableAddingIpAddress);
                      setIPAddresses("");
                      setError("");
                    }}
                    hoveredStyle={primaryButtonHoverStyle}
                    style={primaryButtonStyle}
                    type="submit"
                    className="bg-lite-accent-color"
                    btnTextClass="primary-color"
                  >
                    Cancel
                  </Button>
                </div>
                <div>
                  <Button
                    hoveredStyle={primaryButtonHoverStyle}
                    style={primaryButtonStyle}
                    disabled={
                      (listOfIpAddress.length === savedIpCount &&
                        listOfRemovedIpAddress.length === 0) ||
                      loading
                    }
                    id="ip-whitelisting-send-otp"
                    isLoading={loading}
                    onClick={() => {
                      setLoading(true);
                      sendOtp("Updating the IP list", listOfIpAddress);
                    }}
                    size={MEDIUM}
                  >
                    Save
                  </Button>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="pb-3 px-3">
            <div
              className={`${Styles.IpPlaceholder} d-flex align-items-center justify-content-center`}
              style={{ background: BACKGROUNDCOLOR }}
            >
              <p
                className="mb-0"
                style={{ color: SECONDARYCOLOR, fontSize: "14px" }}
              >
                ALL
              </p>
            </div>
          </div>
        )}
        <hr style={{ border: "1px solid #EBEBEB" }} />
        <IpWhitelistingHelperText helperPoints={helperPoints} />
      </div>
    </React.Fragment>
  );
}

export default IpWhiteListing;
