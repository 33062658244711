//User tries to downgrade the plan and hence warning Popup
import React from "react";

// Styles
import Styles from "../../../../Shared/popup.module.scss";
import classes from "./downgradeWarningPopUp.module.scss";
import {
  primaryButtonStyle,
  cancelButtonStyle,
  cancelButtonHoverStyle,
  primaryButtonHoverStyle,
} from "../../../../Shared/buttonStyles";

// constants
import WarningIllustration from "../../../../assets/images/downgrade_popup.svg";
import { MEDIUM } from "../../../../Shared/buttonSize";

// Components
import Button from "../../../../Shared/button/button";

function DowngradeWarningPopUp({
  planSelected,
  onEnableNow,
  onEnableLater,
  onCancel,
  isLoading,
}: any) {
  return (
    <div
      className={`d-flex align-items-center justify-content-center ${Styles.overlay}`}
    >
      <div className={`p-4 ${Styles.popUpContent}`} style={{ width: "500px" }}>
        <div className="py-2 text-center">
          <img
            src={WarningIllustration}
            alt="warning"
            style={{ width: "100px" }}
          />
        </div>
        <div className="py-2 text-center">
          <p className={`mb-0 ${classes.headingText}`}>
            {planSelected} Plan Selected{" "}
          </p>
        </div>
        <div className="py-2 px-4">
          <p className={`mb-0 ${classes.subText}`}>
            You have selected a plan of a lower value than the current plan
            which may incur higher transaction charges.{" "}
          </p>
        </div>
        <div className="py-2 px-4">
          <p className={`mb-0 ${classes.subText}`}>
            Click <span className={classes.spanText}>Enable Now</span> to
            activate the new plan as soon as the payment is made, else click{" "}
            <span className={classes.spanText}>Enable Later</span> to active
            this plan on expiry of your current plan.
          </p>
        </div>
        <div className="pt-3 d-flex align-items-center justify-content-center px-4">
          <div className="px-2">
            <Button
              hoveredStyle={cancelButtonHoverStyle}
              style={cancelButtonStyle}
              onClick={() => onEnableNow()}
              size={MEDIUM}
              id="z-billing-btn-enablenow"
            >
              Enable Now
            </Button>
          </div>
          <div className="px-2">
            <Button
              hoveredStyle={primaryButtonHoverStyle}
              style={primaryButtonStyle}
              isLoading={isLoading}
              onClick={() => onEnableLater()}
              size={MEDIUM}
              id="z-billing-btn-enablelater"
            >
              Enable Later
            </Button>
          </div>
        </div>
        <div className="py-3 d-flex justify-content-center">
          <div className="d-inline-flex">
            <p
              className={`mb-0 ${classes.cancelText}`}
              id="downgrade-warning-cancel"
              onClick={() => onCancel()}
            >
              Cancel
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DowngradeWarningPopUp;
