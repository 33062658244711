// Library Utilities
import React from "react";

// Components
import Button from "../../../../../Shared/button/button";

// Constants
import WarningIllustration from "../../../../../assets/images/Warning.svg";
import { MEDIUM } from "../../../../../Shared/buttonSize";

// Styles
import Styles from "../../../../../Shared/popup.module.scss";
import classes from "./unsavedChangesPopUp.module.scss";
import {
  primaryButtonHoverStyle,
  primaryButtonStyle,
} from "../../../../../Shared/buttonStyles";

function UnsavedChangesPopUp({
  onDiscardChanges,
  onCancel,
  unsavedSettingNames,
}: any) {
  return (
    <div
      className={`d-flex align-items-center justify-content-center ${Styles.overlay}`}
    >
      <div className={`p-4 ${Styles.popUpContent}`} style={{ width: "500px" }}>
        <div className="py-2 text-center">
          <img
            src={WarningIllustration}
            alt="warning"
            style={{ width: "100px" }}
          />
        </div>
        <div className="py-2 text-center">
          <p className={`mb-0 ${classes.headingText}`}>Unsaved Changes</p>
        </div>
        <div className="py-2 px-4">
          <p className={`mb-0 ${classes.subText}`}>
            You currently have unsaved changes in the following sections
          </p>
        </div>
        <div className="py-3">
          {unsavedSettingNames.map((setting: any) => {
            return (
              <div className="py-1 d-flex justify-content-center" key={setting}>
                <p className={`mb-0 ${classes.unsavedSettingName}`}>
                  {setting}
                </p>
              </div>
            );
          })}
        </div>
        <div className="pt-3 d-flex align-items-center justify-content-center px-4">
          <div className="px-2">
            <Button
              id="discard-unsaved-changes-popup
            "
              hoveredStyle={primaryButtonHoverStyle}
              style={primaryButtonStyle}
              onClick={() => onDiscardChanges()}
              size={MEDIUM}
            >
              Discard
            </Button>
          </div>
        </div>
        <div className="py-2 d-flex justify-content-center">
          <div className="d-inline-flex">
            <p
              id="cancel-unsaved-changes-popup"
              className={`mb-0 ${classes.discardText}`}
              onClick={() => onCancel()}
            >
              Cancel
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UnsavedChangesPopUp;
