import React from "react";

// Constants
import { TickSvgFill } from "../../../../../Shared/svg/tickSvg";

// Styles
import Styles from "../checkList.module.scss";
import {
  ACCENTCOLOR,
  SECONDARYCOLOR,
  SUCCESS,
} from "../../../../../Shared/colors";

function ChecklistItem({
  isStepCompleted,
  highlightCurrentStep,
  listHeader,
  stepNumber,
  ...props
}: any) {
  const showSuccess = (
    <TickSvgFill width="15" height="15" color={SUCCESS} margin="5px" />
  );
  return (
    <div className="px-3 pt-3">
      <div className="d-flex align-items-center">
        <div style={{ flexBasis: "10%" }}>
          <div
            className={`${Styles.step_number_wrapper} d-flex align-items-center justify-content-center`}
          >
            {isStepCompleted ? (
              showSuccess
            ) : (
              <p
                className={`mb-0 ${Styles.step_number}`}
                style={{
                  color: highlightCurrentStep ? ACCENTCOLOR : SECONDARYCOLOR,
                }}
              >
                {stepNumber}
              </p>
            )}
          </div>
        </div>
        <div style={{ flexBasis: "90%" }}>
          <p
            className={`mb-0 ${
              isStepCompleted
                ? Styles.success_step_text
                : highlightCurrentStep
                ? Styles.current_step_text
                : Styles.step_text
            }`}
          >
            {listHeader}
          </p>
        </div>
      </div>
      {props.children}
    </div>
  );
}

export default ChecklistItem;
