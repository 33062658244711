//If the user tries to select another plan dispite of having another plan on hold,
//  will discard the previous plan selected by the user for later purpose
import React from "react";

// Constants
import WarningIllustration from "../../../../assets/images/Warning.svg";
import { MEDIUM } from "../../../../Shared/buttonSize";

// styles
import Styles from "../../../../Shared/popup.module.scss";
import classes from "../downgrade-warning-pop-up/downgradeWarningPopUp.module.scss";
import {
  primaryButtonStyle,
  primaryButtonHoverStyle,
} from "../../../../Shared/buttonStyles";

// Component
import Button from "../../../../Shared/button/button";

function RequestDiscardedPopUp({
  planSelected,
  previouslyRequested,
  onUpdateRequest,
  onCancel,
}: any) {
  return (
    <div
      className={`d-flex align-items-center justify-content-center ${Styles.overlay}`}
    >
      <div className={`p-4 ${Styles.popUpContent}`} style={{ width: "500px" }}>
        <div className="py-2 text-center">
          <img
            src={WarningIllustration}
            alt="warning-illustration"
            style={{ width: "100px" }}
          />
        </div>
        <div className="py-2 text-center">
          <p className={`mb-0 ${classes.headingText}`}>
            {planSelected.plan_name} Plan Selected{" "}
          </p>
        </div>
        <div className="py-2 px-4">
          <p className={`mb-0 ${classes.subText}`}>
            You previously requested for{" "}
            <span className={classes.spanText}>
              {previouslyRequested.plan_name}
            </span>{" "}
            Plan. By requesting a new plan your existing request will be
            discarded.
          </p>
        </div>
        <div
          className="pt-3 d-flex justify-content-around px-4"
          style={{ margin: "0 auto" }}
        >
          <Button
            hoveredStyle={primaryButtonHoverStyle}
            style={primaryButtonStyle}
            size={MEDIUM}
            onClick={() => onUpdateRequest()}
            id="z-billing-btn-update-plan"
          >
            Update Plan
          </Button>
        </div>
        <div className="py-3 d-flex justify-content-center">
          <div className="d-inline-flex">
            <p
              className={`mb-0 ${classes.cancelText}`}
              onClick={() => onCancel()}
              id="z-billing-btn-update-plan-cancel"
            >
              Cancel
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RequestDiscardedPopUp;
