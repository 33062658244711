import React from "react";

// style
import Styles from "../dashboard.module.scss";

function WarningBar() {
  return (
    <div className={`${Styles.warningBar} d-flex align-items-center`}>
      <p>
        <b>Alert: </b>
        Account Balance is low!
      </p>
    </div>
  );
}

export default WarningBar;
