import React from "react";

// Styles
import Styles from "./zpinIntroBox.module.scss";

function ZpinIntroBox({ handleZpinFlow }: any) {
  return (
    <div className={`${Styles.zpinBox}`}>
      <div className={`${Styles.zpinIntro}`}>
        <span className={`${Styles.newTag}`}>New</span>
        <h5 className="">Introducing ZPIN</h5>
      </div>
      <p>
        Create a 4-digit ZPIN and you’ll not need an OTP every time you change a
        setting in your dashboard!
      </p>
      <div className="button-group">
        <button
          id="z-pin-cta"
          className={`${Styles.createZpinButton} mr-2`}
          onClick={() => handleZpinFlow()}
        >
          Create ZPIN
        </button>
      </div>
    </div>
  );
}

export default ZpinIntroBox;
