// Library Utilities
import React from "react";
import moment from "moment";
import { addDays, format } from "date-fns";
import { DateRangePicker } from "react-date-range";

// Components
import Button from "../button/button";
import ErrorMessage from "../error-message/errorMessage";

// Constants
import { MEDIUM } from "../buttonSize";

// Functions
import { defaultInputRanges, defaultStaticRanges } from "./defaultRanges";
import { ACCENTCOLOR } from "../colors";

// Styles
import PopupStyles from "../../Shared/popup.module.scss";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import {
  cancelButtonHoverStyle,
  cancelButtonStyle,
  primaryButtonHoverStylePopup,
  primaryButtonStylePopup,
} from "../buttonStyles";

function CustomDatePicker({
  setShowCustomDatePicker,
  setCustomDurationRange,
  maxDateDifference = 60,
}: any) {
  const [date, setDate] = React.useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "duration",
    color: ACCENTCOLOR,
  });
  const [error, setError] = React.useState("");
  const [confirmDisabled, setConfirmDisabled] = React.useState(false);

  const handleDateChange = (ranges: any) => {
    const isValidDate = checkDateRange(ranges.duration);
    if (!isValidDate) return;
    setDate(ranges.duration);
  };

  function addDateRange() {
    const isValidDate = checkDateRange(date);
    if (!isValidDate) return;

    setCustomDurationRange &&
      setCustomDurationRange({
        from: {
          day: date.startDate.getDate(),
          month: date.startDate.getMonth() + 1,
          year: date.startDate.getFullYear(),
        },
        to: {
          day: date.endDate.getDate(),
          month: date.endDate.getMonth() + 1,
          year: date.endDate.getFullYear(),
        },
      });
  }

  const checkDateRange = (checkDate: any) => {
    const endDate = moment(
      `${format(checkDate.endDate, "yyyy-MM-dd")}`,
      "YYYY-MM-DD"
    );
    const startDate = moment(
      `${format(checkDate.startDate, "yyyy-MM-dd")}`,
      "YYYY-MM-DD"
    );

    if (endDate.diff(startDate, "days") < 0) {
      setConfirmDisabled(true);
      setError("End date cannot be before start date");
      return false;
    }

    if (endDate.diff(startDate, "days") > maxDateDifference) {
      setConfirmDisabled(true);
      setError(
        `Max difference between dates should be ${maxDateDifference} days`
      );
      return false;
    }

    setError("");
    setConfirmDisabled(false);
    return true;
  };
  return (
    <div
      className={`d-flex align-items-center justify-content-center ${PopupStyles.overlay}`}
    >
      <div className={`${PopupStyles.popUpContent} p-1`}>
        <div className="m-4">
          <DateRangePicker
            onChange={handleDateChange}
            ranges={[date]}
            months={2}
            minDate={addDays(new Date("01/01/2020"), 0)}
            maxDate={addDays(new Date(), 0)}
            direction="horizontal"
            scroll={{ enabled: true }}
            calendarFocus="backwards"
            inputRanges={defaultInputRanges}
            staticRanges={defaultStaticRanges}
          />
          {error && <ErrorMessage>{error}</ErrorMessage>}
          <div className="d-flex justify-content-end justify-space-between ">
            <Button
              size={MEDIUM}
              style={cancelButtonStyle}
              hoveredStyle={cancelButtonHoverStyle}
              id="custom-date-picker-cancel"
              onClick={() => setShowCustomDatePicker(false)}
            >
              Cancel
            </Button>
            <Button
              className={`mb-0 ml-2`}
              size={MEDIUM}
              style={primaryButtonStylePopup}
              hoveredStyle={primaryButtonHoverStylePopup}
              disabled={confirmDisabled}
              id="custom-date-picker-confirm"
              onClick={addDateRange}
            >
              Confirm
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomDatePicker;
