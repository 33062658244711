//User doesn't completed KYC Details hence making them do, before purchasing up any plans
import React from "react";

// Styles
import Styles from "../../../../Shared/popup.module.scss";
import classes from "../downgrade-warning-pop-up/downgradeWarningPopUp.module.scss";
import {
  primaryButtonStyle,
  primaryButtonHoverStyle,
} from "../../../../Shared/buttonStyles";

// Components
import KycDetailRequirement from "./kyc-details-requirement/kycDetailRequirement";
import Button from "../../../../Shared/button/button";

// Constants
import { LARGE } from "../../../../Shared/buttonSize";
import checkListSrc from "../../../../assets/images/checkList.svg";
import bankAccountSrc from "../../../../assets/images/bank-account.svg";
import cinIllustrationSrc from "../../../../assets/images/CIN-illustratin.svg";
import gstinCertificateSrc from "../../../../assets/images/GSTIN-certificate.svg";
import Pensrc from "../../../../assets/images/pen.svg";

function KycDetailsPopUp({
  isLoading,
  customerType = "national",
  onSendOverEmail,
  onCancel,
}: any) {
  return (
    <div
      className={`d-flex align-items-center justify-content-center ${Styles.overlay}`}
    >
      <div className={`p-4 ${Styles.popUpContent}`} style={{ width: "500px" }}>
        <div className="py-2 text-center">
          <img src={checkListSrc} alt="money-bag" />
        </div>
        <div className="py-2 text-center">
          <p className={"mb-0 title_semibold_text"}>
            Account activation required
          </p>
        </div>
        <div className="py-2 px-3">
          <p className={`mb-0 ${classes.subText}`}>
            You will need to submit a few documents before purchasing a plan.
            These are required for verification purposes.
          </p>
        </div>
        <div className="py-2 px-4 d-flex justify-content-center">
          <div>
            <KycDetailRequirement
              imgSrc={gstinCertificateSrc}
              text=" GSTIN Number or GSTIN Certificate"
            />
            <KycDetailRequirement
              imgSrc={cinIllustrationSrc}
              text=" CIN or Udhyog Aadhaar Certificate"
            />
            <KycDetailRequirement
              imgSrc={bankAccountSrc}
              text={
                customerType === "international"
                  ? "Owner's Passport or PAN Copy"
                  : "Bank Account Number and IFSC"
              }
            />
            <KycDetailRequirement
              imgSrc={Pensrc}
              text="Authorised Signatory Name and Designation"
            />
          </div>
        </div>
        <div className="py-2 px-4">
          <p className={`mb-0 ${classes.subText}`}>
            We will process your documents and share a contract for the account
            activation.
          </p>
        </div>
        <div
          className="pt-3 d-flex justify-content-around px-4"
          style={{ margin: "0 auto" }}
        >
          <Button
            hoveredStyle={primaryButtonHoverStyle}
            style={primaryButtonStyle}
            size={LARGE}
            isLoading={isLoading}
            onClick={() => onSendOverEmail()}
            id="z-billing-btn-send-over-email"
          >
            Send over Email
          </Button>
        </div>
        <div className="py-3 d-flex justify-content-center">
          <div className="d-inline-flex">
            <p
              className={`mb-0 ${classes.cancelText}`}
              onClick={() => onCancel()}
              id="z-billing-btn-send-over-cancel"
            >
              Cancel
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default KycDetailsPopUp;
