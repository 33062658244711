// Handel different data rendering in bankDetails key value in popup
import React from "react";

// Styles
import Styles from "./bankDetailsKeyValue.module.scss";

function BankDetailsKeyValue({ label, value }: any) {
  return (
    <div>
      <p className={`mb-0 ${Styles.key}`}>{label}</p>
      <p className={`mb-0 ${Styles.value}`}>{value}</p>
    </div>
  );
}

export default BankDetailsKeyValue;
