//Screen that appears when user land onto manageApps section having short desc.
//  about all the apps and ability to create new app

// Hooks
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTitle } from "../../../Shared/useTitle";

// Styles
import Styles from "./manageApps.module.scss";

// Components
import ManageAppCard from "./manage-app-card/manageAppCard";
import CreateAppPopUp from "./create-app-pop-up/createAppPopUp";
import Button from "../../../Shared/button/button";
import Loading from "../../../Shared/loading/loading";
import Toast from "../../../Shared/toast/toast";
import EmptyState from "../../../Shared/empty-state/emptyState";
import RenderTooltip from "../../../Shared/tooltip/tooltip";

// Styles
import {
  primaryButtonStyle,
  primaryButtonHoverStyle,
} from "../../../Shared/buttonStyles";
import { ACCENTCOLOR } from "../../../Shared/colors";
import emptyStateStyle from "../../../Shared/empty-state/emptyState.module.scss";

// Functions
import { callApi } from "../../../api/fetch";
import { monthName } from "../../../utils/getMonth";

// Constants
import { App } from "../../../constants/app";
import { MEDIUM } from "../../../Shared/buttonSize";
import { noContent } from "../../../constants/emptyStatesVariables";
import { ONBOARDING_STATUS } from "../../../constants/onboardingStatuses";

function ManageApps() {
  const history = useHistory();
  const [showCreateApp, setShowCreateApp] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [orgInfoLoading, setOrgInfoLoading] = React.useState(false);
  const [apps, setApps] = React.useState([]);
  const [toast, setToast] = React.useState({
    message: "",
    type: "",
    show: false,
  });
  const org_id = localStorage.getItem("organisation_id");
  const [onboardingStatus, setOnboardingStatus] = React.useState("");

  const getAllApps = async (page: string) => {
    setLoading(true);
    try {
      const data = await callApi(`/app?page=${page}`, {
        method: "GET",
        init: {
          headers: {
            "x-org-id": `${localStorage.getItem("organisation_id")}`,
          },
        },
      });
      setApps(
        data.map((app: App) => {
          const date = new Date(Number(app.createdAt));
          return {
            ...app,
            createdAt: `${monthName(
              date.getMonth()
            )} ${date.getDate()}, ${date.getFullYear()}`,
          };
        })
      );
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toastTimeout(true, "error", (err as Error).message);
    }
  };

  useEffect(() => {
    getAllApps("apps");
  }, []);

  // getting org info api
  useEffect(() => {
    setOrgInfoLoading(true);
    callApi(`/organisation/${org_id}`, {
      method: "GET",
    })
      .then((response) => {
        localStorage.setItem("country_code", response.country_code);
        setOnboardingStatus(response.onboarding_status);
        setOrgInfoLoading(false);
      })
      .catch((error) => {
        setOrgInfoLoading(false);
        toastTimeout(true, "error", error.message);
        // TODO: add sentry error
      });
  }, [org_id]);

  function toastTimeout(
    showToastValue: boolean,
    toastType: string,
    toastMessage: string
  ) {
    setToast((toast: any) => ({
      ...toast,
      type: toastType,
      message: toastMessage,
      show: showToastValue,
    }));
    setTimeout(() => {
      setToast((toast: any) => ({
        ...toast,
        show: !showToastValue,
      }));
    }, 8000);
  }

  function handelCreateAppCancel() {
    setShowCreateApp(false);
  }
  function handelMoreInfo(value: App) {
    history.push(`/application/manage-apps/${value._id}`);
  }
  useTitle("Zoop Customer Platform | Apps Listings");
  return (
    <div>
      {toast.show ? (
        <Toast
          type={toast.type}
          message={toast.message}
          click={() =>
            setToast((toast: any) => ({
              ...toast,
              show: false,
            }))
          }
        />
      ) : null}
      {loading || orgInfoLoading ? (
        <div
          style={{ height: "80vh" }}
          className="d-flex align-items-center justify-content-center"
        >
          <Loading loadingColor={ACCENTCOLOR} />
        </div>
      ) : (
        <div
          className="p-3 mx-2"
          style={showCreateApp ? { position: "relative", zIndex: 1 } : {}}
        >
          <div className="px-2 py-2 d-flex align-items-center">
            <div className="ml-auto">
              {onboardingStatus !== ONBOARDING_STATUS.PLAN_SUBSCRIBED ? (
                <RenderTooltip
                  placement="bottom"
                  title="Subscribe a plan to create an App"
                >
                  <Button
                    hoveredStyle={primaryButtonHoverStyle}
                    style={primaryButtonStyle}
                    type="submit"
                    size={MEDIUM}
                    disabled={
                      onboardingStatus !== ONBOARDING_STATUS.PLAN_SUBSCRIBED
                    }
                  >
                    New App
                  </Button>
                </RenderTooltip>
              ) : (
                <Button
                  hoveredStyle={primaryButtonHoverStyle}
                  style={primaryButtonStyle}
                  type="submit"
                  id="manage-new-app"
                  onClick={() => setShowCreateApp(true)}
                  size={MEDIUM}
                >
                  New App
                </Button>
              )}
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              {apps.length !== 0 ? (
                apps.map((app: App) => (
                  <div
                    className="p-2 col-xl-3 col-md-6 col-lg-4 col-sm-6"
                    key={app._id}
                  >
                    <ManageAppCard
                      app={app}
                      click={() => {
                        handelMoreInfo(app);
                      }}
                    />
                  </div>
                ))
              ) : (
                <div
                  className={`${Styles.emptyState} d-flex align-items-center justify-content-center`}
                >
                  <EmptyState type={noContent}>
                    <div className={"py-3"}>
                      <p
                        className={`${emptyStateStyle.emptyStateHeadingText} mb-0`}
                      >
                        No Apps found
                      </p>
                    </div>
                  </EmptyState>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {showCreateApp ? (
        <CreateAppPopUp onCancel={handelCreateAppCancel} />
      ) : null}
    </div>
  );
}

export default ManageApps;
