// Library Utilities
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

function RenderTooltip({
  children,
  title,
  placement,
  className,
  ...rest
}: any) {
  const [gross, net] = title.includes("Gross")
    ? title.split("| ")
    : [null, null];

  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props} className={className || ""}>
      {!gross ? (
        title
      ) : (
        <>
          {gross}
          <br />
          {net}
        </>
      )}
    </Tooltip>
  );
  return (
    <OverlayTrigger
      placement={placement}
      delay={{ show: 100, hide: 0 }}
      overlay={renderTooltip}
      {...rest}
    >
      {children}
    </OverlayTrigger>
  );
}

export default RenderTooltip;
