import React from "react";
import Styles from "./login.module.scss";
import Button from "../../../Shared/button/button";
import Input from "../../../Shared/input/input";
import PasswordEye from "../../../Shared/svg/Eye";
import AuthSidebar from "../authSidebar/authSidebar";
import InformativeText from "../informativeText/informativeText";
import { Link, useHistory } from "react-router-dom";
import { ACCENTCOLOR, SECONDARYCOLOR } from "../../../Shared/colors";
import Illustration from "../../../assets/images/Verify_login_dashboard_illustration.svg";
import ErrorMessage from "../../../Shared/error-message/errorMessage";
import { initGA, pageView } from "../../../utils/ga4";
import {
  checkIsEmpty,
  validEmail,
  isvalidEmailFirstChar,
  bypassCaptchaEmail,
} from "../../../utils/validation";
import ReCAPTCHA from "react-google-recaptcha";
import {
  primaryButtonStyle,
  primaryButtonHoverStyle,
} from "../../../Shared/buttonStyles";
import { callApi } from "../../../api/fetch";
import Toast from "../../../Shared/toast/toast";
import { LARGE } from "../../../Shared/buttonSize";
import { onboardingSidebarContext } from "../../../utils/onboardingSidebarContext";
import {
  business_info,
  default_service,
  poc_info,
} from "../../../constants/onboardingConstants";
import { ONBOARDING_STATUS } from "../../../constants/onboardingStatuses";
import { useTitle } from "../../../Shared/useTitle";
import { encryptObject } from "../../../constants/encrypt";
function Login() {
  const sidebarContext = React.useContext(onboardingSidebarContext);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [passwordShown, setPasswordShown] = React.useState(false);
  const [error, setError] = React.useState({
    passwordError: "",
    emailError: "",
  });
  const [toast, setToast] = React.useState({
    message: "",
    type: "",
    show: false,
  });
  const [isValidEmail, setIsValidEmail] = React.useState(false);
  const [isValidPassword, setIsValidPassword] = React.useState(false);
  const [recaptchToken, setRecaptchaToken] = React.useState("");
  let captcha: any;
  const history = useHistory();
  const measurementId: string = process.env.REACT_APP_MEASUREMENT_ID as string;
  function toastTimeout(
    showToastValue: boolean,
    toastType: string,
    toastMessage: string
  ) {
    setToast((toast: any) => ({
      ...toast,
      type: toastType,
      message: toastMessage,
      show: showToastValue,
    }));
    setTimeout(() => {
      setToast((toast: any) => ({
        ...toast,
        show: !showToastValue,
      }));
    }, 8000);
    return;
  }

  function checkValidEmailInput(email: any) {
    const regex = /[!#$%^&*()"'`~<>/?]/;
    const domain = email.split("@")[0];
    return regex.test(domain);
  }

  function checkEmail() {
    if (checkIsEmpty(email)) {
      setIsValidEmail(false);
      setError((error) => ({
        ...error,
        emailError: "Email cannot be empty",
      }));
      return false;
    }

    if (checkValidEmailInput(email)) {
      setIsValidEmail(false);
      setError((error) => ({
        ...error,
        emailError: "Enter a valid email",
      }));
      return false;
    }

    if (!validEmail(email)) {
      setIsValidEmail(false);
      setError((error) => ({
        ...error,
        emailError: "Enter a valid email",
      }));
      return false;
    }
    if (isvalidEmailFirstChar(email[0])) {
      setIsValidEmail(false);
      setError((error) => ({
        ...error,
        emailError: "Email cannot start with a number",
      }));
      return false;
    }
    return true;
  }
  function checkPassword() {
    if (checkIsEmpty(password)) {
      setIsValidPassword(false);
      setError((error) => ({
        ...error,
        passwordError: "Password cannot be empty",
      }));
      return false;
    }
    return true;
  }

  async function getOrgInfo(id: string) {
    try {
      const data = await callApi(`/organisation/${id}`, {
        method: "GET",
      });
      setLoading(false);
      localStorage.setItem("organisation_id", id);
      if (data.onboarding_status === ONBOARDING_STATUS.ORG_CREATED) {
        sidebarContext.setStepCompleted([business_info, poc_info]);
        sidebarContext.setOrgCreated(true);
        sidebarContext.setNextStep(default_service);
        sidebarContext.setCreateOrgProgress(100);
        sidebarContext.setSelectServiceProgress(10);
        return history.push("/onboarding/select-service");
      }
      return history.replace("/application");
    } catch (err) {
      setLoading(false);
      toastTimeout(true, "error", (err as Error).message);
    }
  }

  function handleCaptcha(token: any) {
    setRecaptchaToken(token);
  }

  function checkrecaptcha() {
    if (!recaptchToken && !bypassCaptchaEmail.includes(email)) {
      toastTimeout(true, "error", "Captcha Token not present");

      return false;
    }
    return true;
  }
  const setCaptchaRef = (ref: any) => {
    if (ref) {
      return (captcha = ref);
    }
  };
  async function handelLogin(event: any) {
    event.preventDefault();
    const dataToEncrypt = {
      email: email,
      password: password,
    };
    const encryptedData = encryptObject(dataToEncrypt);
    const allCheckPassed = [
      checkEmail(),
      checkPassword(),
      checkrecaptcha(),
    ].every(Boolean);

    if (!allCheckPassed) {
      return;
    }
    setLoading(true);
    try {
      const data = await callApi("/user/login", {
        method: "POST",
        init: {
          headers: {
            captchaToken: recaptchToken.toString(),
          },
        },
        data: {
          encryptedData,
          // email: email,
          // password: password
        },
      });
      localStorage.setItem("token", JSON.stringify(data.token));
      localStorage.setItem("@user", JSON.stringify(data.user));
      localStorage.setItem("email", email);
      if (data.user.isPasswordAutogenerated) {
        return history.replace(
          `/create-password` + encodeURI(`?data={"user":"${data.user._id}"}`)
        );
      }
      if (!data.user.roles.length) return history.replace("/onboarding");
      getOrgInfo(data.user.roles[0].org_id);

      initGA(measurementId, {
        userId: data.user.roles[0].org_id,
      });
      pageView("pageview", window.location.pathname);
    } catch (err) {
      captcha.reset();
      localStorage.setItem("email", email);
      setLoading(false);
      toastTimeout(true, "error", (err as Error).message);
    }
    return;
  }
  useTitle("Zoop Customer Platform | Login");
  return (
    <div className="container-fluid p-0">
      {toast.show && (
        <Toast
          message={toast.message}
          type={toast.type}
          click={() =>
            setToast((toast: any) => ({
              ...toast,
              show: false,
            }))
          }
        />
      )}
      <div className="row no-gutters">
        <div className="col-xl-5 d-none d-xl-block">
          <AuthSidebar
            img={<img src={Illustration} alt="illustration" width="200px" />}
            info={<InformativeText headertext="One Identity Platform." />}
          />
        </div>
        <div className="col-xl-7 col-lg-12">
          <button
            id="visit-dashboard"
            onClick={() => (window.location.href = "https://zoop.one")}
            className={`${Styles.visitBtn} ml-2 mt-2 d-flex justify-content-center align-items-center`}
          >
            Visit Zoop.One
          </button>
          <div
            className={`d-flex align-items-center justify-content-center ${Styles.authActionsBackground}`}
          >
            <div className={Styles.formWidth}>
              <form>
                <div className="form-group mb-0 py-1">
                  <Input
                    type="email"
                    name="email"
                    id="email-login"
                    autoComplete="off"
                    placeholder="Enter your work email"
                    labelname="Email"
                    hasError={error.emailError}
                    isValid={isValidEmail}
                    onChange={(event: any) => {
                      setEmail(event.target.value);
                      setIsValidEmail(true);
                      setError((error) => ({
                        ...error,
                        emailError: "",
                      }));
                    }}
                    onBlur={checkEmail}
                    clear={false}
                  />
                  {error.emailError && (
                    <ErrorMessage>{error.emailError}</ErrorMessage>
                  )}
                </div>
                <div
                  className="form-group mb-0 py-1"
                  style={{ position: "relative" }}
                >
                  <Input
                    type={passwordShown ? "text" : "password"}
                    name="password"
                    id="password-login"
                    autoComplete="off"
                    placeholder="Enter the password"
                    labelname="Password"
                    hasError={error.passwordError}
                    isValid={isValidPassword}
                    onChange={(event: any) => {
                      setPassword(event.target.value);
                      setIsValidPassword(true);
                      setError((error) => ({
                        ...error,
                        passwordError: "",
                      }));
                    }}
                    onBlur={checkPassword}
                    clear={false}
                  />
                  <div
                    id="show-password-cta"
                    onClick={() => setPasswordShown(!passwordShown)}
                    className={Styles.eye}
                    style={
                      error.passwordError ? { top: "39%" } : { top: "43%" }
                    }
                  >
                    <PasswordEye
                      color={passwordShown ? ACCENTCOLOR : SECONDARYCOLOR}
                    />
                  </div>
                  <div className="d-flex align-items-center">
                    {error.passwordError && (
                      <ErrorMessage>{error.passwordError}</ErrorMessage>
                    )}
                    <div className="ml-auto">
                      <Link
                        className={`${Styles.forgotPasswordText} px-1`}
                        to="/forgot-password"
                      >
                        Forgot password
                      </Link>
                    </div>
                  </div>
                </div>
                <div className=" mt-3 mr-3 d-flex flex-column">
                  <div>
                    <p className={`${Styles.text} mb-0`}>
                      I agree to the{" "}
                      <a
                        href="https://zoop.one/terms-conditions/"
                        target="_blank"
                        className={`${Styles.text2} mb-0`}
                        rel="noopener noreferrer"
                      >
                        Terms & Conditions
                      </a>{" "}
                      and{" "}
                      <a
                        href="https://zoop.one/privacy-policy-2"
                        target="_blank"
                        className={`${Styles.text2} mb-0`}
                        rel="noopener noreferrer"
                      >
                        Privacy Policy
                      </a>
                    </p>
                  </div>
                </div>
                <div className="pt-3">
                  <div className="py-2">
                    <Button
                      id="login-cta"
                      hoveredStyle={primaryButtonHoverStyle}
                      size={LARGE}
                      type="submit"
                      style={primaryButtonStyle}
                      disabled={loading}
                      isLoading={loading}
                      onClick={handelLogin}
                    >
                      Login
                    </Button>
                    <ReCAPTCHA
                      className="mt-3"
                      sitekey={`${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`}
                      onChange={handleCaptcha}
                      ref={(r) => setCaptchaRef(r)}
                    />
                  </div>
                  {/* <div className="py-3">
                    <Button
                      hoveredStyle={borderButtonHoverStyle}
                      type="submit"
                      style={borderButtonStyle}
                      size={LARGE}
                    >
                      Login with GSuite Id
                    </Button>
                  </div> */}
                </div>
                <hr />
                <div className="pt-3">
                  <p className={`${Styles.text} mb-0`}>
                    Do not have an account ?
                  </p>
                  <div className="d-flex justify-content-center">
                    <p
                      className={`${Styles.signUpText} mb-0`}
                      id="sign-up-cta"
                      onClick={() => {
                        history.push("/sign-up");
                      }}
                    >
                      Sign up
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
