//Component rendered on firing new App button on manage apps screen

// Library Utilities
import React from "react";

// Hooks
import { useHistory } from "react-router-dom";

// Fucntions
import { isValidName } from "../../../../utils/validation";
import validator from "validator";
import { callApi } from "../../../../api/fetch";

// Styles
import Styles from "../../../../Shared/popup.module.scss";
import {
  primaryButtonStyle,
  primaryButtonHoverStyle,
} from "../../../../Shared/buttonStyles";

// Components
import Input from "../../../../Shared/input/input";
import ErrorMessage from "../../../../Shared/error-message/errorMessage";
import Button from "../../../../Shared/button/button";

// Constants
import { MEDIUM } from "../../../../Shared/buttonSize";

function CreateAppPopUp({ onCancel }: any) {
  const [appName, setAppName] = React.useState("");
  const [error, setError] = React.useState("");
  const [validAppName, setValidAppName] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();
  function checkAppName() {
    if (validator.isEmpty(appName, { ignore_whitespace: true })) {
      setError("Name is required to create a new app");
      return false;
    }
    if (isValidName(appName, true)) {
      setError("App name should contain alphanumeric char only");
      return false;
    }
    if (validator.isNumeric(appName[0], { no_symbols: true })) {
      setError("App name should not start with a number");
      return false;
    }
    if (appName.length > 25) {
      setError("App name should be less than 25 character");
      return false;
    }
    return true;
  }
  function handelCancel(event: any) {
    event.preventDefault();
    onCancel();
    return;
  }
  async function handelSaveChanges(event: any) {
    event.preventDefault();
    if (checkAppName()) {
      try {
        setLoading(true);
        const data = await callApi("/app", {
          method: "POST",
          data: {
            name: appName,
          },
          init: {
            headers: {
              "x-org-id": `${localStorage.getItem("organisation_id")}`,
            },
          },
        });
        setLoading(false);
        history.push(`/application/manage-apps/${data._id}`);
      } catch (err) {
        setLoading(false);
        setError((err as Error).message);
      }
    }
    return;
  }
  return (
    <div
      className={`d-flex align-items-center justify-content-center ${Styles.overlay}`}
    >
      <div className={`p-4 ${Styles.popUpContent} mx-5`}>
        <form onSubmit={handelSaveChanges}>
          <div className="form-group">
            <p className="title_semibold_text mb-3">App Name</p>
            <Input
              type="text"
              name="appName"
              id="appName"
              maxLength={25}
              autoComplete="off"
              placeholder="Your App Name"
              hasError={error}
              isValid={validAppName}
              // labelname="App Name"
              onChange={(event: any) => {
                const value = event.target.value;
                setAppName(value.trim());
                setError("");
                setValidAppName(true);
              }}
              onBlur={checkAppName}
              customInputClass="border-radius-10"
            />
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </div>
          <div className="d-flex align-items-center justify-content-between py-2">
            <div className="pr-2 w-100">
              <Button
                size={MEDIUM}
                id="create-app-popup-cancel"
                onClick={handelCancel}
                hoveredStyle={primaryButtonHoverStyle}
                style={primaryButtonStyle}
                type="button"
                className="bg-lite-accent-color w-100"
                btnTextClass="primary-color"
              >
                Cancel
              </Button>
            </div>
            <div className="w-100">
              <Button
                size={MEDIUM}
                isLoading={loading}
                id="create-app-popup-submit"
                hoveredStyle={primaryButtonHoverStyle}
                style={primaryButtonStyle}
                onClick={handelSaveChanges}
                className="w-100"
                type="submit"
              >
                Create App
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateAppPopUp;
