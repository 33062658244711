import React from "react";

// Components
import Button from "../../../../Shared/button/button";
import ErrorMessage from "../../../../Shared/error-message/errorMessage";
import Input from "../../../../Shared/input/input";

// constants
import { MEDIUM } from "../../../../Shared/buttonSize";

// Styles
import {
  primaryButtonHoverStyle,
  primaryButtonStyle,
} from "../../../../Shared/buttonStyles";
import Styles from "../../../../Shared/popup.module.scss";

// Functions
import { checkIsEmpty } from "../../../../utils/validation";

function EditAlertBalancePopUp({ onCancel, onUpdate, currentPlanType }: any) {
  const [error, setError] = React.useState("");
  const [alertBalance, setAlertBalance] = React.useState("");
  const [validAlertBalance, setValidAlertBalance] = React.useState(false);
  const numberRegexp = /[^\0-9]/;
  const planTypes = {
    PREPAID: "PREPAID",
    POSTPAID: "POSTPAID",
  };
  function checkAlertBalance() {
    if (checkIsEmpty(alertBalance)) {
      setError("Amount Can not be empty");
      setValidAlertBalance(false);
      return false;
    }
    if (numberRegexp.test(alertBalance)) {
      setError("Enter a valid Amount");
      setValidAlertBalance(false);
      return false;
    }
    if (Number(alertBalance) < 0) {
      setError("Amount should not be negative");
      setValidAlertBalance(false);
      return false;
    }
    return true;
  }
  function onUpdateAlertBalance(e: any) {
    e.preventDefault();
    if (!checkAlertBalance()) {
      return;
    }
    onUpdate(alertBalance);
  }
  return (
    <div
      className={`d-flex align-items-center justify-content-center ${Styles.overlay}`}
    >
      <div className={`p-4 ${Styles.popUpContent}`}>
        <form onSubmit={onUpdateAlertBalance}>
          <div className="form-group">
            {currentPlanType === planTypes.POSTPAID ? (
              <p className="title_semibold_text mb-3"> Daily Consumption</p>
            ) : (
              <p className="title_semibold_text  mb-3"> Alert balance</p>
            )}
            <Input
              type="text"
              name="alert_balance"
              id="alert_balance"
              autoComplete="off"
              placeholder="Enter Amount"
              hasError={error}
              isValid={validAlertBalance}
              onChange={(event: any) => {
                let updatedStr = "";
                const value = event.target.value;
                const stringArr = value.split(",");
                stringArr.map((val: string) => (updatedStr += val));
                setAlertBalance(updatedStr.trim());
                setError("");
                setValidAlertBalance(true);
              }}
              onBlur={checkAlertBalance}
              customInputClass={Styles.customAlertClass}
            />
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </div>
          <div className="pt-3 d-flex align-items-center justify-content-between">
            <div className="pr-2 w-100">
              <Button
                size={MEDIUM}
                onClick={() => onCancel()}
                hoveredStyle={primaryButtonHoverStyle}
                style={primaryButtonStyle}
                type="button"
                className="bg-lite-accent-color w-100"
                btnTextClass="primary-color"
                id="z-billing-btn-enablelater"
              >
                Cancel
              </Button>
            </div>
            <div className="pl-2  w-100">
              <Button
                size={MEDIUM}
                onClick={onUpdateAlertBalance}
                hoveredStyle={primaryButtonHoverStyle}
                style={primaryButtonStyle}
                type="submit"
                className="w-100"
                id="z-billing-btn-update"
              >
                Update
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditAlertBalancePopUp;
