// Hooks
import React, { useCallback, useEffect, useState } from "react";

// Components
import TrackTask from "./track-task/trackTask";
import InputDropdown from "../../../Shared/input-dropdown/inputDropdown";
import Button from "../../../Shared/button/button";
import AddBatchModal from "./add-batch";
import Pagination from "../../../Shared/pagination/pagination";
import Loading from "../../../Shared/loading/loading";

// styles
import styles from "./batch-service.module.scss";
import {
  primaryButtonStyle,
  primaryButtonHoverStyle,
} from "../../../Shared/buttonStyles";
import { ACCENTCOLOR, SECONDARYCOLOR } from "../../../Shared/colors";

// Functions
import { callApi } from "../../../api/fetch";

// Constants
import ArrowDownSVG from "../../../../src/components/application/developer-docs/svg/arrowDownSVG";
import ArrowUpSVG from "../../../../src/components/application/developer-docs/svg/arrowUpSVG";
// svg - images
import { ReactComponent as RefreshSvg } from "../../../assets/images/refresh-cw.svg";
import { COMPRESSED } from "../../../Shared/buttonSize";
import Toast from "../../../Shared/toast/toast";
import CustomDatePicker from "../../../Shared/custom-date-picker/customDatePicker";
import { format } from "date-fns";
// import Input from "../../../Shared/input/input";

const dropdownData = [
  { label: "Completed", value: "FINISHED" },
  { label: "In Progress", value: "RUNNING" },
  { label: "Paused", value: "PAUSED" },
  { label: "Error", value: "ERROR" },
  { label: "Waiting for approval", value: "Waiting for approval" },
];

const BatchService = () => {
  const organisationId = localStorage.getItem("organisation_id");

  const [selection, setSelection] = useState<string | undefined>("all_tasks");
  const [search, setSearch] = useState<string>("");
  const [addBatch, setAddBatch] = useState<boolean>(false);
  const [batchTypes, setBatchTypes] = useState<[]>([]);
  const [taskList, setTaskList] = useState<[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [filterTask, setFilterTask] = useState<any>([]);
  const [toast, setToast] = React.useState({
    message: "",
    type: "",
    show: false,
  });

  const [showCustomDatePicker, setShowCustomDatePicker] = React.useState<
    boolean
  >(false);

  const [
    customDurationPlaceholder,
    setCustomDurationPlaceholder,
  ] = React.useState<string>("Select Duration");

  // for Pagination
  const pageNumberLimit = 5;
  const [minPageNumberLimit, setMinPageNumberLimit] = React.useState(1);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = React.useState(
    pageNumberLimit
  );
  const [activePageNumber, setActivePageNumber] = React.useState(1);
  const [totalNumberOfPages, setTotalNumberOfPages] = React.useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [state, setState] = useState<any>(null);
  const [postPerPage] = React.useState(5);

  // To get the Batch types list
  const fetchBatchTypes = useCallback(async () => {
    try {
      const data = await callApi(
        `/productBatchService/getBatchTypes/${organisationId}`,
        {
          method: "GET",
        }
      );
      if (data?.length) {
        setBatchTypes(data);
      }
    } catch (err) {
      console.log(err);
    }
  }, [organisationId]);

  useEffect(() => {
    fetchBatchTypes();
  }, [fetchBatchTypes]);

  function toastTimeout(
    showToastValue: boolean,
    toastType: string,
    toastMessage: string
  ) {
    setToast((toast: any) => ({
      ...toast,
      type: toastType,
      message: toastMessage,
      show: showToastValue,
    }));
    setTimeout(() => {
      setToast((toast: any) => ({
        ...toast,
        show: !showToastValue,
      }));
    }, 2000);
  }

  // to get the list of the task.
  const getTaskData = useCallback(async () => {
    setLoading(true);
    clearAll();
    try {
      const data = await callApi(
        `/productBatchService/getTask?org_id=${organisationId}`,
        {
          method: "GET",
        }
      );
      setLoading(false);

      if (data) {
        setTaskList(data);
        setTotalNumberOfPages(data.length);
        setFilterTask(data);
      }
    } catch (err) {
      setLoading(false);
    }
  }, [organisationId]);

  useEffect(() => {
    getTaskData();
  }, [getTaskData]);

  // To filter task list data by status
  const filterData = (selection: string) => {
    return filterTask.filter(
      (item: any) => item?.status?.toLowerCase() === selection?.toLowerCase()
    );
  };

  // To filter task list data by search input
  const searchData = (items: any, searchParam: string) => {
    const data = items.filter((item: any) => {
      return (
        item.batchName &&
        item.batchName.toLowerCase().includes(searchParam.trim().toLowerCase())
      );
    });
    return data;
  };

  // To paginate the data according to page no.
  function paginate(array: any, page_size: number, page_number: number) {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.slice((page_number - 1) * page_size, page_number * page_size);
  }

  function clearAll() {
    setState("");
    setFilterTask(taskList);
    setActivePageNumber(1);
    setTotalNumberOfPages(taskList?.length);
    setSearch("");
  }

  function applyFilter() {
    if (state?.fromDate || state?.toDate) {
      if (state.fromDate && !state.toDate) {
        toastTimeout(true, "error", "Please Select Both Dates");
        return;
      }
      if (!state.fromDate && state.toDate) {
        toastTimeout(true, "error", "Please Select Both Dates");
        return;
      }
      if (new Date(state.fromDate) > new Date(state.toDate)) {
        toastTimeout(true, "error", "Please Select A valid Date Range");
        return;
      }
    }

    const filterData = taskList.filter((item: any) => {
      const date = item?.created_at.split("T")[0];
      return (
        (!state?.batchType || item.batchType === state?.batchType) &&
        (!state?.value || item?.batchState === state?.value) &&
        ((!state?.fromDate && !state?.toDate) ||
          (new Date(date) >= new Date(state?.fromDate) &&
            new Date(date) <= new Date(state?.toDate)))
      );
    });
    if (filterData.length === 0 && state) {
      setFilterTask([]);
    } else if (filterData.length > 0 && state) {
      setActivePageNumber(1);
      setFilterTask(filterData);
    }
  }

  const cancelCustomDateRange = (val: boolean) => {
    setShowCustomDatePicker(val);
  };

  const setCustomDateRange = (date: any) => {
    setShowCustomDatePicker(false);

    // Formatting the date in YYYY-MM-DD format
    const toFormateDate =
      date.to.year + "-" + date.to.month + "-" + date.to.day;
    const fromFromateDate =
      date.from.year + "-" + date.from.month + "-" + date.from.day;

    const toDate = new Date(toFormateDate);
    const fromDate = new Date(fromFromateDate);

    // To get the placeholder string from function - set the string in state
    setCustomDurationPlaceholder(
      `${format(fromDate, "dd/MM/yyyy")} - ${format(toDate, "dd/MM/yyyy")}`
    );

    setState((prevData: any) => ({
      ...prevData,
      fromDate: fromDate,
      toDate: toDate,
    }));
  };

  useEffect(() => {
    if (search) {
      const data = searchData(filterTask, search);
      setFilterTask(data);
      setActivePageNumber(1);
    } else if (search.trim() === "") {
      clearAll();
    }
  }, [search]);

  return (
    <>
      {toast.show && (
        <Toast
          message={toast.message}
          type={toast.type}
          click={() =>
            setToast((toast: any) => ({
              ...toast,
              show: false,
            }))
          }
        />
      )}
      {showCustomDatePicker && (
        <CustomDatePicker
          setShowCustomDatePicker={cancelCustomDateRange}
          setCustomDurationRange={(data: any) => {
            setCustomDateRange(data);
          }}
          maxDateDifference={90}
        />
      )}
      <div className="container-fluid p-0">
        <div className="p-4 mx-2">
          {/* Tob bar with filters and Title  */}
          <div>
            <div className="row justify-content-between">
              <div className="col col-lg-3  d-flex align-items-center w-100 my-2">
                <Button
                  hoveredStyle={primaryButtonHoverStyle}
                  style={primaryButtonStyle}
                  isLoading={false}
                  type="submit"
                  onClick={() => {
                    setAddBatch(true);
                  }}
                  id="z-add-batch-btn"
                  className="w-50"
                >
                  Add Batch
                </Button>
                <div
                  id="batch-sevice-get-task"
                  className="d-flex align-items-center my-2 mr-4 ml-3"
                  onClick={getTaskData}
                >
                  <div className={styles.refreshContainer}>
                    <RefreshSvg></RefreshSvg>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.trackTaskFilterDiv}>
            <div className={styles.headingStyle} style={{ marginTop: "10px" }}>
              Filter By
            </div>
            <div style={{ marginTop: "10px", cursor: "pointer" }}>
              <div
                // className={showFilter ? styles.roatate90 : ""}
                id="batch-service-filter-by"
                onClick={() => setShowFilter(!showFilter)}
              >
                {showFilter ? <ArrowUpSVG /> : <ArrowDownSVG />}
              </div>
            </div>
          </div>
          {!showFilter && <hr />}
          {showFilter && (
            <>
              <div className={styles.filterBorder}>
                <div className="d-flex flex-wrap">
                  <div className={styles.dropdown2}>
                    <InputDropdown
                      labelname="Product Name"
                      placeholder="Product Name"
                      searchable={true}
                      borderRadius={"true"}
                      no_shadow={"true"}
                      optionsArray={batchTypes.map((item: any) => item?.name)}
                      click={(key: string) => {
                        const option: any = batchTypes.filter(
                          (item: any) => item?.name === key
                        );
                        setState((data: any) => ({
                          ...data,
                          batchType: option[0]?.value || "",
                          name: option[0]?.name || "",
                        }));
                      }}
                      default_value={state?.name ? state?.name : ""}
                      whiteBackgroundcolor={true}
                    />
                  </div>

                  <div className="col-md-4 p-4">
                    <div
                      className={styles.formLabel}
                      style={{ marginBottom: "8px" }}
                    >
                      Select Duration
                    </div>

                    <input
                      type="text"
                      id="z-report-duration"
                      name="datePicker"
                      placeholder={customDurationPlaceholder}
                      style={{
                        width: "100%",
                        borderRadius: "8px",
                        padding: "10px",
                        fontSize: "12px",
                        backgroundColor: "#fff",
                        border: "1px solid #DDDCDD",
                        fontWeight: 100,
                        color: SECONDARYCOLOR,
                      }}
                      value={customDurationPlaceholder}
                      max={new Date().toISOString().split("T")[0]}
                      onClick={() => setShowCustomDatePicker(true)}
                    />
                  </div>

                  <div className={styles.dropdown2}>
                    <InputDropdown
                      no_shadow="true"
                      placeholder="Status"
                      labelname="Status"
                      isValid={true}
                      optionsArray={dropdownData.map(
                        (category: any) => category.label
                      )}
                      default_value={state?.label ? state?.label : ""}
                      click={(value: any) => {
                        const selectedValue = dropdownData.find(
                          (category: any) => category.label === value
                        );
                        setState((data: any) => ({
                          ...data,
                          value: selectedValue?.value || "",
                          label: selectedValue?.label || "",
                        }));
                      }}
                      className={styles.inputDropdown}
                    />
                  </div>
                </div>
                <div
                  className="d-flex flex-wrap "
                  style={{ display: "flex", gap: "40px" }}
                >
                  <div className={styles.filterButtonclearButton}>
                    <Button
                      style={primaryButtonStyle}
                      hoveredStyle={primaryButtonHoverStyle}
                      size={COMPRESSED}
                      id="batch-service-apply-filter"
                      onClick={applyFilter}
                    >
                      Apply Filter
                    </Button>
                  </div>
                  <div className={styles.filterButtonclearButton}>
                    <p
                      id="batch-service-clear-all"
                      onClick={() => clearAll()}
                      style={{ color: "#3272EC", cursor: "pointer" }}
                    >
                      Clear all
                    </p>
                  </div>
                </div>
              </div>
              {/* </div> */}
            </>
          )}

          {/* Track task  */}
          {loading ? (
            <div
              style={{ height: "100vh" }}
              className="d-flex align-items-center justify-content-center"
            >
              <Loading loadingColor={ACCENTCOLOR} width="14px" height="14px" />
            </div>
          ) : (
            <div>
              <TrackTask
                taskList={
                  filterTask.length === taskList.length ? taskList : filterTask
                }
                activePageNumber={activePageNumber}
                postPerPage={postPerPage}
                getTaskData={getTaskData}
              ></TrackTask>

              <div className="p-3">
                <div className=" m-0 d-flex align-items-center px-2">
                  <div className="px-2 ml-auto mr-auto">
                    <Pagination
                      pageNumberLimit={pageNumberLimit}
                      activePageNumber={activePageNumber}
                      total={
                        filterTask.length === taskList.length
                          ? taskList.length
                          : filterTask.length
                      }
                      postPerPage={postPerPage}
                      navigateToPage={(value: number) => {
                        setActivePageNumber(value);
                      }}
                      minPageNumberLimit={minPageNumberLimit}
                      maxPageNumberLimit={maxPageNumberLimit}
                      setMinPageNumberLimit={(value: number) =>
                        setMinPageNumberLimit(value)
                      }
                      setMaxPageNumberLimit={(value: number) =>
                        setMaxPageNumberLimit(value)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Create task modal  */}
        <AddBatchModal
          show={addBatch}
          setModalShow={setAddBatch}
          batchTypes={batchTypes}
          getTaskData={getTaskData}
        />
      </div>
    </>
  );
};

export default BatchService;
