//User tries to change password

// Library utilities
import React from "react";

// Components
import ErrorMessage from "../../../../Shared/error-message/errorMessage";
import Input from "../../../../Shared/input/input";
import Button from "../../../../Shared/button/button";
import Toast from "../../../../Shared/toast/toast";

// Styles
import Styles from "../../../../Shared/popup.module.scss";
import {
  cancelButtonStyle,
  primaryButtonStyle,
  cancelButtonHoverStyle,
  primaryButtonHoverStyle,
} from "../../../../Shared/buttonStyles";
import { ACCENTCOLOR, SECONDARYCOLOR } from "../../../../Shared/colors";

// Functions
import { checkIsEmpty } from "../../../../utils/validation";
import { callApi } from "../../../../api/fetch";

// Constants
import { MEDIUM } from "../../../../Shared/buttonSize";
import PasswordEye from "../../../../Shared/svg/Eye";

import { encryptObject } from "../../../../constants/encrypt";
function ChangePassword({ onSubmit, onCancel }: any) {
  const [error, setError] = React.useState({
    currentPasswordError: "",
    newPasswordError: "",
    confirmPasswordError: "",
  });
  const [validData, setValidData] = React.useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  });
  const [password, setPassword] = React.useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [loading, setLoading] = React.useState(false);
  const [toast, setToast] = React.useState({
    message: "",
    type: "",
    show: false,
  });
  const [showCurrentPassword, setShowCurrentPassword] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  function toastTimeout(
    showToastValue: boolean,
    toastType: string,
    toastMessage: string
  ) {
    setToast((toast: any) => ({
      ...toast,
      type: toastType,
      message: toastMessage,
      show: showToastValue,
    }));
    setTimeout(() => {
      setToast((toast: any) => ({
        ...toast,
        show: !showToastValue,
      }));
    }, 8000);
  }
  //Validating functions for all the input fields
  function checkCurrentPassword() {
    if (checkIsEmpty(password.currentPassword)) {
      setError((error) => ({
        ...error,
        currentPasswordError: "Current Password cannot be empty",
      }));
      setValidData((validData) => ({
        ...validData,
        currentPassword: false,
      }));
      return false;
    }
    return true;
  }
  function checkNewPassword() {
    if (checkIsEmpty(password.newPassword)) {
      setError((error) => ({
        ...error,
        newPasswordError: "New Password cannot be empty",
      }));
      setValidData((validData) => ({
        ...validData,
        newPassword: false,
      }));
      return false;
    }
    return true;
  }
  function checkConfirmPassword() {
    if (checkIsEmpty(password.confirmPassword)) {
      setError((error) => ({
        ...error,
        confirmPasswordError: "Confirm Password cannot be empty",
      }));
      setValidData((validData) => ({
        ...validData,
        confirmPassword: false,
      }));
      return false;
    }
    if (password.newPassword !== password.confirmPassword) {
      setError((error) => ({
        ...error,
        confirmPasswordError: "Confirm Password does not match",
      }));
      setValidData((validData) => ({
        ...validData,
        confirmPassword: false,
      }));
      return false;
    }
    return true;
  }
  function handelCancelClicked(event: any) {
    event.preventDefault();
    onCancel();
    return;
  }
  async function handelChangePassword(event: any) {
    event.preventDefault();
    const allCheckPassed = [
      checkCurrentPassword(),
      checkNewPassword(),
      checkConfirmPassword(),
    ].every(Boolean);
    if (!allCheckPassed) {
      return;
    }
    setLoading(true);
    try {
      const dataToEncrypt = {
        currentPassword: password.currentPassword,
        newPassword: password.newPassword,
      };
      const encryptChangePassword = encryptObject(dataToEncrypt);
      const data = await callApi("/user/change-password", {
        method: "PUT",
        data: {
          // currentPassword: password.currentPassword,
          // newPassword: password.newPassword
          encryptChangePassword,
        },
      });
      setLoading(false);
      onSubmit(data);
    } catch (err) {
      setLoading(false);
      toastTimeout(true, "error", (err as Error).message);
    }
    return;
  }
  function checkFormValidation() {
    return (
      loading ||
      password.currentPassword === "" ||
      error.currentPasswordError !== "" ||
      password.newPassword === "" ||
      error.newPasswordError !== "" ||
      password.confirmPassword === "" ||
      error.confirmPasswordError !== ""
    );
  }
  return (
    <div
      className={`d-flex align-items-center justify-content-center ${Styles.overlay}`}
    >
      {toast.show && (
        <Toast
          type={toast.type}
          message={toast.message}
          click={() =>
            setToast((toast: any) => ({
              ...toast,
              show: false,
            }))
          }
        />
      )}
      <div className={`p-4 ${Styles.popUpContent}`}>
        <form onSubmit={handelChangePassword}>
          <div
            className="form-group mb-0 py-1"
            style={{ position: "relative" }}
          >
            <Input
              type={showCurrentPassword ? "text" : "password"}
              name="currentPassword"
              id="currentPassword"
              autoComplete="off"
              maxLength={15}
              placeholder="Current Password"
              requiredAstrict
              hasError={error.currentPasswordError}
              isValid={validData.currentPassword}
              labelname="Current Password"
              clear={false}
              onChange={(event: any) => {
                const value = event.target.value;
                setPassword((password) => ({
                  ...password,
                  currentPassword: value,
                }));
                setError((error) => ({
                  ...error,
                  currentPasswordError: "",
                }));
                setValidData((validData) => ({
                  ...validData,
                  currentPassword: true,
                }));
              }}
              onBlur={checkCurrentPassword}
            />
            <div
              id="change-password-show-current-password"
              onClick={() => setShowCurrentPassword(!showCurrentPassword)}
              className={Styles.eye}
              style={
                error.currentPasswordError
                  ? { bottom: "46px" }
                  : { bottom: "21px" }
              }
            >
              <PasswordEye
                color={showCurrentPassword ? ACCENTCOLOR : SECONDARYCOLOR}
              />
            </div>
            <div className="d-flex align-items-center">
              {error.currentPasswordError && (
                <ErrorMessage>{error.currentPasswordError}</ErrorMessage>
              )}
            </div>
          </div>
          <div
            className="form-group mb-0 py-1"
            style={{ position: "relative" }}
          >
            <Input
              type={showNewPassword ? "text" : "password"}
              name="newPassword"
              id="newPassword"
              autoComplete="off"
              maxLength={15}
              placeholder="New Password"
              requiredAstrict
              hasError={error.newPasswordError}
              isValid={validData.newPassword}
              labelname="New Password"
              clear={false}
              onChange={(event: any) => {
                const value = event.target.value;
                setPassword((password) => ({
                  ...password,
                  newPassword: value,
                }));
                setError((error) => ({
                  ...error,
                  newPasswordError: "",
                }));
                setValidData((validData) => ({
                  ...validData,
                  newPassword: true,
                }));
              }}
              onBlur={checkNewPassword}
            />
            <div
              id="change-password-show-new-password"
              onClick={() => setShowNewPassword(!showNewPassword)}
              className={Styles.eye}
              style={
                error.newPasswordError ? { bottom: "46px" } : { bottom: "21px" }
              }
            >
              <PasswordEye
                color={showNewPassword ? ACCENTCOLOR : SECONDARYCOLOR}
              />
            </div>
            <div className="d-flex align-items-center">
              {error.newPasswordError && (
                <ErrorMessage>{error.newPasswordError}</ErrorMessage>
              )}
            </div>
          </div>
          <div
            className="form-group mb-0 py-1"
            style={{ position: "relative" }}
          >
            <Input
              type={showConfirmPassword ? "text" : "password"}
              name="confirmPassword"
              id="confirmPassword"
              autoComplete="off"
              maxLength={15}
              placeholder="Confirm Password"
              requiredAstrict
              hasError={error.confirmPasswordError}
              isValid={validData.confirmPassword}
              labelname="Confirm Password"
              clear={false}
              onChange={(event: any) => {
                const value = event.target.value;
                setPassword((password) => ({
                  ...password,
                  confirmPassword: value,
                }));
                setError((error) => ({
                  ...error,
                  confirmPasswordError: "",
                }));
                setValidData((validData) => ({
                  ...validData,
                  confirmPassword: true,
                }));
              }}
              onBlur={checkConfirmPassword}
            />
            <div
              id="change-password-show-confirm-password"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              className={Styles.eye}
              style={
                error.confirmPasswordError
                  ? { bottom: "46px" }
                  : { bottom: "21px" }
              }
            >
              <PasswordEye
                color={showConfirmPassword ? ACCENTCOLOR : SECONDARYCOLOR}
              />
            </div>
            <div className="d-flex align-items-center">
              {error.confirmPasswordError && (
                <ErrorMessage>{error.confirmPasswordError}</ErrorMessage>
              )}
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-around pb-2 pt-4">
            <div className="px-2">
              <Button
                size={MEDIUM}
                disabled={loading}
                hoveredStyle={cancelButtonHoverStyle}
                style={cancelButtonStyle}
                id="change-password-cancel"
                onClick={handelCancelClicked}
                type="button"
              >
                Cancel
              </Button>
            </div>
            <div className="px-2">
              <Button
                size={MEDIUM}
                disabled={checkFormValidation()}
                isLoading={loading}
                hoveredStyle={primaryButtonHoverStyle}
                style={primaryButtonStyle}
                id="change-password-save"
                onClick={handelChangePassword}
                type="submit"
              >
                Save
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ChangePassword;
