import React, { useEffect } from "react";
import Select from "react-select";
import Styles from "./stampApiServicePopup.module.scss";
import ErrorMessage from "../../../../../Shared/error-message/errorMessage";
import dropDownStyles from "../../../../../Shared/dropDownStyles";
import InputField from "../../Shared/inputField/inputField";
import Button from "../../../../../Shared/button/button";
import {
  primaryButtonHoverStylePopup,
  primaryButtonStylePopup,
} from "../../../../../Shared/buttonStyles";
import { MEDIUM } from "../../../../../Shared/buttonSize";
import {
  validName,
  isNumberOnly,
  validEmail,
} from "../../../../../utils/validation";
import { stampDutyValues } from "./estampApiData";
import { callApi } from "../../../../../api/fetch";
import Switch from "../../../../../Shared/toggle-switch/switch";

function EstampApiPopup({ toastTimeout, handleResponse, name }: any) {
  const errorObject = {
    firstPartyNameError: false,
    secondPartyNameError: false,
    recepientEmailError: false,
    purposeOfStampDutyError: false,
    branchError: false,
    articleNumberError: false,
    stampDutyValueError: false,
    considerationPriceError: false,
    numberOfCopiesError: false,
    fileError: false,
    consentError: false,
    estampDutyPaidByError: false,
  };

  const firstPartyNameRef = React.useRef<any>();
  const secondPartyNameRef = React.useRef<any>();
  const recepientEmailRef = React.useRef<any>();
  const purposeOfStampDutyRef = React.useRef<any>();
  const considerationPriceRef = React.useRef<any>();

  const [file, setFile] = React.useState<any>();
  const [fileName, setFileName] = React.useState<string>();
  const [branchData, setBranchData] = React.useState<any>();
  const [stampDutyPaidby, setStampDutyPaidBy] = React.useState<any>();
  const [branch, setBranch] = React.useState<any>([]);
  const [articles, setArticles] = React.useState<any>([]);
  const [stampDutyValue, setStampDutyValue] = React.useState<any>();
  const estampDutyValue = [
    { name: "First Party", value: "first_party_name" },
    { name: "Second Party", value: "second_party_name" },
  ];
  const [attachFile, setAttachFile] = React.useState(false);
  const [isPaymentRequired, setIsPaymentRequired] = React.useState(false);

  const [loading, setLoading] = React.useState(false);
  const [isChecked, setIsChecked] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(true);
  const [error, setError] = React.useState({ ...errorObject });
  const [loadingForBranch, setLoadingForBranch] = React.useState<boolean>(
    false
  );
  const [loadingForArticles, setLoadingForArticles] = React.useState<boolean>(
    false
  );

  function isValidName(fieldName: string) {
    let name: string = "";
    if (fieldName === "firstPartyName") {
      name = firstPartyNameRef.current.value;
    } else if (fieldName === "secondPartyName") {
      name = secondPartyNameRef.current.value;
    } else if (fieldName === "purposeOfStampDuty") {
      name = purposeOfStampDutyRef.current.value;
    }

    return validName(name) ? true : false;
  }

  async function getBranches() {
    setLoadingForBranch(true);
    try {
      const response = await callApi(`/estamp/getBranches`, {
        method: "GET",
      });
      if (response) {
        setBranch(response);
        setLoadingForBranch(false);
      }
    } catch (err) {
      setLoadingForBranch(false);
      toastTimeout(true, "error", (err as Error).message);
    }
  }

  useEffect(() => {
    getBranches();
  }, []);

  async function getArticles() {
    setLoadingForArticles(true);
    try {
      const response = await callApi(
        `/estamp/articles/${branchData?.branch_code}`,
        {
          method: "GET",
        }
      );
      if (response) {
        setArticles(response);
        setLoadingForArticles(false);
      }
    } catch (err) {
      toastTimeout(true, "error", (err as Error).message);
      setLoadingForArticles(false);
    }
  }

  useEffect(() => {
    if (branchData?.branch_id) {
      getArticles();
    }
  }, [branchData?.branch_id]);

  const branchList = branch?.map((obj: any) => {
    return {
      label: obj.branch_name,
      value: obj.branch_id,
    };
  });

  function handleBranchData(val: any) {
    const branchInfo = branch.filter((obj: any) => obj.branch_id === val)[0];
    setBranchData(branchInfo);
    return;
  }

  const uploadImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setFileName(e.target.files?.[0].name);
    const file = e.target.files?.[0];
    if (!file) return;

    if (file.type !== "application/pdf") {
      alert("Only PDF files are allowed");
      e.target.value = "";
      return;
    }

    let baseValue64 = (await convertBase64(file)) as string;

    const char = ",";
    const index = baseValue64.indexOf(char);

    if (index !== -1) {
      baseValue64 = baseValue64.substring(index + 1);
    }
    setFile(baseValue64);
  };

  const convertBase64 = (file: Blob) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result as string);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  // conditions to check Run verfication button should be enabled or disabled
  useEffect(() => {
    try {
      if (
        !validName(firstPartyNameRef.current.value) ||
        !validName(secondPartyNameRef.current.value) ||
        !validEmail(recepientEmailRef.current.value) ||
        !validName(purposeOfStampDutyRef.current.value) ||
        !isNumberOnly(considerationPriceRef.current.value) ||
        !firstPartyNameRef.current.value ||
        !secondPartyNameRef.current.value ||
        !recepientEmailRef.current.value ||
        !purposeOfStampDutyRef.current.value ||
        !branchData?.branch_name ||
        !branchData?.articleNumber ||
        !stampDutyValue ||
        !considerationPriceRef.current.value ||
        error.firstPartyNameError ||
        error.secondPartyNameError ||
        error.recepientEmailError ||
        error.purposeOfStampDutyError ||
        error.branchError ||
        error.articleNumberError ||
        error.stampDutyValueError ||
        error.considerationPriceError ||
        (attachFile && error.fileError) ||
        error.consentError ||
        error.estampDutyPaidByError ||
        !stampDutyPaidby
      ) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    } catch (err) {
      setIsDisabled(true);
      toastTimeout(true, "error", (err as Error).message);
    }
  }, [error]);

  useEffect(() => {
    const myDiv: any = document.getElementById("input-article-estamp-8000");
    myDiv.addEventListener("click", function () {
      if (!branchData) {
        setError((error) => ({
          ...error,
          branchError: true,
        }));
      } else if (branchData) {
        setError((error) => ({
          ...error,
          branchError: false,
        }));
      }
    });
  }, [branchData]);

  function handleCheck() {
    const firstPartyName = firstPartyNameRef.current.value;
    const secondPartyName = secondPartyNameRef.current.value;
    const recepientEmail = recepientEmailRef.current.value;
    const purposeOfStampDuty = purposeOfStampDutyRef.current.value;
    const considerationPrice = considerationPriceRef.current.value;
    const articleId = articles[0]?.id ?? "";
    const branchId = branchData?.branch_id ?? "";

    const consent = isChecked === true ? "Y" : "N";

    if (!validName(firstPartyName)) {
      setError((error) => ({
        ...error,
        firstPartyNameError: true,
      }));
      return false;
    } else if (!validName(secondPartyName)) {
      setError((error) => ({
        ...error,
        secondPartyNameError: true,
      }));
      return false;
    } else if (!validEmail(recepientEmail)) {
      setError((error) => ({
        ...error,
        recepientEmailError: true,
      }));
      return false;
    } else if (!validName(purposeOfStampDuty)) {
      setError((error) => ({
        ...error,
        purposeOfStampDutyError: true,
      }));
      return false;
    } else if (!branchData.branch_name) {
      setError((error) => ({
        ...error,
        branchError: true,
      }));
      return false;
    } else if (!branchData.articleNumber) {
      setError((error) => ({
        ...error,
        articleNumberError: true,
      }));
      return false;
    } else if (!stampDutyValue) {
      setError((error) => ({
        ...error,
        stampDutyValueError: true,
      }));
      return false;
    } else if (!considerationPrice) {
      setError((error) => ({
        ...error,
        considerationPriceError: true,
      }));
      return false;
    } else if (attachFile && !file) {
      setError((error) => ({
        ...error,
        fileError: true,
      }));
      return false;
    } else if (!stampDutyPaidby) {
      setError((error) => ({
        ...error,
        estampDutyPaidByError: true,
      }));
      return false;
    } else if (!isChecked) {
      setError((error) => ({
        ...error,
        consentError: true,
      }));
      return false;
    }
    setLoading(true);
    handleResponse(
      {
        firstPartyName: firstPartyName,
        secondPartyName: secondPartyName,
        stampDutyPaidBy: stampDutyPaidby?.value,
        recepientEmail: recepientEmail,
        purposeOfStampDuty: purposeOfStampDuty,
        branch: branchData.name,
        articleId: articleId,
        stampDutyValue: stampDutyValue,
        considerationPrice: considerationPrice,
        file: file,
        fileName: fileName,
        branchId: branchId,
        PaymentRequired: `${isPaymentRequired}`,
        procure: `${!attachFile}`,
        consent: consent,
      },
      setLoading
    );
  }

  return (
    <div className={`${Styles.Content}`}>
      <div className="d-flex justify-content-between">
        <div>
          <div className={`${Styles.trialText} pl-2`}>Runnning Trial on</div>
          <div className={`${Styles.headingText} pl-2`}>{name}</div>
        </div>
      </div>

      <div className={`${Styles.infoForm} w-full `}>
        <hr />
        <p className="mt-4 ml-2">
          Fill in the below details to run verification
        </p>
        <div className="d-flex flex-column">
          <div className="d-flex flex-row justify-content-between">
            <div className="d-flex flex-grow-1 flex-column mx-2">
              <div className={`${Styles.inputContent} d-flex flex-column`}>
                <label className={`${Styles.label}`}>
                  First Party Name <span className={Styles.mandatory}>*</span>
                </label>
                <InputField
                  id="id-input-first-party-name"
                  text="lowercase"
                  onChange={() => {
                    setError({
                      ...error,
                      firstPartyNameError: !isValidName("firstPartyName"),
                    });
                  }}
                  inputRef={firstPartyNameRef}
                  isError={error.firstPartyNameError}
                  purpose="forPassport"
                />
                {error.firstPartyNameError && (
                  <ErrorMessage>First Party Name field is invalid</ErrorMessage>
                )}
              </div>
              <div className={`${Styles.inputContent} d-flex flex-column mt-2`}>
                <label className={`${Styles.label}`}>
                  Stamp Duty Paid By <span className={Styles.mandatory}>*</span>
                </label>
                <Select
                  id="id-input-article-number"
                  onChange={(e: any) => {
                    setStampDutyPaidBy({
                      ...stampDutyPaidby,
                      value: e?.value,
                    });
                    setError({
                      ...error,
                      estampDutyPaidByError: false,
                    });
                  }}
                  options={estampDutyValue?.map((estamp: any) => {
                    return {
                      label: estamp?.name,
                      value: estamp?.value,
                    };
                  })}
                  styles={
                    !error.estampDutyPaidByError
                      ? dropDownStyles.customStylesForCountry
                      : dropDownStyles.customStylesForCountryError
                  }
                  placeholder=""
                />
                {error.estampDutyPaidByError && (
                  <ErrorMessage>please select estamp duty paid by</ErrorMessage>
                )}
              </div>
              <div className={`${Styles.inputContent} d-flex mt-2 flex-column`}>
                <label className={`${Styles.label}`}>
                  Branch <span className={Styles.mandatory}>*</span>
                </label>
                <Select
                  id="id-input-branch"
                  onChange={(e: any) => {
                    handleBranchData(e.value);
                    setError({ ...error, branchError: false });
                  }}
                  options={branchList}
                  styles={
                    !error.branchError
                      ? dropDownStyles.customStylesForCountry
                      : dropDownStyles.customStylesForCountryError
                  }
                  placeholder=""
                />
                {error.branchError && (
                  <ErrorMessage>Please select branch name first</ErrorMessage>
                )}
              </div>
              <div className={`${Styles.inputContent} d-flex mt-2 flex-column`}>
                <label className={`${Styles.label}`}>
                  Recepient Email <span className={Styles.mandatory}>*</span>
                </label>
                <InputField
                  id="id-input-recepient-email"
                  text="lowercase"
                  type="email"
                  onChange={() => {
                    setError({
                      ...error,
                      recepientEmailError: !validEmail(
                        recepientEmailRef.current.value
                      ),
                    });
                  }}
                  inputRef={recepientEmailRef}
                  isError={error.recepientEmailError}
                  purpose="forPassport"
                />
                {error.recepientEmailError && (
                  <ErrorMessage>Recepient Email field is invalid</ErrorMessage>
                )}
              </div>

              <div className={`${Styles.inputContent} d-flex mt-2 flex-column`}>
                <label className={`${Styles.label}`}>
                  Stamp Duty Value <span className={Styles.mandatory}>*</span>
                </label>
                <Select
                  id="id-input-stamp-duty-value"
                  onChange={(e: any) => {
                    setStampDutyValue(e.value);
                    setError({
                      ...error,
                      stampDutyValueError: false,
                    });
                  }}
                  options={stampDutyValues}
                  styles={
                    !error.stampDutyValueError
                      ? dropDownStyles.customStylesForCountry
                      : dropDownStyles.customStylesForCountryError
                  }
                  placeholder=""
                />
                {error.stampDutyValueError && (
                  <ErrorMessage>Stamp Duty Value field is invalid</ErrorMessage>
                )}
              </div>
            </div>
            <div className="d-flex flex-grow-1 flex-column mx-2">
              <div className={`${Styles.inputContent} d-flex flex-column`}>
                <label className={`${Styles.label}`}>
                  Second Party Name <span className={Styles.mandatory}>*</span>
                </label>
                <InputField
                  id="id-input-second-party-name"
                  text="lowercase"
                  onChange={() => {
                    setError({
                      ...error,
                      secondPartyNameError: !isValidName("secondPartyName"),
                    });
                  }}
                  inputRef={secondPartyNameRef}
                  isError={error.secondPartyNameError}
                  purpose="forPassport"
                />
                {error.secondPartyNameError && (
                  <ErrorMessage>
                    Second Party Name field is invalid
                  </ErrorMessage>
                )}
              </div>
              <div className={`${Styles.inputContent} d-flex mt-2 flex-column`}>
                <label className={`${Styles.label}`}>
                  Purpose of Stamp Duty{" "}
                  <span className={Styles.mandatory}>*</span>
                </label>
                <InputField
                  id="id-input-purpose-of-stamp-duty"
                  text="lowercase"
                  onChange={() => {
                    setError({
                      ...error,
                      purposeOfStampDutyError: !isValidName(
                        "purposeOfStampDuty"
                      ),
                    });
                  }}
                  inputRef={purposeOfStampDutyRef}
                  isError={error.purposeOfStampDutyError}
                  purpose="forPassport"
                />
                {error.purposeOfStampDutyError && (
                  <ErrorMessage>
                    Purpose of stamp duty field is invalid
                  </ErrorMessage>
                )}
              </div>
              <div
                className={`${Styles.inputContent} d-flex mt-2 flex-column`}
                id="input-article-estamp-8000"
              >
                <label className={`${Styles.label}`}>
                  Article <span className={Styles.mandatory}>*</span>
                </label>
                <Select
                  id="input-article-estamp"
                  onChange={(e: any) => {
                    setBranchData({
                      ...branchData,
                      articleNumber: e.value,
                    });
                    setError({
                      ...error,
                      articleNumberError: false,
                    });
                  }}
                  options={articles.map((article: any) => {
                    return {
                      label: article.article_name,
                      value: article.id,
                    };
                  })}
                  styles={
                    !error.articleNumberError
                      ? dropDownStyles.customStylesForCountry
                      : dropDownStyles.customStylesForCountryError
                  }
                  placeholder=""
                  isDisabled={!branchData}
                />

                {error.articleNumberError && (
                  <ErrorMessage>
                    Please enter a valid article number
                  </ErrorMessage>
                )}
              </div>
              <div className={`${Styles.inputContent} d-flex mt-2 flex-column`}>
                <label className={`${Styles.label}`}>
                  Consideration Price{" "}
                  <span className={Styles.mandatory}>*</span>
                </label>
                <InputField
                  type="text"
                  id="id-input-consideration-price"
                  onChange={() => {
                    setError({
                      ...error,
                      considerationPriceError: !isNumberOnly(
                        considerationPriceRef.current.value
                      ),
                    });
                  }}
                  inputRef={considerationPriceRef}
                  isError={error.considerationPriceError}
                  purpose="forPassport"
                />
                {error.considerationPriceError && (
                  <ErrorMessage>
                    Consideration Price field is invalid
                  </ErrorMessage>
                )}
              </div>
              <div
                className={`${Styles.inputContent} d-flex mt-4 pt-2 flex-column`}
              >
                <Switch
                  negativeLabel="Attach Document"
                  id="procure-switch"
                  checked={attachFile}
                  onChange={() => {
                    setAttachFile(!attachFile);
                  }}
                />
              </div>
            </div>
          </div>
          {attachFile ? (
            <div
              className={`${Styles.inputContent} ml-2 d-flex mt-2 flex-column`}
            >
              <label className={`${Styles.label}`}>
                File <span className={Styles.mandatory}>*</span>
              </label>
              <input
                id="id-input-file-estamp"
                type="file"
                name="file"
                accept=".pdf"
                onChange={(event) => {
                  uploadImage(event);
                }}
                className={Styles.fileUpload}
              />
              {error.fileError && (
                <ErrorMessage>Please select file!</ErrorMessage>
              )}
            </div>
          ) : null}

          <p
            className={`${Styles.ackText}`}
            style={{ marginTop: "20px", marginLeft: "6px", maxWidth: "90%" }}
          >
            <span className={Styles.mandatory}>*</span> A dummy Estamp is
            generated for testing purpose. The dummy Estamp generated on trail
            center is NOT legally valid
          </p>

          <div className="pl-4 py-3 d-flex flex-column">
            <input
              required
              type="checkbox"
              className="form-check-input"
              onChange={(e: any) => setIsChecked(e.target.checked)}
              style={error.consentError ? { border: "1px solid red" } : {}}
            />
            <p className={`${Styles.ackText}`}>
              I hearby agree, to let zoop.one verify my data for verification
            </p>
            {error.consentError && (
              <ErrorMessage>Please provide consent!</ErrorMessage>
            )}
          </div>
          <div className={`${Styles.buttonGroup}`}>
            <Button
              id="id-submit-button-estamp"
              hoveredStyle={primaryButtonHoverStylePopup}
              disabled={isDisabled || loading}
              size={MEDIUM}
              isLoading={loading}
              style={primaryButtonStylePopup}
              onClick={() => {
                setTimeout(() => setError({ ...error }), 2000);
                handleCheck();
              }}
            >
              Run Verification
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EstampApiPopup;
