//Input field format for KYC Details
import React from "react";

// Styles
import Styles from "../kycDetailsPopUp.module.scss";

function KycDetailRequirement({ imgSrc, text }: any) {
  return (
    <div className="d-flex align-items-center py-3">
      <div className="pl-3 pr-2">
        <img src={imgSrc} alt="GSTIN certificate" width="20px" height="20px" />
      </div>
      <div className="pl-2">
        <p className={`mb-0 ${Styles.requirementText}`}>{text}</p>
      </div>
    </div>
  );
}

export default KycDetailRequirement;
