// Library utilities
import moment from "moment";
import { addLeadingZero } from "../../../../utils/date-format";

// Hooks
import React, { useState, useEffect } from "react";

// Styles
import Styles from "./transactionStats.module.scss";
import sharedStyles from "../dashboard.module.scss";
import { ACCENTCOLOR } from "../../../../Shared/colors";

// Components
import Switch from "../../../../Shared/toggle-switch/switch";
import CustomDatePicker from "../../../../Shared/custom-date-picker/customDatePicker";
import InputDropdown from "../../../../Shared/input-dropdown/inputDropdown";
import Loading from "../../../../Shared/loading/loading";
import DataTable from "../../../../Shared/data-table/datatable";

// Constants
import { durationOptions } from "../../../../constants/data";
import { envTypes } from "../../../../constants/envTypes";

// Functions
import { callApi } from "../../../../api/fetch";

function TransactionStats({ loading, appDetails, appData }: any) {
  const org_id = localStorage.getItem("organisation_id");
  const user: any = localStorage.getItem("@user");
  const x_user_id = JSON.parse(user)._id;

  const [activePageNumber, setActivePageNumber] = React.useState(1);
  const [totalNumberOfPages, setTotalNumberOfPages] = React.useState(0);
  const [selectedApp, setSelectedApp] = React.useState(appData[0]);
  const [showCustomDatePicker, setShowCustomDatePicker] = useState(false);
  const [transactionStats, setTransactionStats] = useState([]);
  const [selectedDuration, setSelectedDuration] = useState("Last 30 days");
  const [customDurationRange, setCustomDurationRange] = useState({
    to: {
      day: 0,
      month: 0,
      year: 0,
    },
    from: {
      day: 0,
      month: 0,
      year: 0,
    },
  });
  async function getTransactionStats() {
    let str = `&app_id=${selectedApp._id}&env=${
      selectedApp.is_test_app ? envTypes.TEST : envTypes.PRODUCTION
    }`;
    const end_date = moment(new Date()).format("YYYY-MM-DD");
    switch (selectedDuration) {
      case "Last 7 days": {
        const last_seventh_date = new Date(
          moment().subtract(7, "days").calendar()
        );
        str += `&start_date=${moment(last_seventh_date).format(
          "YYYY-MM-DD"
        )}&end_date=${moment(end_date).format("YYYY-MM-DD")}`;
        break;
      }
      case "Last 30 days": {
        const last_thirtieth_date = new Date(
          moment().subtract(30, "days").calendar()
        );
        str += `&start_date=${moment(last_thirtieth_date).format(
          "YYYY-MM-DD"
        )}&end_date=${moment(end_date).format("YYYY-MM-DD")}`;
        break;
      }
      case "Last 60 days": {
        const last_sixtieth_date = new Date(
          moment().subtract(60, "days").calendar()
        );
        str += `&start_date=${moment(last_sixtieth_date).format(
          "YYYY-MM-DD"
        )}&end_date=${moment(end_date).format("YYYY-MM-DD")}`;
        break;
      }
      case "Last 120 days": {
        const last_quarter_date = new Date(
          moment().subtract(120, "days").calendar()
        );
        str += `&start_date=${moment(last_quarter_date).format(
          "YYYY-MM-DD"
        )}&end_date=${moment(end_date).format("YYYY-MM-DD")}`;
        break;
      }
      case "Custom": {
        if (!customDurationRange.from.day && !customDurationRange.to.day)
          return;
        str += `&start_date=${`${
          customDurationRange.from.year
        }-${addLeadingZero(customDurationRange.from.month)}-${addLeadingZero(
          customDurationRange.from.day
        )}`}&end_date=${`${customDurationRange.to.year}-${addLeadingZero(
          customDurationRange.to.month
        )}-${addLeadingZero(customDurationRange.to.day)}`}`;
        break;
      }
    }
    const query = str;
    setActivePageNumber(1);
    try {
      const res = await callApi(
        `/organisation/${org_id}?action=get_org_transaction_stats${query}`,
        {
          method: "GET",
          init: {
            headers: {
              "x-user-id": x_user_id,
            },
          },
        }
      );
      return setTransactionStats(res);
    } catch (err) {
      console.log(err);
    }
    return;
  }

  useEffect(() => {
    if (selectedDuration === "Custom" && showCustomDatePicker) return;
    getTransactionStats();
  }, [selectedDuration, selectedApp, customDurationRange]);

  const tableHeader = [
    {
      name: "Product Name",
      className: Styles.name,
    },
    {
      name: "Balance Credits",
      className: Styles.name,
    },
    {
      name: "Billable Transactions",
      className: Styles.transNo,
    },
    {
      name: "Success",
      className: Styles.status,
    },
    {
      name: "Failed",
      className: Styles.resCode,
    },
  ];

  const tableHeaderCustom = (
    <div className="d-flex justify-content-between">
      <p className={"mb-0 card_title_semibold_text"}>Transaction Stats</p>
      <div>
        <div className={`d-flex align-items-center ${Styles.duration}`}>
          <div className="mr-3 mt-3">
            <Switch
              positiveLabel="Production"
              negativeLabel="Trial"
              purpose="forDashboard"
              id="disable-prod-trial-switch"
              checked={!selectedApp.is_test_app}
              disabled={true}
            />
          </div>
          <div className="d-flex mt-4 flex-grow-1">
            <div className="d-flex flex-column flex-grow-1 mr-3">
              <p className={sharedStyles.dropDownHeader}>Duration</p>
              <InputDropdown
                label="Duration"
                style={{ width: "160px" }}
                no_shadow="true"
                placeholder="Last 30 days"
                optionsArray={durationOptions}
                click={(value: string) => {
                  setSelectedDuration(value);
                  if (value === "Custom") {
                    setShowCustomDatePicker(true);
                  }
                }}
                id="z-duration-product-usage-report-30"
              />
            </div>
            <div className="d-flex flex-column flex-grow-1 mr-3">
              <p className={sharedStyles.dropDownHeader}>Apps</p>
              <InputDropdown
                label="Apps"
                style={{ width: "160px" }}
                optionsArray={appData.map((app: any) => app.name)}
                placeholder={appData[0].name}
                no_shadow="true"
                id="z-duration-product-usage-product-select"
                click={(value: string) => {
                  const temp = appData.find((app: any) => app.name === value);
                  setSelectedApp(temp);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {showCustomDatePicker && (
        <CustomDatePicker
          setShowCustomDatePicker={setShowCustomDatePicker}
          maxDateDifference={120}
          setCustomDurationRange={(data: any) => {
            setCustomDurationRange(data);
            setShowCustomDatePicker(false);
          }}
        />
      )}
      <div>
        {loading ? (
          <div
            style={{ height: "80vh" }}
            className="d-flex align-items-center justify-content-center"
          >
            <Loading loadingColor={ACCENTCOLOR} />
          </div>
        ) : (
          <DataTable
            headers={
              selectedApp.is_test_app
                ? tableHeader
                : tableHeader.filter(
                    (header) => header.name !== "Balance Credits"
                  )
            }
            loading={loading}
            rows={transactionStats}
            setActivePageNumber={setActivePageNumber}
            totalNumberOfPages={totalNumberOfPages}
            tableHeader={tableHeaderCustom}
            bodyClassName={Styles?.bodyClassName}
            purpose="forDashboard"
          >
            {transactionStats?.length ? (
              transactionStats.map((traxn: any, index: number) => {
                return (
                  <tr key={`${index} ${Styles.tableRow}`}>
                    <td className="px-4 py-2">
                      <p className={"mb-0 body_small_text"}>
                        {traxn.product_name}
                      </p>
                    </td>
                    {selectedApp.is_test_app && (
                      <td className="px-4 py-2">
                        <p className={"mb-0 body_small_text"}>
                          {traxn.test_credits}
                        </p>
                      </td>
                    )}
                    <td className="px-4 py-2">
                      <p className={"mb-0 body_small_text"}>
                        {traxn.billable_txn_count}
                      </p>
                    </td>
                    <td className="px-4 py-2">
                      <p className={"mb-0 body_small_text"}>
                        {traxn.success_txn_count}
                      </p>
                    </td>
                    <td className="px-4 py-2">
                      <p className={"mb-0 body_small_text"}>
                        {traxn.failure_txn_count}
                      </p>
                    </td>
                  </tr>
                );
              })
            ) : (
              <div className="d-flex justify-content-center align-items-center mt-5">
                No data to display
              </div>
            )}
          </DataTable>
        )}
      </div>
    </>
  );
}

export default TransactionStats;
