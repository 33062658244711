//Giving user the ability to add logo,colors,number and different UI for their app.

// Library Utilities
import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Accordion } from "react-bootstrap";

// Components
import Toast from "../../../../Shared/toast/toast";
import ZpinMainPopup from "../../../../Shared/zpin-main-popup/zpinMainPopup";
import UnsavedChangesPopUp from "./unsaved-changes-pop-up/unsavedChangesPopUp";
import IpWhiteListing from "./ip-whiteListing/ipWhiteListing";
import TopBar from "../../../../Shared/top-bar/topBarNew";

// Hooks
import { useTitle } from "../../../../Shared/useTitle";

// Constants
import DarkLogoSVG from "../../../../Shared/svg/darkLogoSVG";
import ArrowUp from "../../../../Shared/svg/arrowUp";

// Fucntions
import { isValidIp } from "../../../../utils/validation";
import { callApi } from "../../../../api/fetch";

// Styles
import Styles from "./app-settings.module.scss";
import { BLACKCOLOR } from "../../../../Shared/colors";

function AppSettings() {
  const {
    state: { id: appId },
  }: any = useLocation();
  const history = useHistory();
  const [activeKey, setActiveKey] = React.useState("1");

  const [buttonLoading, setButtonLoading] = React.useState(false);
  const [ipAddresses, setIPAddresses] = React.useState("");
  const [toast, setToast] = React.useState({
    message: "",
    type: "",
    show: false,
  });
  const [relayId, setRelayId] = React.useState("");
  const [allIpWhitelisted, setAllIpWhitelisted] = React.useState(false);
  const [savedIpCount, setSavedIpCount] = React.useState(0);
  const [enableAddingIpAddress, setEnableAddingIpAddress] = React.useState(
    true
  );
  const [zpinExists, setZpinExists] = React.useState(false);
  const [listOfIpAddress, setListOfIpAddress] = React.useState<any>([]);
  const [updatedListOfIpAddress, setUpdatedListOfIpAddress] = React.useState(
    []
  );
  const [listOfRemovedIpAddress, setListOfRemovedIpAddress] = React.useState<
    any
  >([]);
  const [isIpAddressUpdated, setIsIpAddressUpdated] = React.useState(false);
  // const [showGatewayUISetting, setShowGatewayUISetting] = React.useState(false);
  const [saveAllPopUp, setSaveAllPopUp] = React.useState({
    show: false,
    unsavedSettings: [],
  });
  const [
    toggleOtpVerificationPopUp,
    setToggleOtpVerificationPopUp,
  ] = React.useState<any>({
    toggle: false,
    ips: [],
  });
  const [error, setError] = React.useState({
    backgroundColorError: "",
    buttonColorError: "",
    companyNameError: "",
    msisdnNumberError: "",
    appLogoError: "",
    ipAddress: "",
  });
  const [toggleResetOtp, setToggleResetOtp] = React.useState(false);
  const orgId = localStorage.getItem("organisation_id");

  async function getAppIpsById() {
    try {
      await callApi(`/app/${appId}?onlyIpWhitelisted=true`, {
        method: "GET",
      })
        .then((response: any) => {
          const { ip_whitelisted } = response;
          setListOfIpAddress(ip_whitelisted);
          setUpdatedListOfIpAddress(ip_whitelisted);
          setAllIpWhitelisted(
            ip_whitelisted.length !== 0 && ip_whitelisted[0] !== "ALL"
          );
          setSavedIpCount(
            ip_whitelisted[0] === "ALL" ? 0 : ip_whitelisted.length
          );
        })
        .catch((err) => {
          toastTimeout(true, "error", err.message);
          history.replace(`/application/manage-apps/${appId}/`);
        });
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getAppIpsById();
  }, []);

  useEffect(() => {
    async function checkForZpin() {
      try {
        const res = await callApi(`/zpin/${orgId}`, {
          method: "GET",
        });
        if (res.data !== null) setZpinExists(true);
      } catch (error) {
        setZpinExists(false);
      }
    }
    checkForZpin();
  }, [orgId]);

  useEffect(() => {
    if (listOfIpAddress[0] === "ALL") {
      setAllIpWhitelisted(false);
    }
  }, [listOfIpAddress]);

  // Custom Toggle function here
  function CustomToggle({ onClick, eventKey, header }: any) {
    return (
      <div
        id={`custom-toggle-${eventKey}`}
        className={
          activeKey.includes(eventKey)
            ? Styles.activeToggle
            : Styles.customToggle
        }
        // style={{ boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)" }}
        onClick={onClick}
      >
        <div
          className="d-flex align-items-center"
          style={{
            cursor: "pointer",
            position: "relative",
            zIndex: 1,
          }}
        >
          <div className="px-3 flex-grow-1 px-4 pb-2 pt-4">
            <p className={"mb-0 card_title_bold_text"}>{header}</p>
          </div>
          {/* <div className="px-2">
            {activeKey === eventKey ? (
              <ArrowDown />
            ) : (
              <div className={Styles.rotateArrowRight}>
                <ArrowDown />
              </div>
            )}
          </div> */}
        </div>
      </div>
    );
  }

  function toastTimeout(
    showToastValue: boolean,
    toastType: string,
    toastMessage: string
  ) {
    setToast((toast: any) => ({
      ...toast,
      type: toastType,
      message: toastMessage,
      show: showToastValue,
    }));
    setTimeout(() => {
      setToast((toast: any) => ({
        ...toast,
        show: !showToastValue,
      }));
    }, 8000);
    return;
  }

  function handleBackNavigation() {
    const unsavedList: any = [];
    if (isIpAddressUpdated) {
      if (isIpAddressUpdated) {
        unsavedList.push("IP Whitelisting");
      }
      setSaveAllPopUp((saveAllPopUp: any) => ({
        ...saveAllPopUp,
        show: true,
        unsavedSettings: unsavedList,
      }));
      return;
    }
    history.replace(`/application/manage-apps/${appId}`);
  }

  function onAddIpAddress(ipAddresses: string) {
    if (ipAddresses.length > 0) {
      let inValidIpCount = 0;
      let ipAddressExists = 0;
      const ipArray: any = [];
      if (ipAddresses.charAt(ipAddresses.length - 1) === ",") {
        setError((error) => ({
          ...error,
          ipAddress: "IP address cannot start with a ,",
        }));
        return;
      }
      const ips = ipAddresses.split(",");
      ips.map((ip: string) => {
        if (
          ipArray.includes(ip) ||
          listOfIpAddress.includes(ip) ||
          listOfRemovedIpAddress.includes(ip)
        ) {
          ipAddressExists++;
          return ipAddressExists;
        }
        if (!isValidIp(ip)) {
          inValidIpCount++;
          return inValidIpCount;
        }
        return ipArray.push(ip);
      });
      if (ipAddressExists > 0) {
        setError((error) => ({
          ...error,
          ipAddress: "This IP Address already exists",
        }));
        return;
      }
      if (inValidIpCount > 0) {
        setError((error) => ({
          ...error,
          ipAddress: "Enter a valid IP Address",
        }));
        return;
      }
      setIsIpAddressUpdated(true);
      setListOfIpAddress(listOfIpAddress.concat(ips));
      setIPAddresses("");
    }
  }

  async function handleAddIpAddressWithZpin(
    zpin: string,
    otp: string,
    setInvalidZpinError: any
  ) {
    setButtonLoading(true);
    try {
      let response;
      if (zpin) {
        response = await callApi(`/app/${appId}?action=update_whitelisted_ip`, {
          method: "PUT",
          data: {
            orgId: orgId,
            zpin: zpin,
            whitelisted_ips: toggleOtpVerificationPopUp.ips,
          },
        });
      } else if (otp) {
        response = await callApi(`/app/${appId}?action=update_whitelisted_ip`, {
          method: "PUT",
          data: {
            relayId: relayId,
            otp: otp,
            whitelisted_ips: toggleOtpVerificationPopUp.ips,
          },
        });
      }
      setButtonLoading(false);
      setToggleResetOtp(false);
      setToggleOtpVerificationPopUp((toggleOtpVerificationPopUp: any) => ({
        ...toggleOtpVerificationPopUp,
        toggle: false,
      }));
      toastTimeout(true, "success", "IP Whitelisting successfully saved");
      const { ip_whitelisted } = response;
      setListOfIpAddress(ip_whitelisted);
      setUpdatedListOfIpAddress(ip_whitelisted);
      setAllIpWhitelisted(
        ip_whitelisted.length !== 0 && ip_whitelisted[0] !== "ALL"
      );
      setSavedIpCount(ip_whitelisted[0] === "ALL" ? 0 : ip_whitelisted.length);
      setIsIpAddressUpdated(false);
      setListOfRemovedIpAddress([]);

      // if (!toggleOtpVerificationPopUp.ips.includes("ALL")) {
      //   setEnableAddingIpAddress(!enableAddingIpAddress);
      // }
    } catch (err) {
      setButtonLoading(false);
      setInvalidZpinError();
    }
  }

  async function sendOtp(purpose: string) {
    try {
      const res = await callApi("/events?action=send_otp", {
        method: "POST",
        data: {
          purpose: purpose,
        },
      });
      const { relay_id } = res;
      setRelayId(relay_id);
      return relay_id;
    } catch (err) {
      toastTimeout(true, "error", (err as Error).message);
    }
  }
  useTitle("Zoop Customer Platform | App Settings");
  return (
    <div>
      {toast.show && (
        <Toast
          message={toast.message}
          type={toast.type}
          click={() =>
            setToast((toast: any) => ({
              ...toast,
              show: false,
            }))
          }
        />
      )}
      {saveAllPopUp.show && (
        <UnsavedChangesPopUp
          unsavedSettingNames={saveAllPopUp.unsavedSettings}
          onDiscardChanges={() => {
            history.replace(`/application/manage-apps/${appId}`);
          }}
          onCancel={() => {
            setSaveAllPopUp((saveAllPopUp) => ({
              ...saveAllPopUp,
              show: false,
            }));
          }}
        />
      )}
      {toggleOtpVerificationPopUp.toggle && (
        <ZpinMainPopup
          zpinExists={zpinExists}
          updateZpinStatus={() => setZpinExists(true)}
          toggleResetOtp={toggleResetOtp}
          setToggleResetOtp={(value: boolean) => setToggleResetOtp(value)}
          resendOtp={(purpose: string = "Updating the IP List") =>
            sendOtp(purpose)
          }
          isLoading={buttonLoading}
          purpose="Updating the IP list"
          onCancel={() => {
            setToggleOtpVerificationPopUp(
              (toggleOtpVerificationPopUp: any) => ({
                ...toggleOtpVerificationPopUp,
                toggle: false,
                // ips: listOfIpAddress,
              })
            );
          }}
          onVerifyOtp={(zpin: string, otp: string, setInvalidOtpError: any) =>
            handleAddIpAddressWithZpin(zpin, otp, setInvalidOtpError)
          }
          handleZpinAuth={(
            zpin: string,
            otp: string,
            setInvalidZpinError: any
          ) => handleAddIpAddressWithZpin(zpin, otp, setInvalidZpinError)}
        />
      )}
      <div className={`${Styles.background}`}>
        <div className="d-flex justify-content-between align-items-center px-3 pl-5">
          <DarkLogoSVG />
          <TopBar />
        </div>
        <div className="px-4 pt-4 pb-2 mx-3">
          <div
            id="go-back-navigation"
            className="d-flex align-items-center"
            onClick={() => handleBackNavigation()}
            style={{ cursor: "pointer" }}
          >
            <div className={`px-1 ${Styles.rotateRight}`}>
              <ArrowUp color={BLACKCOLOR} />
            </div>
            <p className={"mb-0 px-2 heading_text"}>App Settings</p>
          </div>
        </div>
        <div className="px-5 py-3">
          <div
            className={"d-flex justify-content-center align-items-center pt-3"}
          >
            <Accordion
              defaultActiveKey="0"
              activeKey={activeKey}
              className={`${Styles.formWidth} py-2`}
            >
              {/* IP whitelist Section  */}
              <div className="py-2">
                <CustomToggle
                  eventKey="1"
                  onClick={() => setActiveKey("1")}
                  header="IP Whitelisting"
                />
                <Accordion.Collapse eventKey="1">
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <IpWhiteListing
                      zpinExists={zpinExists}
                      isIpAddressUpdated={isIpAddressUpdated}
                      setIsIpAddressUpdated={(value: boolean) =>
                        setIsIpAddressUpdated(value)
                      }
                      onAddingMultipleIpAddress={(value: string) =>
                        onAddIpAddress(value)
                      }
                      listOfIpAddress={listOfIpAddress}
                      removeIpAddress={(ipAddress: string) =>
                        setListOfIpAddress(
                          listOfIpAddress.filter((ip: any) => {
                            return ipAddress !== ip;
                          })
                        )
                      }
                      addIpAddressOnceRemoved={(ipAddress: string) => {
                        setListOfIpAddress(listOfIpAddress.concat(ipAddress));
                      }}
                      ipAddresses={ipAddresses}
                      setIPAddresses={(value: string) => setIPAddresses(value)}
                      enableAddingIpAddress={enableAddingIpAddress}
                      setEnableAddingIpAddress={(value: boolean) =>
                        setEnableAddingIpAddress(value)
                      }
                      error={error.ipAddress}
                      setError={(value: string) => {
                        setError((error) => ({
                          ...error,
                          ipAddress: value,
                        }));
                      }}
                      onCancel={() => {
                        setIPAddresses("");
                        setError((error) => ({
                          ...error,
                          ipAddress: "",
                        }));
                        setListOfIpAddress(updatedListOfIpAddress);
                        setAllIpWhitelisted(
                          updatedListOfIpAddress.length !== 0
                        );
                      }}
                      onSubmit={(value: any) => {
                        if (!value.withZpin) setRelayId(value.relay_id);
                        setError((error) => ({
                          ...error,
                          ipAddress: "",
                        }));
                        setIPAddresses("");
                        setToggleOtpVerificationPopUp(
                          (toggleOtpVerificationPopUp: any) => ({
                            ...toggleOtpVerificationPopUp,
                            toggle: true,
                            ips: value.ips.length !== 0 ? value.ips : ["ALL"],
                          })
                        );
                      }}
                      allIpWhitelisted={allIpWhitelisted}
                      setToggleAllIpWhitelisting={(value: boolean) => {
                        setAllIpWhitelisted(value);
                        setListOfIpAddress([]);
                      }}
                      listOfRemovedIpAddress={listOfRemovedIpAddress}
                      setListOfRemovedIpAddress={(value: any) =>
                        setListOfRemovedIpAddress(value)
                      }
                      savedIpCount={savedIpCount}
                    />
                  </div>
                </Accordion.Collapse>
              </div>
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppSettings;
