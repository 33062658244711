//User wishes to keep the plan for later subscription
import React from "react";

// Styles
import Styles from "../../../../Shared/popup.module.scss";
import classes from "./enableLaterConfirmationPopUp.module.scss";
import {
  primaryButtonStyle,
  primaryButtonHoverStyle,
} from "../../../../Shared/buttonStyles";

// Constants
import requestRegistered from "../../../../assets/images/requestRegistered.svg";
import { LARGE } from "../../../../Shared/buttonSize";

// Components
import Button from "../../../../Shared/button/button";

function EnableLaterConfirmationPopUp({ onConfirm, onCancel }: any) {
  return (
    <div
      className={`d-flex align-items-center justify-content-center ${Styles.overlay}`}
    >
      <div className={`p-4 ${Styles.popUpContent}`} style={{ width: "500px" }}>
        <div className="py-2 text-center">
          <img
            src={requestRegistered}
            alt="request-registered"
            style={{ width: "100px" }}
          />
        </div>
        <div className="py-2">
          <p className={`mb-0 ${classes.headingText}`}>
            Request has been registered.
          </p>
          <div className="py-1">
            <p className={`mb-0 ${classes.subText}`}>
              Please proceed to payment details.
            </p>
          </div>
        </div>
        <div className="py-3 text-center" style={{ margin: "0 auto" }}>
          <Button
            hoveredStyle={primaryButtonHoverStyle}
            style={primaryButtonStyle}
            size={LARGE}
            onClick={() => onConfirm()}
            id="z-billing-btn-send-email"
          >
            Send via Email
          </Button>
          <div className="py-3 d-flex justify-content-center">
            <p
              className={`mb-0 ${classes.cancelText} d-inline-flex`}
              onClick={() => onCancel()}
              id="z-billing-btn-send-cancel"
            >
              Cancel
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EnableLaterConfirmationPopUp;
