//Email regarding what documents required, sent via mail after confirmation

import React from "react";

// Styles
import Styles from "../../../../Shared/popup.module.scss";
import classes from "./emailSentPopUp.module.scss";
import {
  primaryButtonStyle,
  primaryButtonHoverStyle,
} from "../../../../Shared/buttonStyles";

// Constants
import emailSentIllustration from "../../../../assets/images/email_sent.svg";
import { MEDIUM } from "../../../../Shared/buttonSize";

// Components
import Button from "../../../../Shared/button/button";

function EmailSentPopUp({ onConfirm }: any) {
  const user: any = localStorage.getItem("@user");
  const parsedUser = JSON.parse(user);
  return (
    <div
      className={`d-flex align-items-center justify-content-center ${Styles.overlay}`}
    >
      <div className={`p-4 ${Styles.popUpContent}`} style={{ width: "500px" }}>
        <div className="py-2 text-center">
          <img
            src={emailSentIllustration}
            alt="email-sent"
            style={{ width: "100px" }}
          />
        </div>
        <div className="py-4">
          <p className={`mb-0 ${classes.headingText}`}>Email Sent</p>
          <div className="py-1">
            <p className={`mb-0 ${classes.subText}`}>
              We have sent the Bank details on{" "}
              <span className={classes.spanText}>{parsedUser.email}</span>
            </p>
          </div>
        </div>
        <div className="py-3 text-center" style={{ margin: "0 auto" }}>
          <Button
            hoveredStyle={primaryButtonHoverStyle}
            style={primaryButtonStyle}
            size={MEDIUM}
            onClick={() => onConfirm()}
            id="z-billing-btn-emailsent"
          >
            Okay
          </Button>
        </div>
      </div>
    </div>
  );
}

export default EmailSentPopUp;
