// Component rendered on showing pending approval state confirmation to user
import React from "react";

// Styles
import Styles from "../../../../Shared/popup.module.scss";
import {
  primaryButtonStyle,
  primaryButtonHoverStyle,
} from "../../../../Shared/buttonStyles";
import classes from "./pendingApproval.module.scss";

// constants
import requestRegistered from "../../../../assets/images/requestRegistered.svg";
import { MEDIUM } from "../../../../Shared/buttonSize";

// Components
import Button from "../../../../Shared/button/button";

function PendingApproval({ onConfirm }: any) {
  return (
    <div
      className={`d-flex align-items-center justify-content-center ${Styles.overlay}`}
    >
      <div className={`p-4 ${Styles.popUpContent}`} style={{ width: "500px" }}>
        <div className="py-3 text-center">
          <img
            src={requestRegistered}
            alt="request-registered"
            style={{ width: "100px" }}
          />
        </div>
        <div className="py-3">
          <p className={`mb-0 ${classes.headingText}`}>
            Your request has been submitted
          </p>
          <p className={`mb-0 ${classes.subText}`}>
            Our team will contact you for further details
          </p>
        </div>
        <div className="py-3 d-flex justify-content-center">
          <div>
            <Button
              hoveredStyle={primaryButtonHoverStyle}
              style={primaryButtonStyle}
              id="pending-approval-confirn"
              onClick={() => onConfirm()}
              size={MEDIUM}
            >
              Confirm
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PendingApproval;
