// Second step of onboarding providing the user to add trial services
//  to the default Test app created.
import React, { useEffect, useContext } from "react";
import Styles from "./selectService.module.scss";
import { useHistory } from "react-router-dom";
import Loading from "../../../Shared/loading/loading";
import { ACCENTCOLOR } from "../../../Shared/colors";
import ServiceCard from "./service-card/serviceCard";
import ArrowDown from "../../../Shared/svg/arrowDown";
import { Accordion } from "react-bootstrap";
import Button from "../../../Shared/button/button";
import {
  primaryButtonStyle,
  primaryButtonHoverStyle,
} from "../../../Shared/buttonStyles";
import { callApi } from "../../../api/fetch";
import TransitionPopUp from "../transition-pop-up/transitionPopUp";
import Toast from "../../../Shared/toast/toast";
import { onboardingSidebarContext } from "../../../utils/onboardingSidebarContext";
import { default_service } from "../../../constants/onboardingConstants";
import { LARGE } from "../../../Shared/buttonSize";
import { useTitle } from "../../../Shared/useTitle";
import { customEvent } from "../../../utils/ga4";

function SelectService() {
  const sidebarContext = useContext(onboardingSidebarContext);
  const history = useHistory();
  window.addEventListener("popstate", () => {
    history.go(1);
  });
  const [activeKey, setActiveKey] = React.useState(0);
  const [checkedItems, setCheckedItems] = React.useState<Record<string, any>[]>(
    []
  );
  const [trialServices, setTrialServices] = React.useState<any>([]);
  const [categoryArray, setCategoryArray] = React.useState<any>([]);
  const [loading, setLoading] = React.useState(false);
  const [showDisabledButton, setShowDisabledButton] = React.useState(true);
  const [
    toggleSkipForNowTransitionPopUp,
    setToggleSkipForNowTransitionPopUp,
  ] = React.useState(false);
  const messageArray = [
    "You can use the Trial Application created for you to test our services instantly.",
    "You can select other services from within the dashboard.",
    "Refer the documentations to guide you through the process and help you get started immediately.",
  ];
  const [loaderMessage, setLoaderMessage] = React.useState(messageArray[0]);
  const [toast, setToast] = React.useState({
    message: "",
    type: "",
    show: false,
  });
  let filterer: any = [];
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      callApi("/product?show_while_onboarding=1", {
        method: "GET",
      })
        .then((responses: any) => {
          setTrialServices(responses);
          const tmpcategoryArray: any = [];
          if (responses.length !== 0) {
            tmpcategoryArray.push(responses[0].category);
            responses.forEach((response: any) => {
              if (!tmpcategoryArray.includes(response.category)) {
                tmpcategoryArray.push(response.category);
              }
            });
          }
          setCategoryArray(tmpcategoryArray);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          toastTimeout(true, "error", error.message);
          // TODO: add sentry error
        });
    }, 1000);
  }, [setTrialServices, setCategoryArray, setLoading]);

  function CustomToggle({ children, onClick, eventKey }: any) {
    return (
      <div
        id={`custom-toggle-${eventKey}`}
        className={
          activeKey === eventKey ? Styles.activeToggle : Styles.customToggle
        }
        onClick={onClick}
      >
        {children}
      </div>
    );
  }

  function toastTimeout(
    showToastValue: boolean,
    toastType: string,
    toastMessage: string
  ) {
    setToast((toast: any) => ({
      ...toast,
      type: toastType,
      message: toastMessage,
      show: showToastValue,
    }));
    setTimeout(() => {
      setToast((toast: any) => ({
        ...toast,
        show: !showToastValue,
      }));
    }, 8000);
    return;
  }

  function setIntervalFunction() {
    let i = 1;
    const timer = setInterval(() => {
      if (i >= messageArray.length) {
        clearInterval(timer);
        history.push("/application/dashboard");
        return;
      }
      setLoaderMessage(messageArray[i]);
      i += 1;
    }, 3000);
    return;
  }

  function handleGoToChecklist() {
    setToggleSkipForNowTransitionPopUp(true);
    callApi(
      `/organisation/${localStorage.organisation_id}?action=skip_onboarding`,
      {
        method: "PUT",
      }
    )
      .then((response: any) => {
        localStorage.setItem("test_app_id", response._id);
        setIntervalFunction();
      })
      .catch((error) => {
        setToggleSkipForNowTransitionPopUp(false);
        toastTimeout(true, "error", error.message);
        // TODO: add sentry error
      });
    return;
  }

  const handleCheckedItem = (check: any, obj: any) => {
    let tmpCheckedItems: any = checkedItems;
    if (tmpCheckedItems.length === 0) {
      sidebarContext.setSelectServiceProgress(50);
    }
    if (check) {
      tmpCheckedItems.push(obj);
      setCheckedItems(tmpCheckedItems);
    } else {
      tmpCheckedItems = checkedItems.filter(
        (item: any) => item._id !== obj._id
      );
      if (tmpCheckedItems.length === 0) {
        sidebarContext.setSelectServiceProgress(10);
      }
      setCheckedItems(tmpCheckedItems);
    }
    if (tmpCheckedItems.length !== 0) {
      setShowDisabledButton(false);
    } else {
      setShowDisabledButton(true);
    }
    return;
  };
  async function handelFormSubmission(event: any) {
    setLoading(true);
    event.preventDefault();
    const servicesChecked: any = checkedItems.map(({ _id }) => _id);
    try {
      const getApps = await callApi("/app?page=select-service", {
        method: "GET",
      });
      if (!getApps.length) {
        const data = await callApi("/app", {
          method: "POST",
          data: {
            name: "Test App",
          },
          init: {
            headers: {
              "x-org-id": `${localStorage.getItem("organisation_id")}`,
            },
          },
        });
        localStorage.setItem("test_app_id", data._id);
      } else localStorage.setItem("test_app_id", getApps[0]._id);
      // call the subscribe products api
      try {
        await callApi(
          `/organisation/${localStorage.organisation_id}?action=org_product_subscribe`,
          {
            method: "PUT",
            data: {
              productIds: servicesChecked,
            },
          }
        );
        sidebarContext.setStepCompleted([
          ...sidebarContext.step_completed,
          default_service,
        ]);
        sidebarContext.setAppCreated(true);
        sidebarContext.setNextStep("");
        sidebarContext.setSelectServiceProgress(100);
        for (const items of checkedItems) {
          const category = `${items.category}_Product`;
          customEvent(
            category,
            "Products_Subscribed_While_Onboarding",
            `${items.name} subscribed`
          );
        }
        history.push("/welcome", {
          subscribedService: checkedItems,
        });
      } catch (err) {
        toastTimeout(true, "error", (err as Error).message);
      }
    } catch (err) {
      toastTimeout(true, "error", (err as Error).message);
    } finally {
      setLoading(false);
    }
  }
  useTitle("Zoop Customer Platform | Select Service");
  return (
    <div>
      <div
        className="container-fluid p-0"
        style={
          toggleSkipForNowTransitionPopUp
            ? { filter: "blur(5px)" }
            : { filter: "blur(0)" }
        }
      >
        {toast.show && (
          <Toast
            message={toast.message}
            type={toast.type}
            click={() =>
              setToast((toast: any) => ({
                ...toast,
                show: false,
              }))
            }
          />
        )}
        <div className={`${Styles.authActionsBackground}`}>
          {loading ? (
            <div
              style={{
                height: "100vh",
              }}
              className="d-flex align-items-center justify-content-center"
            >
              <Loading loadingColor={ACCENTCOLOR} />
            </div>
          ) : (
            <div
              style={{ height: "100vh", overflow: "auto" }}
              className="d-flex justify-content-center"
            >
              <div style={{ padding: "40px 0 0" }}>
                <div className="pb-3">
                  <p className={`${Styles.headingText} mb-0 text-center`}>
                    Select the Products that you wish to test.
                  </p>
                  <p className={`${Styles.educationalText} mb-0 text-center`}>
                    Some Products might require approval
                  </p>
                </div>
                <div className={`${Styles.formWidth} pb-4`}>
                  <Accordion
                    defaultActiveKey="0"
                    activeKey={activeKey.toString()}
                  >
                    {categoryArray.map((category: any, index: number) => {
                      filterer = trialServices.filter(
                        (service: any) => service.category === category
                      );
                      return (
                        <div key={category} style={{ padding: "13px 0" }}>
                          <CustomToggle
                            id={`custom-toggle-${index}`}
                            eventKey={index}
                            onClick={() => setActiveKey(index)}
                          >
                            <div
                              className="d-flex align-items-center"
                              style={{
                                cursor: "pointer",
                                position: "relative",
                                zIndex: 1,
                              }}
                            >
                              <div className="px-2 flex-grow-1">
                                <div className="d-flex">
                                  <p
                                    className={`mb-0 ${Styles.accordion_header}`}
                                  >
                                    {category}
                                  </p>
                                </div>
                              </div>
                              <div className="px-2">
                                {activeKey === index ? (
                                  <ArrowDown />
                                ) : (
                                  <div className={Styles.rotateArrowRight}>
                                    <ArrowDown />
                                  </div>
                                )}
                              </div>
                            </div>
                          </CustomToggle>
                          <Accordion.Collapse eventKey={index.toString()}>
                            <div
                              style={{
                                position: "relative",
                                zIndex: 0,
                              }}
                            >
                              <div className={`${Styles.formBG} pt-2`}>
                                {filterer.map((categorisedService: any) => (
                                  <div key={categorisedService._id}>
                                    <ServiceCard
                                      serviceDetails={categorisedService}
                                      handleCheckedItem={(
                                        check: any,
                                        name: any
                                      ) => handleCheckedItem(check, name)}
                                    />
                                    <hr style={{ margin: "0" }} />
                                  </div>
                                ))}
                              </div>
                            </div>
                          </Accordion.Collapse>
                        </div>
                      );
                    })}
                  </Accordion>
                  <div className="text-center py-3">
                    <div className="py-2" style={{ margin: "0 auto" }}>
                      <Button
                        size={LARGE}
                        hoveredStyle={primaryButtonHoverStyle}
                        buttontext="Next"
                        disabled={loading || showDisabledButton}
                        style={primaryButtonStyle}
                        isLoading={loading}
                        id="next-step"
                        onClick={handelFormSubmission}
                      >
                        Next
                      </Button>
                    </div>
                    <div
                      id="skip-for-now"
                      className={`${Styles.blockButton}`}
                      onClick={handleGoToChecklist}
                    >
                      <p className={`${Styles.linkText} mb-0`}>Skip for now</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {toggleSkipForNowTransitionPopUp ? (
        <TransitionPopUp headingText="Welcome! Your account setup is complete!">
          <p className={`mb-0 ${Styles.stepText}`}>{loaderMessage}</p>
        </TransitionPopUp>
      ) : null}
    </div>
  );
}

export default SelectService;
