// Library Utilities
import React from "react";
import IdleTimer, { useIdleTimer } from "react-idle-timer";
// Hooks
import { useHistory } from "react-router";

export default function IdleTimerContainer(props: any) {
  const history = useHistory();
  const handleOnIdle = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("organisation_id");
    history.replace("/login");
  };

  useIdleTimer({
    // change the timeout its configurable
    timeout: 1000 * 60 * 30,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  return (
    <div>
      <IdleTimer>{props.children}</IdleTimer>
    </div>
  );
}
