// Card for displaying plan details
import React from "react";

// Styles
import Styles from "./planCard.module.scss";
import { ACCENTCOLOR, PRIMARYCOLOR } from "../../../../../Shared/colors";

// Constants
import RupeeSVG from "../../../../../Shared/svg/rupeeSVG";
import {
  ACCOUNT_ACTIVATION_PENDING,
  ACCOUNT_ACTIVATION_REQUESTED,
} from "../../../../../constants/accountActivationStates";

// Functions
import { callApi } from "../../../../../api/fetch";

// Components
import Loading from "../../../../../Shared/loading/loading";
import Toast from "../../../../../Shared/toast/toast";
import KycDetailsPopUp from "../../kyc-details-pop-up/kycDetailsPopUp";

function PlanCard({ onRequestPlan, ...props }: any) {
  const planTypes = {
    PREPAID: "PREPAID",
    POSTPAID: "POSTPAID",
  };
  const [loading, setLoading] = React.useState(false);
  const [toast, setToast] = React.useState({
    message: "",
    type: "",
    show: false,
  });
  const [toggleKycDetailsPopUp, setToggleKycDetailsPopUp] = React.useState(
    false
  );
  const [
    accountActivationRequestLoading,
    setAccountActivationRequestLoading,
  ] = React.useState(false);
  async function requestPlan() {
    if (props.country_code !== "IN") {
      onRequestPlan({
        international_customer: true,
        customerType: "international",
      });
      return;
    }
    try {
      const organisationId = localStorage.getItem("organisation_id");
      setLoading(true);
      const response = await callApi(
        `/organisation/${organisationId}/virtual-account`,
        {
          method: "GET",
        }
      );
      setLoading(false);
      onRequestPlan(response);
    } catch (err) {
      const error: any = err;
      setLoading(false);
      if (error.message === ACCOUNT_ACTIVATION_REQUESTED) {
        toastTimeout(
          true,
          "error",
          "Complete your account activation process to proceed ahead."
        );
        return;
      } else if (error.message === ACCOUNT_ACTIVATION_PENDING) {
        setToggleKycDetailsPopUp(true);
        return;
      }
      toastTimeout(true, "error", error.message);
    }
  }

  async function requestAccountActivation() {
    setAccountActivationRequestLoading(true);
    try {
      await callApi("/events?action=account_activation_request", {
        method: "POST",
      });
      setAccountActivationRequestLoading(false);
      setToggleKycDetailsPopUp(false);
    } catch (err) {
      setAccountActivationRequestLoading(false);
      setToggleKycDetailsPopUp(false);
      toastTimeout(true, "error", (err as Error).message);
    }
  }

  function toastTimeout(
    showToastValue: boolean,
    toastType: string,
    toastMessage: string
  ) {
    setToast((toast: any) => ({
      ...toast,
      type: toastType,
      message: toastMessage,
      show: showToastValue,
    }));
    setTimeout(() => {
      setToast((toast: any) => ({
        ...toast,
        show: !showToastValue,
      }));
    }, 8000);
    return;
  }

  return (
    <div className={Styles.planCardBack}>
      {toast.show && (
        <Toast
          message={toast.message}
          type={toast.type}
          click={() =>
            setToast((toast: any) => ({
              ...toast,
              show: false,
            }))
          }
        />
      )}
      <div className={`p-3 d-flex align-items-center ${Styles.cardHeader}`}>
        <p className={`mb-0 px-2 ${Styles.planname}`}>{props.planname}</p>
      </div>
      <div className="p-3">
        <div className="p-2">
          <p className={`mb-0 ${Styles.label}`}>
            Amount{" "}
            <span className={`mb-0 ${Styles.label} px-1`}>(Excl. taxes)</span>
          </p>
          <div className="d-flex align-items-center">
            <div className="pr-1">
              <div style={{ width: "100%", height: "100%" }}>
                <RupeeSVG color={PRIMARYCOLOR} width="7" height="14" />
              </div>
            </div>
            <div className="pr-1">
              <p className={`mb-0 ${Styles.value}`}>{props.amount}</p>
            </div>
          </div>
          <div className="py-2">
            <p className={`mb-0 ${Styles.label}`}>Validity</p>
            <p className={`mb-0 ${Styles.value}`}>{props.validity}</p>
          </div>
        </div>
        <div className="py-2 d-flex align-items-center justify-content-center">
          {props.currentPlan && !props.renewPlan ? (
            <div className="d-flex align-items-center justify-content-center">
              <p
                className={`mb-0 ${Styles.currentPlanTabText}`}
                style={{ cursor: "no-drop" }}
              >
                Current Plan
              </p>
            </div>
          ) : props.renewPlan ? (
            <div className="d-flex align-items-center justify-content-center">
              <p
                className={`mb-0 ${Styles.renewPlanText}`}
                style={{ cursor: "pointer" }}
                id="plan-card-renew-plan"
                onClick={() => requestPlan()}
              >
                Renew Plan
              </p>
            </div>
          ) : props.pendingPayment ? (
            <div className="d-flex align-items-center justify-content-center">
              <p
                className={`mb-0 ${Styles.pendingPaymentText}`}
                style={{ cursor: "no-drop" }}
              >
                Payment Pending
              </p>
            </div>
          ) : props.paymentReceived ? (
            <div className="d-flex align-items-center justify-content-center">
              <p
                className={`mb-0 ${Styles.currentPlanTabText}`}
                style={{ cursor: "no-drop" }}
              >
                Payment Received
              </p>
            </div>
          ) : loading ? (
            <Loading loadingColor={ACCENTCOLOR} />
          ) : props.isPaymentReceived ||
            props.org_subscribed_plan_type === planTypes.POSTPAID ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ cursor: "no-drop", opacity: 0.7 }}
            >
              <p className={`mb-0 ${Styles.disabled}`}>Request Plan</p>
            </div>
          ) : (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ cursor: "pointer" }}
              id="plan-card-request-plan"
              onClick={() => requestPlan()}
            >
              <p className={`mb-0 ${Styles.choosePlan}`}>Request Plan</p>
            </div>
          )}
        </div>
      </div>
      {toggleKycDetailsPopUp ? (
        <KycDetailsPopUp
          isLoading={accountActivationRequestLoading}
          customerType="national"
          onCancel={() => setToggleKycDetailsPopUp(false)}
          onSendOverEmail={() => {
            requestAccountActivation();
          }}
        />
      ) : null}
    </div>
  );
}

export default PlanCard;
