import React, { useEffect } from "react";

// Styles
import Styles from "./sidebar.module.scss";

// Constants
import {
  DASHBOARD,
  REPORTS,
  MANAGEAPPS,
  PRODUCTCATALOG,
  BILLING,
  BATCHSERVICE,
  TRIALCENTER,
} from "../../constants/sidebar";
import DashboardSVGNew from "../svg/dashboardSVGnew";
import ProductsSVGNew from "../svg/productsSVGnew";
import AppsSVGNew from "../svg/appsSVGnew";
import DarkLogoSVG from "../svg/darkLogoSVG";
import BatchServiceSVGNew from "../svg/batchServiceSVGnew";
import TrialCenterSVGNew from "../svg/trialCenterSVGnew";
import ReportsSVGNew from "../svg/reportsSVGnew";
import BillingSVG from "../svg/billingSVG";
import ArrowLeftSVG from "../svg/arrowLeft";
import ArrowRightSVG from "../svg/arrowRight";
import DarkLogoSVGMini from "../svg/darkLogoSVGMini";
import { ONBOARDING_STATUS } from "../../constants/onboardingStatuses";

// Hooks
import { useHistory, NavLink, useLocation } from "react-router-dom";

// Functions
import { customEvent } from "../../utils/ga4";

function Sidebar({
  toggleCollapseSideBar,
  collapsedView,
  toggleSideBar = () => {},
  onboardingStatus,
  batchActivated,
}: any) {
  const location = useLocation();
  const history = useHistory();

  const [activeLink, setActiveLink] = React.useState(DASHBOARD);

  // To change the routing onClick of sidenav tabs
  function changeRouting(value: string) {
    setActiveLink(value);
    let str = value.toLowerCase();
    str = str.replace(" ", "-");
    history.push(`/application/${str}`);
    toggleSideBar(false);
    customEvent("Trial-Center-Page", "Trial-Center-clicked", "Trial-Center");
  }

  // To activate the Tab by route name
  useEffect(() => {
    const url = location.pathname.split("/");
    if (url.includes("dashboard")) {
      setActiveLink(DASHBOARD);
    } else if (url.includes("reports")) {
      setActiveLink(REPORTS);
    } else if (url.includes("product-catalog")) {
      setActiveLink(PRODUCTCATALOG);
    } else if (url.includes("manage-apps")) {
      setActiveLink(MANAGEAPPS);
    } else if (url.includes("billing")) {
      setActiveLink(BILLING);
    } else if (url.includes("batch-service")) {
      setActiveLink(BATCHSERVICE);
    } else if (url.includes("trial-center")) {
      setActiveLink(TRIALCENTER);
    }
  }, [location.pathname]);

  return (
    <div
      className={
        collapsedView
          ? `${Styles.sidebarCollapsed}`
          : `${Styles.sideBarBackground}`
      }
      style={{
        zIndex: 1,
      }}
    >
      <div className="ml-4 mb-4 d-flex">
        {collapsedView ? <DarkLogoSVGMini /> : <DarkLogoSVG />}
        <button
          id="sidebar-collapsed-view"
          onClick={() => toggleCollapseSideBar()}
          className={`d-flex align-items-center justify-content-center ${
            collapsedView ? "collapse_button_active" : "collapse_button"
          }`}
        >
          {collapsedView ? <ArrowRightSVG /> : <ArrowLeftSVG />}
        </button>
      </div>
      <div
        style={{
          height: "90%",
          overflow: "auto",
        }}
      >
        <NavLink
          className={activeLink === DASHBOARD ? Styles.activeLink : Styles.link}
          to="/application/dashboard"
          id="sidebar-change-routing-dashboard"
          onClick={() => changeRouting(DASHBOARD)}
        >
          <div
            className={`d-flex align-items-center ${
              activeLink === DASHBOARD
                ? Styles.activeState
                : Styles.nonActiveState
            } ${
              collapsedView
                ? `justify-content-center ${Styles.collapsedHover}`
                : ""
            }`}
            style={{ paddingLeft: collapsedView ? 0 : "30px" }}
          >
            {activeLink === DASHBOARD && (
              <div className={Styles.indicator}></div>
            )}
            <div className={Styles.showTooltip}>
              <p className={`mb-0 ${Styles.tooltipText}`}>Dashboard</p>
            </div>
            <div className={collapsedView ? "pr-0" : "pr-2"}>
              <DashboardSVGNew
                className={
                  activeLink === DASHBOARD
                    ? Styles.activeStateFill
                    : Styles.nonActiveStateFill
                }
              />
            </div>
            {!collapsedView ? (
              <div className="pl-2">
                <p
                  style={{ margin: 0 }}
                  className={
                    activeLink === DASHBOARD
                      ? Styles.activeLink
                      : Styles.nonActiveLink
                  }
                >
                  {DASHBOARD}
                </p>
              </div>
            ) : null}
          </div>
        </NavLink>
        <NavLink
          className={Styles.link}
          to="/application/reports"
          id="sidebar-change-routing-reports"
          onClick={() => changeRouting(REPORTS)}
        >
          <div
            className={`d-flex align-items-center ${
              activeLink === REPORTS
                ? Styles.activeState
                : Styles.nonActiveState
            } ${
              collapsedView
                ? `justify-content-center ${Styles.collapsedHover}`
                : ""
            }`}
            style={{ paddingLeft: collapsedView ? 0 : "30px" }}
          >
            {activeLink === REPORTS && <div className={Styles.indicator}></div>}
            <div className={Styles.showTooltip}>
              <p className={`mb-0 ${Styles.tooltipText}`}>Usage</p>
            </div>
            <div className={collapsedView ? "pr-0" : "pr-2"}>
              <ReportsSVGNew
                className={
                  activeLink === REPORTS
                    ? Styles.activeStateFill
                    : Styles.nonActiveStateFill
                }
              />
            </div>
            {!collapsedView ? (
              <div className="pl-2">
                <p
                  style={{ margin: 0 }}
                  className={
                    activeLink === REPORTS
                      ? Styles.activeLink
                      : Styles.nonActiveLink
                  }
                >
                  {REPORTS}
                </p>
              </div>
            ) : null}
          </div>
        </NavLink>
        <NavLink
          className={Styles.link}
          to="/application/manage-apps"
          id="sidebar-change-routing-manage-app"
          onClick={() => changeRouting(MANAGEAPPS)}
        >
          <div
            className={`d-flex align-items-center ${
              activeLink === MANAGEAPPS
                ? Styles.activeState
                : Styles.nonActiveState
            } ${
              collapsedView
                ? `justify-content-center ${Styles.collapsedHover}`
                : ""
            }`}
            style={{ paddingLeft: collapsedView ? 0 : "30px" }}
          >
            {activeLink === MANAGEAPPS && (
              <div className={Styles.indicator}></div>
            )}
            <div className={Styles.showTooltip}>
              <p className={`mb-0 ${Styles.tooltipText}`}>Apps</p>
            </div>
            <div className={collapsedView ? "pr-0" : "pr-2"}>
              <AppsSVGNew
                className={
                  activeLink === MANAGEAPPS
                    ? Styles.activeStateFill
                    : Styles.nonActiveStateFill
                }
              />
            </div>
            {!collapsedView ? (
              <div className="pl-2">
                <p
                  style={{ margin: 0 }}
                  className={
                    activeLink === MANAGEAPPS
                      ? Styles.activeLink
                      : Styles.nonActiveLink
                  }
                >
                  {MANAGEAPPS}
                </p>
              </div>
            ) : null}
          </div>
        </NavLink>
        <NavLink
          className={Styles.link}
          to="/application/product-catalog"
          id="sidebar-change-routing-product-catalog"
          onClick={() => changeRouting(PRODUCTCATALOG)}
        >
          <div
            className={`d-flex align-items-center ${
              activeLink === PRODUCTCATALOG
                ? Styles.activeState
                : Styles.nonActiveState
            } ${
              collapsedView
                ? `justify-content-center ${Styles.collapsedHover}`
                : ""
            }`}
            style={{ paddingLeft: collapsedView ? 0 : "30px" }}
          >
            {activeLink === PRODUCTCATALOG && (
              <div className={Styles.indicator}></div>
            )}
            <div className={Styles.showTooltip}>
              <p className={`mb-0 ${Styles.tooltipText}`}>Products</p>
            </div>
            <div className={collapsedView ? "pr-0" : "pr-2"}>
              <ProductsSVGNew
                className={
                  activeLink === PRODUCTCATALOG
                    ? Styles.activeStateFill
                    : Styles.nonActiveStateFill
                }
              />
            </div>
            {!collapsedView ? (
              <div className="pl-2">
                <p
                  style={{ margin: 0 }}
                  className={
                    activeLink === PRODUCTCATALOG
                      ? Styles.activeLink
                      : Styles.nonActiveLink
                  }
                >
                  {PRODUCTCATALOG}
                </p>
              </div>
            ) : null}
          </div>
        </NavLink>
        {onboardingStatus === ONBOARDING_STATUS.PLAN_SUBSCRIBED ? (
          <>
            <NavLink
              className={Styles.link}
              to="/application/billing"
              id="sidebar-change-routing-billing"
              onClick={() => changeRouting(BILLING)}
            >
              <div
                className={`d-flex align-items-center ${
                  activeLink === BILLING
                    ? Styles.activeState
                    : Styles.nonActiveState
                } ${
                  collapsedView
                    ? `justify-content-center ${Styles.collapsedHover}`
                    : ""
                }`}
                style={{ paddingLeft: collapsedView ? 0 : "30px" }}
              >
                {activeLink === BILLING && (
                  <div className={Styles.indicator}></div>
                )}
                <div className={Styles.showTooltip}>
                  <p className={`mb-0 ${Styles.tooltipText}`}>Billing</p>
                </div>
                <div className={collapsedView ? "pr-0" : "pr-2"}>
                  <BillingSVG
                    className={
                      activeLink === BILLING
                        ? Styles.activeStateFill
                        : Styles.nonActiveStateFill
                    }
                  />
                </div>
                {!collapsedView ? (
                  <div className="pl-2">
                    <p
                      style={{ margin: 0 }}
                      className={
                        activeLink === BILLING
                          ? Styles.activeLink
                          : Styles.nonActiveLink
                      }
                    >
                      {BILLING}
                    </p>
                  </div>
                ) : null}
              </div>
            </NavLink>
          </>
        ) : null}

        {batchActivated &&
        onboardingStatus === ONBOARDING_STATUS.PLAN_SUBSCRIBED ? (
          <NavLink
            className={Styles.link}
            to="/application/batch-service"
            id="sidebar-change-routing-batch-service"
            onClick={() => changeRouting(BATCHSERVICE)}
          >
            <div
              className={`d-flex align-items-center ${
                activeLink === BATCHSERVICE
                  ? Styles.activeState
                  : Styles.nonActiveState
              } ${
                collapsedView
                  ? `justify-content-center ${Styles.collapsedHover}`
                  : ""
              }`}
              style={{ paddingLeft: collapsedView ? 0 : "30px" }}
            >
              {activeLink === BATCHSERVICE && (
                <div className={Styles.indicator}></div>
              )}
              <div className={Styles.showTooltip}>
                <p className={`mb-0 ${Styles.tooltipText}`}>Batch Service</p>
              </div>
              <div className={collapsedView ? "pr-0" : "pr-2"}>
                <BatchServiceSVGNew
                  className={
                    activeLink === BATCHSERVICE
                      ? Styles.activeStateFill
                      : Styles.nonActiveStateFill
                  }
                />
              </div>
              {!collapsedView ? (
                <div className="pl-2">
                  <p
                    style={{ margin: 0 }}
                    className={
                      activeLink === BATCHSERVICE
                        ? Styles.activeLink
                        : Styles.nonActiveLink
                    }
                  >
                    {BATCHSERVICE}
                  </p>
                </div>
              ) : null}
            </div>
          </NavLink>
        ) : null}
        <NavLink
          className={Styles.link}
          to="/application/trial-center"
          id="sidebar-change-routing-trial-center"
          onClick={() => changeRouting(TRIALCENTER)}
        >
          <div
            className={`d-flex align-items-center ${
              activeLink === TRIALCENTER
                ? Styles.activeState
                : Styles.nonActiveState
            } ${
              collapsedView
                ? `justify-content-center ${Styles.collapsedHover}`
                : ""
            }`}
            style={{
              paddingLeft: collapsedView ? 0 : "30px",
            }}
          >
            {activeLink === TRIALCENTER && (
              <div className={Styles.indicator}></div>
            )}
            <div className={Styles.showTooltip}>
              <p className={`mb-0 ${Styles.tooltipText}`}>Trial Center</p>
            </div>
            <div className={collapsedView ? "pr-0" : "pr-2"}>
              <TrialCenterSVGNew
                className={
                  activeLink === TRIALCENTER
                    ? Styles.activeStateFill
                    : Styles.nonActiveStateFill
                }
              />
            </div>
            {!collapsedView ? (
              <div className="pl-2">
                <p
                  style={{ margin: 0 }}
                  className={
                    activeLink === TRIALCENTER
                      ? Styles.activeLink
                      : Styles.nonActiveLink
                  }
                >
                  {TRIALCENTER}
                </p>
              </div>
            ) : null}
          </div>
        </NavLink>
      </div>
      {/* <div
        className={`d-flex align-items-center ${Styles.footer}  ${
          collapsedView ? `justify-content-center ${Styles.collapsedHover}` : ""
        }`}
        onClick={() => toggleCollapseSideBar()}
        style={{ paddingLeft: collapsedView ? 0 : "30px" }}
      >
        <div className={!collapsedView ? `pl-2 ${Styles.rotate}` : "pr-0"}>
          <svg
            width="15"
            height="15"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 27.8 28"
            className={Styles.nonActiveStateFill}
          >
            <g
              id="Group_1220_1_"
              transform="translate(27.294 106.4) rotate(180)"
            >
              <g id="Group_1217-2_1_" transform="translate(-0.5 -0.6)">
                <g id="Group_1216-2_1_">
                  <path
                    id="Path_2162-2_1_"
                    d="M22,105.3l0,0.2c0,0.8-0.7,1.5-1.5,1.5c0,0,0,0,0,0H3.3c-1.8,0-3.3-1.5-3.3-3.3V82.3
				C0,80.5,1.5,79,3.3,79h17.1c0.8,0,1.5,0.6,1.5,1.4c0,0,0,0.1,0,0.1c0,0.8-0.7,1.5-1.5,1.5c0,0,0,0,0,0H3.3C3.2,82,3,82.2,3,82.3
				c0,0,0,0,0,0.1v21.3c0,0.2,0.1,0.3,0.3,0.3c0,0,0,0,0.1,0h17.1C21.1,104,21.8,104.5,22,105.3z"
                  />
                </g>
              </g>
              <g id="Group_1218-2_1_" transform="translate(9.418 4.858)">
                <path
                  id="Path_2163-2_1_"
                  d="M17.9,87.6c0,0.8-0.7,1.5-1.5,1.5c0,0,0,0,0,0H5.3l4.3,4.3c0.6,0.6,0.6,1.6,0,2.2
			c-0.6,0.6-1.6,0.6-2.2,0l-7-7c-0.1-0.1-0.1-0.1-0.2-0.2c0,0,0,0,0-0.1c0-0.1-0.1-0.1-0.1-0.2c0,0,0-0.1,0-0.1
			C0.1,88.1,0,88,0,87.9v0c0-0.2,0-0.4,0-0.6v0c0-0.1,0-0.2,0.1-0.2c0,0,0,0,0,0c0-0.1,0.1-0.2,0.1-0.2c0,0,0,0,0,0
			c0.1-0.1,0.1-0.2,0.2-0.2c0,0,0.1-0.1,0.1-0.1l6.9-6.9c0.6-0.6,1.6-0.6,2.2,0c0.6,0.6,0.6,1.6,0,2.2L5.3,86h11.1
			C17.2,86,17.9,86.7,17.9,87.6z"
                />
              </g>
            </g>
          </svg>
        </div>
        {!collapsedView ? (
          <div className="pl-2">
            <p style={{ margin: 0 }} className={Styles.nonActiveLink}>
              Collapse
            </p>
          </div>
        ) : null}
      </div> */}
    </div>
  );
}

export default Sidebar;
