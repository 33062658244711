// Card for showing different product on main screen of productCatalog as card list

// Library Utilities
import React from "react";

// Styles
import Styles from "./serviceCard.module.scss";
import { ACCENTCOLOR, SUCCESS } from "../../../../Shared/colors";
import {
  successButtonStyle,
  successButtonHoverStyle,
} from "../../../../Shared/buttonStyles";

// Constants
import DocsSVG from "../../../../Shared/svg/docsSVG";
import { COMPRESSED } from "../../../../Shared/buttonSize";

// Components
import Tooltip from "../../../../Shared/tooltip/tooltip";
import Loading from "../../../../Shared/loading/loading";
import Button from "../../../../Shared/button/button";

// Functions
import { productToLinkMap } from "../../developer-docs/common/constants";

function ServiceCard({ productDetails, click, isLoading }: any) {
  function handleSubscribeService() {
    click("subscribe");
    return;
  }
  function handelUnsubscribeService() {
    click("unsubscribe");
    return;
  }
  return (
    <div>
      {isLoading.isLoading && isLoading.serviceName === productDetails.name ? (
        <div
          className={`d-flex align-items-center justify-content-center ${Styles.loadingCard}`}
        >
          <Loading loadingColor={ACCENTCOLOR} />
        </div>
      ) : (
        <div className={`${Styles.card} ${Styles.cardAnimation}`}>
          <div
            className="d-flex align-items-center p-3"
            style={{
              borderBottom: "1px solid rgba(112, 112, 112, 0.2)",
              height: "65px",
            }}
          >
            <p className={"mb-0 px-2 title_bold_text"}>{productDetails.name}</p>
          </div>
          <div className="p-3">
            <div className="pb-3 pt-1 d-flex align-items-center">
              <div className="pr-2">
                {/* trial transaction text here */}
                {productDetails.subscription_status === "SUBSCRIBED" ? (
                  <div className="d-flex align-items-center px-2">
                    <p className={`mb-0 ${Styles.trialTransactionText}`}>
                      Trial Transactions:
                    </p>
                    <div className="pl-1">
                      <p className={`mb-0 ${Styles.transactionCount}`}>
                        {productDetails.default_free_transactions}
                      </p>
                    </div>
                  </div>
                ) : !productDetails.auto_approval &&
                  productDetails.subscription_status !== "SUBSCRIBED" ? (
                  <div
                    className={`d-flex align-items-center ${Styles.badgeWrapper} py-1`}
                  >
                    <div className="pl-2 pr-1">
                      <div
                        className={`${Styles.circle} bg-strong-orange-color`}
                        // style={{ backgroundColor: DANGER }}
                      ></div>
                    </div>
                    <div className="pl-1 pr-3">
                      <p className={`mb-0 ${Styles.approvalRequired}`}>
                        Requires Approval
                      </p>
                    </div>
                  </div>
                ) : (
                  <div
                    className={`d-flex align-items-center ${Styles.badgeWrapper} py-1`}
                  >
                    <div className="pl-2 pr-1">
                      <div
                        className={Styles.circle}
                        style={{ backgroundColor: SUCCESS }}
                      ></div>
                    </div>
                    <div className="pr-3 pl-1">
                      <p className={`mb-0 ${Styles.approvalRequired}`}>
                        Pre-Approved
                      </p>
                    </div>
                  </div>
                )}
              </div>
              {productDetails.subscription_status === "SUBSCRIBED" ? (
                <div className="px-2 ml-auto">
                  <Tooltip placement="bottom" title="View Docs">
                    <button
                      style={{
                        background: "transparent",
                        outline: "none",
                        border: "none",
                      }}
                      id="service-card-view-docs"
                      onClick={(e) => {
                        e.preventDefault();
                        if (productToLinkMap[productDetails.name]) {
                          window.location.href =
                            productToLinkMap[productDetails.name];
                        } else {
                          const type = productDetails.arn
                            .split("_")[2]
                            .toUpperCase();
                          const name = productDetails.name
                            .toLowerCase()
                            .replaceAll(" ", "_");
                          window.location.href = `/doc#tag/${type}/operation/${name}`;
                        }
                      }}
                    >
                      <DocsSVG classes={Styles.SVGcolor} />
                    </button>
                  </Tooltip>
                </div>
              ) : null}
            </div>
            <div className="py-2 px-2">
              <div style={{ height: "90px" }}>
                <p className={`mb-0 ${Styles.serviceDescription}`}>
                  {productDetails.short_description}
                </p>
              </div>
            </div>
            <div
              className="py-2 px-1 d-flex align-items-center"
              style={{ height: "44px" }}
            >
              <div className="px-2">
                {/* deactivate key text here  */}
                {productDetails.subscription_status === "SUBSCRIBED" ? (
                  <p
                    className={`mb-0 ${Styles.unsubscribeService}`}
                    id="service-card-unsubscribe"
                    onClick={handelUnsubscribeService}
                  >
                    Unsubscribe
                  </p>
                ) : null}
              </div>
              <div className="px-2 ml-auto">
                {/* subscribe service below  */}
                {productDetails.subscription_status === "UNSUBSCRIBED" ||
                productDetails.subscription_status === "REJECTED" ? (
                  <Button
                    style={successButtonStyle}
                    hoveredStyle={successButtonHoverStyle}
                    size={COMPRESSED}
                    disabled={isLoading.isLoading}
                    id="service-card-subscribe"
                    onClick={() => handleSubscribeService()}
                    className={Styles.subscribeBtn}
                  >
                    Subscribe
                  </Button>
                ) : null}

                {/* use this div for subscribed status */}
                {productDetails.subscription_status === "SUBSCRIBED" ? (
                  <p className={`mb-0 ${Styles.subscribedStatus}`}>
                    Subscribed
                  </p>
                ) : null}

                {/* use this div for pending state  */}
                {productDetails.subscription_status === "PENDING_APPROVAL" ? (
                  <p className={`mb-0 ${Styles.pendingStatus}`}>
                    Approval Pending
                  </p>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ServiceCard;
