import React, { useContext } from "react";

// Styles
import Styles from "./onBoardingSideBar.module.scss";
import { AUTHSIDEBARGRADIENT, SUCCESS, SECONDARYCOLOR, WHITE } from "../colors";

// Components
import ProgressBar from "../progress-bar/progressBar";

// Constants
import {
  poc_info,
  business_info,
  default_service,
} from "../../constants/onboardingConstants";
import TickSvg from "../svg/tickSvg";
import logo from "../../assets/images/logo.png";

// Functions
import { onboardingSidebarContext } from "../../utils/onboardingSidebarContext";

function OnBoardingSideBar() {
  const sideBarContext = useContext(onboardingSidebarContext);
  const {
    orgCreated,
    next_step,
    step_completed,
    appCreated,
    createOrgProgress,
    selectServiceProgress,
  } = sideBarContext;
  const showSuccess = (
    <div style={{ position: "absolute", top: "-5px", left: "3px" }}>
      <TickSvg width="20" height="20" color={WHITE} />
    </div>
  );
  return (
    <div
      className={`${Styles.onBoardingSideBar} d-flex align-items-center justify-content-center`}
      style={{ background: AUTHSIDEBARGRADIENT }}
    >
      <div style={{ width: "400px", margin: "0 auto" }}>
        <div className="pt-5">
          <div className="d-flex align-items-center pb-3">
            <div className="px-2">
              <ProgressBar
                size={50}
                strokeWidth={4}
                stepNumber="1"
                progress={createOrgProgress}
                successStroke={SUCCESS}
                progressBarStroke={SECONDARYCOLOR}
                stepCompleted={orgCreated}
                highlight={
                  next_step.includes(business_info) ||
                  next_step.includes(poc_info)
                }
              />
            </div>
            <div className="px-2">
              <p
                className={`mb-0 ${
                  orgCreated ? Styles.stepCompletedText : Styles.stepText
                }`}
                style={
                  next_step.includes(business_info) ||
                  next_step.includes(poc_info)
                    ? { opacity: 0.9 }
                    : {}
                }
              >
                Create an Organisation.
              </p>
            </div>
          </div>
          <div className="pl-5">
            <div className="d-flex align-items-center py-2">
              <div
                className={`px-2 ${
                  step_completed.includes(business_info)
                    ? Styles.stepCompleted
                    : next_step === business_info
                    ? Styles.currentStep
                    : Styles.unVisited
                }`}
              >
                {step_completed.includes(business_info) && showSuccess}
              </div>
              <div className="px-3">
                <p
                  className={`mb-0 ${
                    step_completed.includes(business_info)
                      ? Styles.stepCompletedText
                      : next_step === business_info
                      ? Styles.activeStepText
                      : Styles.stepText
                  }`}
                >
                  Enter Business information
                </p>
              </div>
            </div>
            <div className="d-flex align-items-center py-2">
              <div
                className={`px-2 ${
                  step_completed.includes(poc_info)
                    ? Styles.stepCompleted
                    : next_step === poc_info
                    ? Styles.currentStep
                    : Styles.unVisited
                }`}
              >
                {step_completed.includes(poc_info) && showSuccess}
              </div>
              <div className="px-3">
                <p
                  className={`mb-0 ${
                    step_completed.includes(poc_info)
                      ? Styles.stepCompletedText
                      : next_step === poc_info
                      ? Styles.activeStepText
                      : Styles.stepText
                  }`}
                >
                  Enter point of contact details
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-5">
          <div className=" d-flex align-items-center pb-3">
            <div className="px-2">
              <ProgressBar
                size={50}
                strokeWidth={4}
                stepNumber="2"
                progress={selectServiceProgress}
                successStroke={SUCCESS}
                progressBarStroke={SECONDARYCOLOR}
                stepCompleted={appCreated}
                highlight={next_step.includes(default_service)}
              />
            </div>
            <div className="px-2">
              <p
                className={`mb-0 ${
                  appCreated ? Styles.stepCompletedText : Styles.stepText
                }`}
                style={
                  next_step.includes(default_service) ? { opacity: 0.9 } : {}
                }
              >
                Select Service
              </p>
            </div>
          </div>
          <div className="pl-5">
            <div className="d-flex align-items-center py-2">
              <div
                className={`px-2 ${
                  step_completed.includes(default_service)
                    ? Styles.stepCompleted
                    : next_step === default_service
                    ? Styles.currentStep
                    : Styles.unVisited
                }`}
              >
                {step_completed.includes(default_service) && showSuccess}
              </div>
              <div className="px-3">
                <p
                  className={`mb-0 ${
                    step_completed.includes(default_service)
                      ? Styles.stepCompletedText
                      : next_step === default_service
                      ? Styles.activeStepText
                      : Styles.stepText
                  }`}
                >
                  Choose products to start with
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={Styles.logo}>
        <img src={logo} alt="logo" height="35px" />
      </div>
    </div>
  );
}

export default OnBoardingSideBar;
