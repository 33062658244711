// Hooks
import React, { useEffect, useState } from "react";

// Components
import Loading from "../../../../Shared/loading/loading";
import ProgressCard from "./progressCard";

// styles
import styles from "./trackTask.module.scss";

// Constants
import { ACCENTCOLOR } from "../../../../Shared/colors";

// Functions
import { callApi } from "../../../../api/fetch";

interface Props {
  taskList?: Array<any>;
  getTaskData: () => void;
  activePageNumber: any;
  postPerPage: any;
}

const TrackTask = (props: Props) => {
  const [taskList, setTaskList] = useState<Array<any>>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const indexOfLastAccount = props.activePageNumber * props.postPerPage;
  const indexOfFirstAccount = indexOfLastAccount - props.postPerPage;

  useEffect(() => {
    setTaskList(props?.taskList?.length ? props?.taskList : []);
    setLoading(false);
  }, [props]);

  const executeAction = async (batchId: string, type: string) => {
    let endpoint = "";
    switch (type) {
      case "STOP_TASK":
        endpoint = "stopBatch/" + batchId;
        break;
      default:
        break;
    }
    if (batchId && endpoint) {
      try {
        await callApi(`/productBatchService/executeAction`, {
          method: "POST",
          data: {
            action: endpoint,
          },
        });

        // eslint-disable-next-line
        props.getTaskData && props?.getTaskData();
      } catch (error) {
        // eslint-disable-next-line
        props.getTaskData && props?.getTaskData();
      }
    }
  };

  return (
    <div style={{ overflowX: "auto" }}>
      {loading ? (
        <div
          style={{ height: "50vh" }}
          className="d-flex align-items-center justify-content-center"
        >
          <Loading loadingColor={ACCENTCOLOR} />
        </div>
      ) : (
        <>
          <div className={styles.batchdetailsHeading}>Batch Details</div>
          {taskList.length ? (
            <table className={"w-100 " + styles.progressCardTable}>
              {taskList
                .slice(indexOfFirstAccount, indexOfLastAccount)
                .map((item, index) => {
                  const btndisable = !(
                    item.downloadInitiated &&
                    item?.batchState === "FINISHED" &&
                    item?.downloadUrl
                  );
                  return (
                    <ProgressCard
                      key={index}
                      item={item}
                      btndisable={btndisable}
                      loading={loading}
                      executeAction={executeAction}
                    />
                  );
                })}
            </table>
          ) : (
            <div
              style={{ height: "50vh" }}
              className="d-flex align-items-center justify-content-center"
            >
              No task found
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default TrackTask;
