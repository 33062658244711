import React from "react";

// Styles
import Styles from "./emptyState.module.scss";

// Constants
import emptyStateNoContent from "../../assets/images/emptyStateNoContent.png";
import emptyStateLinkExpiry from "../../assets/images/emptyStateLinkExpiry.png";
import emptyState404 from "../../assets/images/emptyState404.png";
import {
  pageNotFound404,
  noContent,
  linkBroken,
} from "../../constants/emptyStatesVariables";

function EmptyState({ type = noContent, ...props }: any) {
  return (
    <div
      className={`d-flex align-items-center justify-content-center ${Styles.wrapper}`}
    >
      <div>
        <div className="py-3 text-center">
          {type === noContent ? (
            <img
              src={emptyStateNoContent}
              alt="empty-state-no-content"
              width="300"
            />
          ) : type === pageNotFound404 ? (
            <img src={emptyState404} alt="empty-state-404" width="300" />
          ) : type === linkBroken ? (
            <img
              src={emptyStateLinkExpiry}
              alt="empty-state-link-expiry"
              width="300"
            />
          ) : null}
        </div>
        {props.children}
      </div>
    </div>
  );
}

export default EmptyState;
