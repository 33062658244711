import React from "react";

// Styles
import { ACCENTCOLOR } from "../colors";
import Styles from "./pagination.module.scss";

// Constants
import ArrowGroup from "../svg/arrowGroup";

function Pagination({
  minPageNumberLimit,
  maxPageNumberLimit,
  setMinPageNumberLimit,
  setMaxPageNumberLimit,
  pageNumberLimit,
  activePageNumber,
  total,
  postPerPage,
  navigateToPage,
}: any) {
  const pages = [];
  let totalNumberOfPages = Math.ceil(total / postPerPage);
  totalNumberOfPages = totalNumberOfPages <= 100 ? totalNumberOfPages : 100;
  for (let i = 1; i <= totalNumberOfPages; i++) {
    pages.push(i);
  }
  function gotToPreviousPage() {
    navigateToPage(activePageNumber - 1);
  }
  function goToNextPage() {
    navigateToPage(activePageNumber + 1);
  }
  return (
    <div className="d-inline-block">
      <div className="d-flex align-items-center">
        {activePageNumber > 1 && (
          <div
            id="pagination-goto-page-1"
            className={Styles.rotateLeft}
            onClick={() => {
              setMaxPageNumberLimit(pageNumberLimit);
              setMinPageNumberLimit(1);
              navigateToPage(1);
            }}
          >
            <div className="d-flex flex-column">
              <ArrowGroup color={ACCENTCOLOR}></ArrowGroup>
              {/* <ArrowDown color={ACCENTCOLOR} />
              <ArrowDown color={ACCENTCOLOR} /> */}
            </div>
          </div>
        )}
        {activePageNumber > 1 && (
          <div
            className={Styles.rotateLeft}
            id="pagination-goto-previous-page"
            onClick={() => {
              if (activePageNumber - 1 < minPageNumberLimit) {
                setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
                setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
              }
              gotToPreviousPage();
            }}
          >
            <ArrowGroup color={ACCENTCOLOR} single={true} />
          </div>
        )}
        <div className="d-flex align-items-center px-2">
          {pages.map((pageNumber, index: number) => {
            return pageNumber > minPageNumberLimit - 1 &&
              pageNumber < maxPageNumberLimit + 1 ? (
              <div className="px-2" key={`id${index}`}>
                <div
                  id={`pagination-goto-page-${pageNumber}`}
                  onClick={() => navigateToPage(pageNumber)}
                  className={`d-flex justify-content-center align-items-center ${
                    activePageNumber === pageNumber
                      ? Styles.pageNumberActive + ""
                      : Styles.pageNumberInActive
                  }`}
                >
                  <p
                    className={`m-0 ${Styles.pageNumberText} ${
                      activePageNumber === pageNumber ? "white-color" : ""
                    }`}
                  >
                    {pageNumber}
                  </p>
                </div>
              </div>
            ) : null;
          })}
        </div>
        {activePageNumber !== pages.length && pages.length > 0 && (
          <div
            className={Styles.rotateRight}
            id="pagination-goto-next-page"
            onClick={() => {
              if (activePageNumber + 1 > maxPageNumberLimit) {
                setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
                setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
              }
              goToNextPage();
            }}
          >
            {/* <ArrowDown color={ACCENTCOLOR} /> */}
            <ArrowGroup color={ACCENTCOLOR} single={true}></ArrowGroup>
          </div>
        )}
        {activePageNumber !== pages.length && pages.length > 0 && (
          <div
            className={Styles.rotateRight}
            id="pagination-goto-last-page"
            onClick={() => {
              setMaxPageNumberLimit(totalNumberOfPages);
              setMinPageNumberLimit(totalNumberOfPages - pageNumberLimit + 1);
              navigateToPage(totalNumberOfPages);
            }}
          >
            <div className="d-flex flex-column">
              <ArrowGroup color={ACCENTCOLOR}></ArrowGroup>

              {/* <ArrowDown color={ACCENTCOLOR} />
              <ArrowDown color={ACCENTCOLOR} /> */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Pagination;
