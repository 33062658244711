import React from "react";
import Styles from "./forgotPassword.module.scss";
import AuthSidebar from "../authSidebar/authSidebar";
import Button from "../../../Shared/button/button";
import Input from "../../../Shared/input/input";
import Illustration from "../../../assets/images/forgot_password_illustration.svg";
import { useHistory } from "react-router-dom";
import ErrorMessage from "../../../Shared/error-message/errorMessage";
import {
  checkIsEmpty,
  validEmail,
  isvalidEmailFirstChar,
} from "../../../utils/validation";
import {
  primaryButtonStyle,
  primaryButtonHoverStyle,
} from "../../../Shared/buttonStyles";
import { publicEmail } from "../../../constants/publicEmail";
import InformativeText from "../informativeText/informativeText";
import { LARGE } from "../../../Shared/buttonSize";
import { callApi } from "../../../api/fetch";
import Toast from "../../../Shared/toast/toast";
import { useTitle } from "../../../Shared/useTitle";
function ForgotPassword() {
  const history = useHistory();
  const [email, setEmail] = React.useState(localStorage.getItem("email") || "");
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const [isValidEmail, setIsValidEmail] = React.useState(email ? true : false);
  const [toast, setToast] = React.useState({
    message: "",
    type: "",
    show: false,
  });

  function isvalidEmail() {
    if (checkIsEmpty(email)) {
      setIsValidEmail(false);
      setError("Email cannot be empty");
      return false;
    }
    if (!validEmail(email)) {
      setIsValidEmail(false);
      setError("Enter a valid email");
      return false;
    }
    if (isvalidEmailFirstChar(email)) {
      setIsValidEmail(false);
      setError("Email cannot start with a number");
      return false;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, domain] = email.split("@");
    if (publicEmail.includes(domain.toLocaleLowerCase())) {
      setIsValidEmail(false);
      setError("Only work emails are allowed");
      return false;
    }
    return true;
  }
  function toastTimeout(
    showToastValue: boolean,
    toastType: string,
    toastMessage: string
  ) {
    setToast((toast: any) => ({
      ...toast,
      type: toastType,
      message: toastMessage,
      show: showToastValue,
    }));
    setTimeout(() => {
      setToast((toast: any) => ({
        ...toast,
        show: !showToastValue,
      }));
    }, 8000);
  }
  async function handelForgotPassword(event: any) {
    event.preventDefault();
    const emailCheck = isvalidEmail();
    if (!emailCheck) {
      return;
    }
    setLoading(true);
    try {
      await callApi("/user/password-reset", {
        method: "POST",
        data: {
          email: email,
          success_redirect: `${window.location.origin}/create-password`,
          failure_redirect: `${window.location.origin}/password-reset-link-expiry`,
        },
      });
      setLoading(false);
      toastTimeout(true, "success", `We have sent you an email on ${email}`);
    } catch (err) {
      setLoading(false);
      toastTimeout(true, "error", (err as Error).message);
    }
    return;
  }
  useTitle("Zoop Customer Platform | Forgot Password");
  return (
    <div className="container-fluid p-0">
      {toast.show && (
        <Toast
          message={toast.message}
          type={toast.type}
          click={() =>
            setToast((toast: any) => ({
              ...toast,
              show: false,
            }))
          }
        />
      )}
      <div className="row no-gutters">
        <div className="col-xl-5 d-none d-xl-block">
          <AuthSidebar
            img={<img src={Illustration} alt="illustration" width="250px" />}
            info={
              <InformativeText
                headertext="Forgot Your Password ?"
                bodytext=" Don’t worry resetting your password is very easy. We just require your email to send you the reset password
              link."
              />
            }
          />
        </div>
        <div className="col-xl-7 col-lg-12">
          <div
            className={`d-flex align-items-center justify-content-center ${Styles.authActionsBackground}`}
          >
            <div className="d-flex justify-content-center">
              <div className={Styles.formWidth}>
                <form>
                  <div className="form-group mb-0">
                    <Input
                      type="email"
                      name="email"
                      id="email-auth"
                      autoComplete="off"
                      placeholder="Your Email"
                      labelname="Email"
                      isValid={isValidEmail}
                      value={email}
                      hasError={error}
                      onChange={(event: any) => {
                        setEmail(event.target.value);
                        setIsValidEmail(true);
                        setError("");
                      }}
                      onBlur={isvalidEmail}
                      clear={false}
                    />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </div>
                  <div className="py-3">
                    <Button
                      id="reset-password"
                      hoveredStyle={primaryButtonHoverStyle}
                      type="submit"
                      style={primaryButtonStyle}
                      disabled={loading}
                      isLoading={loading}
                      size={LARGE}
                      onClick={handelForgotPassword}
                    >
                      Reset Password
                    </Button>
                  </div>
                  <hr />
                  <div className="pt-3">
                    <p className={`${Styles.text} mb-0`}>
                      Already know your password
                    </p>
                    <div className="d-flex justify-content-center">
                      <p
                        id="sign-in-page"
                        className={`${Styles.signUpText} mb-0`}
                        onClick={() => {
                          history.push("/login");
                        }}
                      >
                        Sign In
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
