// Main component handeling display of all the plans and details

// Hooks and Utilities
import React, { Fragment, useEffect, useCallback } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

// Styles
import Styles from "./allPlans.module.scss";
import {
  SECONDARYCOLOR,
  PRIMARYCOLOR,
  ACCENTCOLOR,
  WHITE,
} from "../../../../Shared/colors";
import {
  primaryButtonHoverStyle,
  primaryButtonStyle,
} from "../../../../Shared/buttonStyles";

// Components
import ArrowUp from "../../../../Shared/svg/arrowUp";
import PlanCard from "./plan-card/planCard";
import FilterTabs from "../../product-catalog/filter-tabs/filterTabs";
import BankDetailsPopUp from "../bank-detail-pop-up/bankDetailsPopUp";
import EmailSentPopUp from "../email-sent-pop-up/emailSentPopUp";
import ContactSalesRequestPopUp from "../contact-sales-request-pop-up/contactSalesRequestPopUp";
import Loading from "../../../../Shared/loading/loading";
import DowngradeWarningPopUp from "../downgrade-warning-pop-up/downgradeWarningPopUp";
import EnableLaterConfirmationPopUp from "../enable-later-confirmation-pop-up/enableLaterConfirmationPopUp";
import Toast from "../../../../Shared/toast/toast";
import KycDetailsPopUp from "../kyc-details-pop-up/kycDetailsPopUp";
import Button from "../../../../Shared/button/button";
import RequestDiscardedPopUp from "../request-discarded-pop-up/requestDiscardedPopUp";

// Constants
import { ALL } from "../../../../constants/filters";
import RupeeSVG from "../../../../Shared/svg/rupeeSVG";
import { PlanSubscriptionStatus } from "../../../../constants/planSubscriptionStatus";
import ArrowDown from "../../../../Shared/svg/arrowDown";
import { LARGE } from "../../../../Shared/buttonSize";
import { requestTypes } from "../../../../constants/requestTypes";

// Functions
import { callApi } from "../../../../api/fetch";
import {
  numberWithCommas,
  roundOfDecimal,
} from "../../../../utils/updateAmpunt";

function AllPlans() {
  const {
    state: { productDetails },
  }: any = useLocation();
  const { id }: any = useParams();
  const history = useHistory();
  const planTypes = {
    PREPAID: "PREPAID",
    POSTPAID: "POSTPAID",
  };
  const organisationId = localStorage.getItem("organisation_id");
  const [selectedProductType, setSelectedProductType] = React.useState(
    productDetails ? productDetails.category : ALL
  );
  const [toggleBankDetailsPopUp, setToggleBankDetailsPopUp] = React.useState(
    false
  );
  const [toggleEmailSentPopUp, setToggleEmailSentPopUp] = React.useState(false);
  const [bankDetails, setBankDetails] = React.useState({
    bankName: "",
    accountName: "",
    accountNumber: "",
    IFSC: "",
    amount: "",
    amountLabel: "",
    paymentMode: "",
    noteText: "",
    buttonText: "",
  });
  const [requestInitiated, setRequestInitiated] = React.useState(false);
  const [
    toggleContactSalesRequest,
    setToggleContactSalesPopUp,
  ] = React.useState(false);
  const [planData, setPlanData] = React.useState<any>([]);
  const [allPlans, setAllPlans] = React.useState<any>([]);
  const [rateCard, setRateCard] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [toast, setToast] = React.useState({
    message: "",
    type: "",
    show: false,
  });
  const [currentPlanId, setCurrentPlanId] = React.useState<string>("");
  const [customPlan, setCustomPlan] = React.useState<any>();
  const [activeKey, setActiveKey] = React.useState("");
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const [downgradeWarningPopUp, setDowngradeWarningPopUp] = React.useState<any>(
    {
      show: false,
      selectedPlan: {},
    }
  );
  const [toggleEnableLaterPopUp, setToggleEnableLaterPopUp] = React.useState(
    false
  );
  const [virtualAccount, setVirtualAccount] = React.useState();
  const [
    requestCustomPlanLoading,
    setRequestCustomPlanLoading,
  ] = React.useState(false);
  const [
    downgradePlanLaterLoading,
    setDowngradePlanLaterLoading,
  ] = React.useState(false);
  const [
    requestPlanForInternationalCustomer,
    setRequestPlanForInternationalCustomer,
  ] = React.useState(false);
  const [isPaymentReceived, setIsPaymentReceived] = React.useState(false);
  const [toggleKycDetailsPopUp, setToggleKycDetailsPopUp] = React.useState(
    false
  );
  const [orgDetails, setOrgDetails] = React.useState<any>();
  const [
    accountActivationRequestLoading,
    setAccountActivationRequestLoading,
  ] = React.useState(false);
  const [
    toggleRequestDiscardedPopUp,
    setToggleRequestDiscardedPopUp,
  ] = React.useState<any>({
    show: false,
    internationalCustomer: false,
    previouslySelectedPlan: {},
    currentSelectedPlan: {},
  });
  const [productCategories, setProductCategories] = React.useState(["All"]);
  const highlightRef = React.useRef<any>(null);
  const requestStatus = {
    PENDING: "PENDING",
    FULFILLED: "FULFILLED",
  };

  const getOrgDetails = useCallback(async () => {
    try {
      const data = await callApi(
        `/organisation/${localStorage.getItem(
          "organisation_id"
        )}?action=get_org_details`,
        {
          method: "GET",
        }
      );
      setOrgDetails(data);
    } catch (err) {
      toastTimeout(true, "error", (err as Error).message);
    }
  }, []);

  const getPlans = useCallback(async () => {
    try {
      const plans = await callApi("/plan", {
        method: "GET",
      });
      setAllPlans(plans);
      const defaultPlans: any = plans.filter((plan: any) => {
        if (plan.is_custom_plan && plan.subscription_status === "ACTIVE") {
          setCustomPlan(plan);
        }
        return !plan.is_custom_plan;
      });
      setPlanData(defaultPlans);
      plans.map((plan: any) => {
        return (
          plan.subscription_status ===
            PlanSubscriptionStatus.PAYMENT_RECEIVED &&
          setIsPaymentReceived(true)
        );
      });
      const products = await callApi("/product", {
        method: "GET",
      });
      const categories: any = Array.from(
        new Set(products.map((product: any) => product.category))
      );
      setProductCategories(productCategories.concat(categories));
      const rateCard = products.map((product: any) => ({
        name: product.name,
        category: product.category,
        description: product.short_description,
        additional_info: product.additional_info,
        lite: getPricing(
          plans,
          product,
          plans.findIndex((plan: any) => plan.plan_name === "Lite")
        ),
        plus: getPricing(
          plans,
          product,
          plans.findIndex((plan: any) => plan.plan_name === "Plus")
        ),
        advance: getPricing(
          plans,
          product,
          plans.findIndex((plan: any) => plan.plan_name === "Advance")
        ),
        premium: getPricing(
          plans,
          product,
          plans.findIndex((plan: any) => plan.plan_name === "Premium")
        ),
        custom: getPricing(
          plans,
          product,
          plans.findIndex((plan: any) => plan.is_custom_plan)
        ),
      }));
      setRateCard(rateCard);
      setLoading(false);

      if (highlightRef) {
        highlightRef?.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    } catch (err) {
      toastTimeout(true, "error", (err as Error).message);
      setLoading(false);
    }

    function getPricing(plans: any, product: any, condition: any) {
      // To check whether product.arn exist or not
      if (plans[condition]?.pricing[product.arn]) {
        // To check that whether the arn is an object or not
        // if an object is there then we take the unit price of the product
        if (Object.keys(plans[condition]?.pricing[product.arn]).length > 0) {
          return plans[condition]?.pricing[product.arn]?.unit_price;
        }
        // if not then we take the product price
        return plans[condition]?.pricing[product.arn];
      }
      return;
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setLoading(true);
    getOrgDetails();
  }, [getOrgDetails]);

  useEffect(() => {
    setLoading(true);
    getPlans();
  }, [getPlans]);

  useEffect(() => {
    async function getCustomPlanRequest() {
      try {
        const res = await callApi("/requests", {
          method: "GET",
        });
        const isRequestPending = res.find(
          (request: any) =>
            request.status === requestStatus.PENDING &&
            request.request_type === requestTypes.CUSTOM_PLAN_REQUEST
        );
        if (isRequestPending) setRequestInitiated(true);
      } catch (err) {
        toastTimeout(true, "error", (err as Error).message);
      }
    }
    getCustomPlanRequest();
    // eslint-disable-next-line
  }, []);

  function toggleTab(value: string) {
    if (selectedProductType === value) {
      return setSelectedProductType("");
    }
    return setSelectedProductType(value);
  }

  function showBankDetailsPopUp(account: any, amount: number) {
    setToggleBankDetailsPopUp(true);
    setBankDetails((bankDetails: any) => ({
      ...bankDetails,
      accountName: account.name,
      bankName: account.bank_name,
      accountNumber: account.account_number,
      IFSC: account.ifsc,
      paymentMode: "NEFT/IMPS",
      noteText:
        "The amount mentioned above excludes applicable taxes. Total payable amount along with bank details will be shared over your registered email address once request is confirmed. Kindly ensure exact amount is paid while making the transaction.",
      amount: `${numberWithCommas(roundOfDecimal(amount))} (Excl. taxes)`,
      amountLabel: "Amount",
      buttonText: "Confirm Request",
    }));
    return;
  }

  async function _handlePlanConfirm() {
    try {
      setButtonLoading(true);
      await callApi(
        `/organisation/${organisationId}?action=org_plan_subscribe`,
        {
          method: "PUT",
          data: {
            planId: currentPlanId,
          },
        }
      );
      getPlans();
      setButtonLoading(false);
      setToggleBankDetailsPopUp(false);
      setToggleEmailSentPopUp(true);
    } catch (err) {
      toastTimeout(true, "error", (err as Error).message);
      setButtonLoading(false);
      setToggleBankDetailsPopUp(false);
    }
  }

  function toastTimeout(
    showToastValue: boolean,
    toastType: string,
    toastMessage: string
  ) {
    setToast((toast: any) => ({
      ...toast,
      type: toastType,
      message: toastMessage,
      show: showToastValue,
    }));
    setTimeout(() => {
      setToast((toast: any) => ({
        ...toast,
        show: !showToastValue,
      }));
    }, 8000);
    return;
  }

  async function enablePlanLater() {
    setDowngradePlanLaterLoading(true);
    try {
      await callApi(
        `/organisation/${organisationId}?action=org_plan_subscribe`,
        {
          method: "PUT",
          data: {
            planId: currentPlanId,
            enable_immediately: false,
          },
        }
      );
      getPlans();
      setDowngradePlanLaterLoading(false);
      setDowngradeWarningPopUp((downgradeWarningPopUp: any) => ({
        ...downgradeWarningPopUp,
        show: false,
      }));
      setToggleEnableLaterPopUp(true);
    } catch (err) {
      setDowngradePlanLaterLoading(false);
      setDowngradeWarningPopUp((downgradeWarningPopUp: any) => ({
        ...downgradeWarningPopUp,
        show: false,
      }));
      toastTimeout(true, "error", (err as Error).message);
    }
  }

  async function requestCustomPlan() {
    setRequestCustomPlanLoading(true);
    try {
      await callApi("/requests", {
        method: "POST",
        data: {
          request_type: requestTypes.CUSTOM_PLAN_REQUEST,
        },
      });
      setRequestCustomPlanLoading(false);
      setToggleContactSalesPopUp(false);
      setRequestInitiated(true);
    } catch (err) {
      setRequestCustomPlanLoading(false);
      setToggleContactSalesPopUp(false);
      toastTimeout(true, "error", (err as Error).message);
    }
  }

  async function requestAccountActivation() {
    setAccountActivationRequestLoading(true);
    try {
      await callApi("/events?action=account_activation_request", {
        method: "POST",
      });
      setAccountActivationRequestLoading(false);
      setToggleKycDetailsPopUp(false);
      getOrgDetails();
    } catch (err) {
      setAccountActivationRequestLoading(false);
      setToggleKycDetailsPopUp(false);
      toastTimeout(true, "error", (err as Error).message);
    }
  }

  async function handlePlanSubscriptionForInternationalCustomer() {
    setRequestCustomPlanLoading(true);
    try {
      await callApi(
        `/organisation/${organisationId}?action=org_plan_subscribe`,
        {
          method: "PUT",
          data: {
            planId: currentPlanId,
          },
        }
      );
      getPlans();
      setRequestCustomPlanLoading(false);
      setRequestPlanForInternationalCustomer(false);
      toastTimeout(true, "success", "Your request has been registered");
    } catch (err) {
      setRequestCustomPlanLoading(false);
      toastTimeout(true, "error", (err as Error).message);
    }
  }

  function checkAccountActivationForInternationalCustomer() {
    if (orgDetails?.onboarding_status === "ONBOARDING_COMPLETED") {
      setToggleKycDetailsPopUp(true);
      return false;
    }
    if (orgDetails?.onboarding_status === "ACTIVATION_REQUESTED") {
      toastTimeout(
        true,
        "error",
        "Complete your account activation process to proceed ahead."
      );
      return false;
    }
    return true;
  }

  function checkDowngradePlan(plan: any, currentPlan: any) {
    if (plan.prepaid_recharge_amount < currentPlan?.prepaid_recharge_amount) {
      setDowngradeWarningPopUp((downgradeWarningPopUp: any) => ({
        ...downgradeWarningPopUp,
        show: true,
        selectedPlan: plan,
      }));
      setCurrentPlanId(plan._id);
      return true;
    }
    return false;
  }

  function checkDiscardPlanRequest(
    plan: any,
    pendingPlan: any,
    isInternationalCustomer: boolean
  ) {
    if (pendingPlan) {
      setCurrentPlanId(plan._id);
      setToggleRequestDiscardedPopUp((toggleRequestDiscardedPopUp: any) => ({
        ...toggleRequestDiscardedPopUp,
        show: true,
        currentSelectedPlan: plan,
        previouslySelectedPlan: pendingPlan,
        internationalCustomer: isInternationalCustomer,
      }));
      return true;
    }
    return false;
  }

  function checkRenewalOfPlan(plan: any) {
    if (plan.subscription_status === PlanSubscriptionStatus.EXPIRED) {
      const [currentPlan] = allPlans.filter((p: any) => {
        return p.subscription_status === PlanSubscriptionStatus.ACTIVE;
      });
      const [planPaymentReceived] = allPlans.filter((p: any) => {
        return (
          p.subscription_status === PlanSubscriptionStatus.PAYMENT_RECEIVED
        );
      });
      if (!currentPlan && !planPaymentReceived) {
        return true;
      }
      return false;
    }
    return false;
  }

  return (
    <div>
      {toast.show && (
        <Toast
          message={toast.message}
          type={toast.type}
          click={() =>
            setToast((toast: any) => ({
              ...toast,
              show: false,
            }))
          }
        />
      )}
      <div className="p-3">
        <div className="d-inline-flex py-2">
          <div
            className="d-flex align-items-center"
            id="all-plans-back"
            onClick={() => history.push(`/application/${id}`)}
            style={{ cursor: "pointer" }}
          >
            <div className={`px-1 ${Styles.rotate}`}>
              <ArrowUp color={SECONDARYCOLOR} />
            </div>
            <p className={`mb-0 ${Styles.backText}`}>back</p>
          </div>
        </div>
        {loading ? (
          <div
            style={{
              height: "80vh",
            }}
            className="d-flex align-items-center justify-content-center"
          >
            <Loading loadingColor={ACCENTCOLOR} />
          </div>
        ) : (
          <>
            <div className="py-2">
              <div
                className="d-flex align-items-center justify-content-start"
                style={{ overflowX: "auto", overflowY: "hidden" }}
              >
                {planData.map(
                  (plan: {
                    _id: string;
                    plan_name: any;
                    prepaid_recharge_amount: any;
                    plan_validity: any;
                    subscription_status: string;
                  }) => (
                    <div className="p-2" key={plan._id}>
                      <PlanCard
                        country_code={orgDetails?.country_code}
                        org_subscribed_plan_type={
                          orgDetails?.activePlan?.plan_type
                        }
                        planId={plan._id}
                        planname={plan.plan_name}
                        isPaymentReceived={isPaymentReceived}
                        paymentReceived={
                          plan.subscription_status ===
                          PlanSubscriptionStatus.PAYMENT_RECEIVED
                        }
                        pendingPayment={
                          plan.subscription_status ===
                          PlanSubscriptionStatus.PAYMENT_PENDING
                        }
                        renewPlan={checkRenewalOfPlan(plan)}
                        currentPlan={
                          plan.subscription_status ===
                          PlanSubscriptionStatus.ACTIVE
                        }
                        amount={numberWithCommas(
                          roundOfDecimal(plan.prepaid_recharge_amount)
                        )}
                        validity={
                          plan.plan_validity || plan.plan_validity === 0
                            ? `${plan.plan_validity} months`
                            : "NA"
                        }
                        onRequestPlan={(account: any) => {
                          if (account?.international_customer) {
                            if (
                              checkAccountActivationForInternationalCustomer()
                            ) {
                              setCurrentPlanId(plan._id);
                              const [pendingPlan] = planData.filter(
                                (p: any) => {
                                  return (
                                    p.subscription_status ===
                                    PlanSubscriptionStatus.PAYMENT_PENDING
                                  );
                                }
                              );
                              if (
                                checkDiscardPlanRequest(plan, pendingPlan, true)
                              ) {
                                return;
                              }
                              setRequestPlanForInternationalCustomer(true);
                            }
                            return;
                          }
                          setVirtualAccount(account);
                          const [currentPlan] = planData.filter((p: any) => {
                            return (
                              p.subscription_status ===
                              PlanSubscriptionStatus.ACTIVE
                            );
                          });
                          const [pendingPlan] = planData.filter((p: any) => {
                            return (
                              p.subscription_status ===
                              PlanSubscriptionStatus.PAYMENT_PENDING
                            );
                          });
                          if (
                            checkDiscardPlanRequest(plan, pendingPlan, false)
                          ) {
                            return;
                          }
                          if (checkDowngradePlan(plan, currentPlan)) {
                            return;
                          }
                          showBankDetailsPopUp(
                            account,
                            plan.prepaid_recharge_amount
                          );
                          setCurrentPlanId(plan._id);
                        }}
                      />
                    </div>
                  )
                )}
                <div className="p-2">
                  <div className={Styles.customPlanCard}>
                    <div
                      className={`p-3 d-flex align-items-center ${Styles.cardHeader}`}
                    >
                      <p className={`mb-0 px-2 ${Styles.planname}`}>Custom</p>
                      {customPlan?.plan_type === planTypes.POSTPAID && (
                        <div className="ml-auto">
                          <div className={Styles.post_paid_plan_bubble}>
                            <p className={Styles.post_paid_plan_text}>
                              Postpaid
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="p-3">
                      {customPlan ? (
                        <div className="p-2">
                          <p className={`mb-0 ${Styles.label}`}>
                            Amount{" "}
                            <span className={`mb-0 ${Styles.label} px-1`}>
                              (Excl. taxes)
                            </span>
                          </p>
                          <div className="d-flex align-items-center">
                            <div className="pr-1">
                              <div style={{ width: "100%", height: "100%" }}>
                                <RupeeSVG
                                  color={PRIMARYCOLOR}
                                  width="7"
                                  height="14"
                                />
                              </div>
                            </div>
                            <div className="pr-1">
                              <p className={`mb-0 ${Styles.value}`}>
                                {customPlan?.prepaid_recharge_amount ||
                                customPlan?.prepaid_recharge_amount === 0
                                  ? numberWithCommas(
                                      roundOfDecimal(
                                        customPlan.prepaid_recharge_amount
                                      )
                                    )
                                  : "NA"}
                              </p>
                            </div>
                          </div>
                          <div className="py-2">
                            <p className={`mb-0 ${Styles.label}`}>Validity</p>
                            <p className={`mb-0 ${Styles.value}`}>
                              {customPlan?.plan_validity ||
                              customPlan?.plan_validity === 0
                                ? `${customPlan.plan_validity} months`
                                : "NA"}
                            </p>
                          </div>
                          <div className="py-3 d-flex align-items-center justify-content-center">
                            <div
                              className="d-flex align-items-center justify-content-center"
                              style={{ cursor: "pointer" }}
                            >
                              <div className="pl-1">
                                {/* use the below p to show if they have contacted us or not. */}
                                {customPlan?.subscription_status ===
                                "ACTIVE" ? (
                                  <p className={`mb-0 ${Styles.currentPlan}`}>
                                    Current Plan
                                  </p>
                                ) : customPlan?.subscription_status ===
                                  "PAYMENT_PENDING" ? (
                                  <p
                                    className={`mb-0 ${Styles.requestInitiated}`}
                                  >
                                    Payment Pending
                                  </p>
                                ) : requestInitiated ? (
                                  <p
                                    className={`mb-0 ${Styles.requestInitiated}`}
                                  >
                                    Request Initiated
                                  </p>
                                ) : (
                                  <p
                                    className={`mb-0 ${Styles.choosePlan}`}
                                    id="all-plans-contact1"
                                    onClick={() => {
                                      setToggleContactSalesPopUp(true);
                                    }}
                                  >
                                    Contact Sales
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="py-3">
                          <p
                            className={`mb-0 ${Styles.customPlanText}`}
                            style={{ height: "98px" }}
                          >
                            A custom business proposal, tailored for big players
                            and those having high volume transactions.
                          </p>
                          <div className="py-2 d-flex align-items-center justify-content-center">
                            <div
                              className="d-flex align-items-center justify-content-center"
                              style={{ cursor: "pointer" }}
                            >
                              <div className="pl-1">
                                {/* use the below p to show if they have contacted us or not. */}
                                {requestInitiated ? (
                                  <p
                                    className={`mb-0 ${Styles.requestInitiated}`}
                                  >
                                    Request Initiated
                                  </p>
                                ) : (
                                  <p
                                    className={`mb-0 ${Styles.choosePlan}`}
                                    id="all-plans-contact2"
                                    onClick={() => {
                                      setToggleContactSalesPopUp(true);
                                    }}
                                  >
                                    Contact Sales
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-2">
              <div
                className="py-2 d-flex align-items-center justify-content-start"
                style={{ width: "100%", overflowY: "scroll" }}
              >
                {productCategories.map((category: any) => {
                  return (
                    <div key={category} className="p-2">
                      <FilterTabs
                        filteredString={selectedProductType}
                        category={category}
                        click={(value: any) => toggleTab(value)}
                        hideCross={category === ALL}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="p-2">
              <div
                className={`p-3 d-flex align-items-center ${Styles.cardHeader}`}
              >
                <p className={`mb-0 px-2 ${Styles.planname}`}>
                  Compare based on category
                </p>
              </div>
              <div className={`${Styles.compareBillingCard}`}>
                <table>
                  <thead>
                    <tr className={Styles.tableHeaderWrapper}>
                      <th
                        className={`${Styles.tableHeader} text-center`}
                        style={{ width: "5%" }}
                      >
                        #
                      </th>
                      <th
                        className={Styles.tableHeader}
                        style={{ width: "25%" }}
                      >
                        Product Name
                      </th>
                      <th
                        className={Styles.tableHeader}
                        style={{ width: "30%" }}
                      >
                        Description
                      </th>
                      {customPlan && (
                        <th
                          className={Styles.tableHeader}
                          style={{ width: "8%" }}
                        >
                          <div className="d-flex align-items-center justify-content-center">
                            <div
                              className="pl-1"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Custom
                            </div>
                            <div className="pl-1">
                              <RupeeSVG
                                color={SECONDARYCOLOR}
                                width="7"
                                height="10"
                              />
                            </div>
                          </div>
                        </th>
                      )}
                      <th
                        className={Styles.tableHeader}
                        style={{ width: "8%" }}
                      >
                        <div className="d-flex align-items-center justify-content-center">
                          <div className="pl-1">Lite</div>
                          <div className="pl-1">
                            <RupeeSVG
                              color={SECONDARYCOLOR}
                              width="7"
                              height="10"
                            />
                          </div>
                        </div>
                      </th>
                      <th
                        className={Styles.tableHeader}
                        style={{ width: "8%" }}
                      >
                        <div className="d-flex align-items-center justify-content-center">
                          <div className="pl-1">Plus</div>
                          <div className="pl-1">
                            <RupeeSVG
                              color={SECONDARYCOLOR}
                              width="7"
                              height="10"
                            />
                          </div>
                        </div>
                      </th>
                      <th
                        className={Styles.tableHeader}
                        style={{ width: "8%" }}
                      >
                        <div className="d-flex align-items-center justify-content-center">
                          <div className="pl-1">Advance</div>
                          <div className="pl-1">
                            <RupeeSVG
                              color={SECONDARYCOLOR}
                              width="7"
                              height="10"
                            />
                          </div>
                        </div>
                      </th>
                      <th
                        className={Styles.tableHeader}
                        style={{ width: "8%" }}
                      >
                        <div className="d-flex align-items-center justify-content-center">
                          <div className="pl-1">Premium</div>
                          <div className="pl-1">
                            <RupeeSVG
                              color={SECONDARYCOLOR}
                              width="7"
                              height="10"
                            />
                          </div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {rateCard
                      .filter((product: any) =>
                        selectedProductType === ALL || !selectedProductType
                          ? product
                          : product.category.includes(selectedProductType)
                      )
                      .map((product: any) => {
                        return (
                          <Fragment key={`${product.name}`}>
                            <tr
                              className={`${
                                productDetails.name === product.name
                                  ? Styles.highlight
                                  : activeKey === product.name
                                  ? Styles.shadow
                                  : ""
                              }`}
                              ref={
                                productDetails.name === product.name
                                  ? highlightRef
                                  : null
                              }
                            >
                              <td
                                className={Styles.tableBody}
                                style={{ width: "5%" }}
                              >
                                {product.additional_info !== "" ? (
                                  <div
                                    className={`${Styles.expandWrapper} d-flex align-items-center justify-content-center`}
                                    style={
                                      activeKey === product.name
                                        ? { backgroundColor: ACCENTCOLOR }
                                        : {}
                                    }
                                    id="all-plans-additional-info"
                                    onClick={() => {
                                      if (activeKey === product.name) {
                                        setActiveKey("");
                                        return;
                                      }
                                      setActiveKey(product.name);
                                    }}
                                  >
                                    {activeKey === product.name ? (
                                      <ArrowDown color={WHITE} />
                                    ) : (
                                      <div
                                        className={
                                          Styles.rotateArrowRightLongDescription
                                        }
                                      >
                                        <ArrowDown />
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <div
                                    className={`${Styles.nonActiveExpandWrapper} d-flex align-items-center justify-content-center`}
                                    style={{
                                      cursor: "no-drop",
                                      opacity: "0.4",
                                    }}
                                  >
                                    {activeKey === product.name ? (
                                      <ArrowDown color={WHITE} />
                                    ) : (
                                      <div
                                        className={
                                          Styles.rotateArrowRightLongDescription
                                        }
                                      >
                                        <ArrowDown />
                                      </div>
                                    )}
                                  </div>
                                )}
                              </td>
                              <td
                                className={Styles.tableBody}
                                style={{ width: "25%" }}
                              >
                                {product.name}
                              </td>
                              <td
                                className={Styles.tableBody}
                                style={{ width: "30%" }}
                              >
                                {product.description}
                              </td>
                              {customPlan && (
                                <td
                                  className={Styles.tableBody}
                                  style={{ width: "8%", textAlign: "center" }}
                                >
                                  {product.custom}
                                </td>
                              )}
                              <td
                                className={Styles.tableBody}
                                style={{ width: "8%", textAlign: "center" }}
                              >
                                {product.lite}
                              </td>
                              <td
                                className={Styles.tableBody}
                                style={{ width: "8%", textAlign: "center" }}
                              >
                                {product.plus}
                              </td>
                              <td
                                className={Styles.tableBody}
                                style={{ width: "8%", textAlign: "center" }}
                              >
                                {product.advance}
                              </td>
                              <td
                                className={Styles.tableBody}
                                style={{ width: "8%", textAlign: "center" }}
                              >
                                {product.premium}
                              </td>
                            </tr>
                            {activeKey === product.name ? (
                              <tr>
                                <td
                                  colSpan={8}
                                  className={Styles.longDescription}
                                >
                                  {product.additional_info}
                                </td>
                              </tr>
                            ) : null}
                          </Fragment>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
      </div>

      {toggleBankDetailsPopUp ? (
        <BankDetailsPopUp bankDetails={bankDetails}>
          <div className="pt-3 text-center" style={{ margin: "0 auto" }}>
            <Button
              id="z-billing-btn-plan-confirm"
              hoveredStyle={primaryButtonHoverStyle}
              style={primaryButtonStyle}
              size={LARGE}
              isLoading={buttonLoading}
              onClick={() => {
                _handlePlanConfirm();
              }}
            >
              {bankDetails.buttonText}
            </Button>
            <div className="py-3 d-flex justify-content-center">
              <p
                id="all-plans-cancel"
                className={`mb-0 ${Styles.cancelText} d-inline-flex`}
                onClick={() => setToggleBankDetailsPopUp(false)}
              >
                Cancel
              </p>
            </div>
          </div>
        </BankDetailsPopUp>
      ) : null}
      {toggleEmailSentPopUp ? (
        <EmailSentPopUp onConfirm={() => setToggleEmailSentPopUp(false)} />
      ) : null}
      {toggleContactSalesRequest ? (
        <ContactSalesRequestPopUp
          isLoading={requestCustomPlanLoading}
          onConfirm={() => {
            requestCustomPlan();
          }}
          onCancel={() => setToggleContactSalesPopUp(false)}
        />
      ) : null}
      {requestPlanForInternationalCustomer ? (
        <ContactSalesRequestPopUp
          isLoading={requestCustomPlanLoading}
          onConfirm={() => handlePlanSubscriptionForInternationalCustomer()}
          onCancel={() => setRequestPlanForInternationalCustomer(false)}
        />
      ) : null}
      {downgradeWarningPopUp.show ? (
        <DowngradeWarningPopUp
          isLoading={downgradePlanLaterLoading}
          planSelected={downgradeWarningPopUp.selectedPlan.plan_name}
          onCancel={() =>
            setDowngradeWarningPopUp((downgradeWarningPopUp: any) => ({
              ...downgradeWarningPopUp,
              show: false,
            }))
          }
          onEnableNow={() => {
            setDowngradeWarningPopUp((downgradeWarningPopUp: any) => ({
              ...downgradeWarningPopUp,
              show: false,
            }));
            showBankDetailsPopUp(
              virtualAccount,
              downgradeWarningPopUp.selectedPlan.prepaid_recharge_amount
            );
          }}
          onEnableLater={() => enablePlanLater()}
        />
      ) : null}
      {toggleEnableLaterPopUp ? (
        <EnableLaterConfirmationPopUp
          onConfirm={() => {
            setToggleEnableLaterPopUp(false);
          }}
          onCancel={() => setToggleEnableLaterPopUp(false)}
        />
      ) : null}
      {toggleKycDetailsPopUp ? (
        <KycDetailsPopUp
          isLoading={accountActivationRequestLoading}
          customerType={
            orgDetails?.country_code === "IN" ? "national" : "international"
          }
          onCancel={() => setToggleKycDetailsPopUp(false)}
          onSendOverEmail={() => {
            requestAccountActivation();
          }}
        />
      ) : null}
      {toggleRequestDiscardedPopUp.show ? (
        <RequestDiscardedPopUp
          planSelected={toggleRequestDiscardedPopUp.currentSelectedPlan}
          previouslyRequested={
            toggleRequestDiscardedPopUp.previouslySelectedPlan
          }
          onUpdateRequest={() => {
            setToggleRequestDiscardedPopUp(
              (toggleRequestDiscardedPopUp: any) => ({
                ...toggleRequestDiscardedPopUp,
                show: false,
              })
            );
            if (toggleRequestDiscardedPopUp.internationalCustomer) {
              setRequestPlanForInternationalCustomer(true);
              return;
            }
            showBankDetailsPopUp(
              virtualAccount,
              toggleRequestDiscardedPopUp.currentSelectedPlan
                .prepaid_recharge_amount
            );
          }}
          onCancel={() =>
            setToggleRequestDiscardedPopUp(
              (toggleRequestDiscardedPopUp: any) => ({
                ...toggleRequestDiscardedPopUp,
                show: false,
              })
            )
          }
        />
      ) : null}
    </div>
  );
}

export default AllPlans;
