import React from "react";

// Constants
import businessDataImage from "../../../../assets/images/business-data.svg";
import InsightSVG from "../../../../Shared/svg/insightSVG";

// Styles
import Styles from "../dashboard.module.scss";

function InsightsCard({
  currentTranxnVolume = 0,
  prevTranxnVolume = 0,
  productName,
}: any) {
  const temp_state = +currentTranxnVolume >= +prevTranxnVolume;
  const ratio =
    Math.abs(currentTranxnVolume - prevTranxnVolume) / prevTranxnVolume;
  const percentage = parseInt((ratio * 100).toString());

  return (
    <div
      className={`${Styles.insightCard} pt-3 pr-3 pl-3 d-flex flex-column justify-content-between`}
    >
      <div>
        <div className="d-flex align-items-start">
          <InsightSVG />
          <h6 className="ml-2 mt-1 mb-3">Insight</h6>
        </div>
        <div>
          <h3
            style={
              isNaN(percentage)
                ? { color: "#0075f3" }
                : temp_state
                ? { color: "#008056" }
                : { color: "#C80000" }
            }
          >
            {isNaN(percentage)
              ? "No Usage Found"
              : `${temp_state ? "+" : "-"}${percentage}` + "%"}
          </h3>
        </div>
        <div>
          <p>
            {isNaN(percentage)
              ? "No Information was found for this duration!"
              : `${
                  temp_state ? "increase" : "decrease"
                } in your usage of ${productName} compared to the previous period.`}
          </p>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <img src={businessDataImage} width={"200px"} />
      </div>
    </div>
  );
}

export default InsightsCard;
