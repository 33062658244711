import React from "react";

// Style
import Styles from "./approvalBox.module.scss";

function ApprovalBox({ pendingServices }: any) {
  return (
    <div className={Styles.demoInfo}>
      <h6>Note</h6>
      <p>
        {" "}
        {pendingServices.length}{" "}
        {pendingServices.length > 1 ? "products are" : "product is"} awaiting
        approval for trial
      </p>
      <ul>
        {pendingServices.map((service: any, index: number) => (
          <li key={index}>{service.name}</li>
        ))}
      </ul>
      <p>Please note that the admin is working towards the approval for you.</p>
      <p>Thank you for your patience!</p>
    </div>
  );
}

export default ApprovalBox;
