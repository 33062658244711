import React from "react";

// Styles
import Styles from "../../../../Shared/popup.module.scss";

// Components
import Button from "../../../../Shared/button/button";

// Styles
import {
  dangerButtonStyle,
  cancelButtonStyle,
  cancelButtonHoverStyle,
  dangerButtonHoverStyle,
} from "../../../../Shared/buttonStyles";
import { DANGER } from "../../../../Shared/colors";

// Functions
import { callApi } from "../../../../api/fetch";
import { monthName } from "../../../../utils/getMonth";

// Constants
import { MEDIUM } from "../../../../Shared/buttonSize";

function DeleteApiKey({ onSubmit, onCancel, appId, keyName }: any) {
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  function handleCancel(event: any) {
    event.preventDefault();
    onCancel();
    return;
  }

  async function handleDeleteKey() {
    if (loading) return;

    try {
      setLoading(true);
      const res = await callApi(`/app/${appId}?action=delete_app_key`, {
        method: "PUT",
        data: {
          keyName,
        },
      });

      setLoading(false);
      const updatedKeys = res.keys.map((key: any) => {
        const createdDate = new Date(Number(key.createdAt));
        const updatedDate = new Date(Number(key.updatedAt));
        return {
          ...key,
          createdAt: `${createdDate.getDate()}-${monthName(
            createdDate.getMonth()
          )}-${createdDate.getFullYear()}`,
          updatedAt: `${updatedDate.getDate()}-${monthName(
            updatedDate.getMonth()
          )}-${updatedDate.getFullYear()}`,
        };
      });
      onSubmit(updatedKeys);
    } catch (err) {
      setLoading(false);
      setError((err as Error).message);
    }
  }
  return (
    <div
      className={`d-flex align-items-center justify-content-center ${Styles.overlay}`}
    >
      <div className={`p-4 ${Styles.popUpContent}`}>
        <div className="py-3">
          <p className={`mb-0 ${Styles.warningMessage} text-center`}>
            Are you sure you want to delete this key{" "}
          </p>
        </div>
        <div className="d-flex align-items-center py-3 justify-content-around">
          <div className="px-2">
            <Button
              hoveredStyle={cancelButtonHoverStyle}
              style={cancelButtonStyle}
              id="delete-api-key-cancel"
              onClick={handleCancel}
              size={MEDIUM}
            >
              Cancel
            </Button>
          </div>
          <div className="px-2">
            <Button
              hoveredStyle={dangerButtonHoverStyle}
              style={dangerButtonStyle}
              id="delete-api-key-delete"
              onClick={handleDeleteKey}
              isLoading={loading}
              size={MEDIUM}
            >
              Delete
            </Button>
          </div>
        </div>
        {error && (
          <div className="py-2">
            <p
              className="mb-0 text-center"
              style={{ color: DANGER, fontSize: "12px" }}
            >
              {error}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default DeleteApiKey;
