import React from "react";
// Components
import Loading from "../loading/loading";

// Styles
import Styles from "./button.module.scss";

// Constants
import { COMPRESSED, LARGE, MEDIUM } from "../buttonSize";

function Button({
  id,
  isLoading,
  disabled,
  style,
  hoveredStyle,
  size,
  btnTextClass,
  className,
  ...props
}: any) {
  const [buttonStyle, setButtonStyle] = React.useState(style);
  return (
    <button
      id={id}
      className={Styles.btn + " " + className || ""}
      style={
        size === COMPRESSED
          ? { ...buttonStyle, minWidth: "110px", width: "100%", height: "30px" }
          : {
              ...buttonStyle,
              width:
                size === LARGE ? "300px" : size === MEDIUM ? "150px" : "100%",
            }
      }
      disabled={disabled}
      onMouseOver={!disabled ? () => setButtonStyle(hoveredStyle) : false}
      onMouseOut={() => setButtonStyle(style)}
      {...props}
    >
      {isLoading ? (
        <Loading
          loadingColor={style.buttonTextColor ?? style.buttonTextColor}
          width={size === LARGE ? "12px" : size === COMPRESSED ? "8px" : "10px"}
          height={
            size === LARGE ? "12px" : size === COMPRESSED ? "8px" : "10px"
          }
        />
      ) : (
        <p
          className={`${Styles.btnText + " " + (btnTextClass || "")} mb-0`}
          style={{
            color: buttonStyle.buttonTextColor,
            fontSize: size === COMPRESSED ? "12px" : "14px",
          }}
        >
          {props.children}
        </p>
      )}
    </button>
  );
}

export default Button;
