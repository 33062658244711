import React from "react";
import ErrorMessage from "../../../../../Shared/error-message/errorMessage";
import Styles from "../../Shared/sharedStyles.module.scss";
import Button from "../../../../../Shared/button/button";
import {
  primaryButtonHoverStylePopup,
  primaryButtonStylePopup,
} from "../../../../../Shared/buttonStyles";
import { MEDIUM } from "../../../../../Shared/buttonSize";

function FaceLivenessPopup({ handleResponse, name }: any) {
  const errorObject = {
    consentError: false,
  };

  const [error, setError] = React.useState({ ...errorObject });
  const [isChecked, setIsChecked] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  function handleCheck() {
    const consent = isChecked === true ? "Y" : "N";

    if (!isChecked) {
      setError(() => ({
        ...error,
        consentError: true,
      }));
      return false;
    }
    setLoading(true);
    handleResponse({ consent }, setLoading);
  }
  return (
    <div className={`${Styles.content}`}>
      <div className={`${Styles.trialText} pl-2`}>Runnning Trial on</div>
      <div className={`${Styles.headingText} pl-2`}>{name} </div>
      <div className={`${Styles.infoForm} pl-2`}>
        <hr />
        <div className="pl-4 pb-4 mt-4">
          <input
            type="checkbox"
            className="form-check-input"
            onChange={(e: any) => {
              setIsChecked(e.target.checked);
            }}
          />
          <p className={`${Styles.ackText}`}>
            I hearby agree, to let zoop.one verify my data for verification
          </p>
          {error.consentError && (
            <ErrorMessage>Please provide consent!</ErrorMessage>
          )}
        </div>
        <div className={`${Styles.buttonGroup}`}>
          <Button
            id="id-submit-button-face-liveness"
            hoveredStyle={primaryButtonHoverStylePopup}
            disabled={loading}
            size={MEDIUM}
            isLoading={loading}
            style={primaryButtonStylePopup}
            onClick={() => {
              setTimeout(() => setError({ ...errorObject }), 2000);
              handleCheck();
            }}
          >
            Run Verification
          </Button>
        </div>
      </div>
    </div>
  );
}

export default FaceLivenessPopup;
