// Child component of businessTechPOC
import React from "react";
import Styles from "./pocForm.module.scss";
import Input from "../../../Shared/input/input";
import Button from "../../../Shared/button/button";
import {
  checkIsEmpty,
  validEmail,
  validPhoneNumber,
  isValidName,
  isvalidEmailFirstChar,
  isNumeric,
} from "../../../utils/validation";
import ErrorMessage from "../../../Shared/error-message/errorMessage";
import {
  borderButtonStyle,
  borderButtonHoverStyle,
} from "../../../Shared/buttonStyles";
import { publicEmail } from "../../../constants/publicEmail";
import { MEDIUM } from "../../../Shared/buttonSize";
import { useTitle } from "../../../Shared/useTitle";

function PocForm({ click, hasError, orgCountryCode }: any) {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [designation, setDesignation] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [error, setError] = React.useState({
    nameError: "",
    emailError: "",
    designationError: "",
    phoneNumberError: "",
  });
  function checkName() {
    if (checkIsEmpty(name)) {
      setError((error) => ({
        ...error,
        nameError: "Name cannot be Empty",
      }));
      hasError();
      return false;
    }
    if (isValidName(name, false)) {
      setError((error) => ({
        ...error,
        nameError: "should contain alphabets only!",
      }));
      hasError();
      return false;
    }
    // Razorpay validation: 3 - 50 characters
    if (name.length < 3) {
      setError((error) => ({
        ...error,
        nameError: "must be at least 3 characters long",
      }));
      hasError();
      return false;
    }
    return true;
  }
  function checkEmail() {
    if (checkIsEmpty(email)) {
      setError((error) => ({
        ...error,
        emailError: "Email cannot be empty",
      }));
      hasError();
      return false;
    }
    if (!validEmail(email)) {
      setError((error) => ({
        ...error,
        emailError: "Please enter a valid email",
      }));
      hasError();
      return false;
    }
    if (isvalidEmailFirstChar(email)) {
      setError((error) => ({
        ...error,
        emailError: "Email Cannot start with a number",
      }));
      hasError();
      return false;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, domain] = email.split("@");
    if (publicEmail.includes(domain.toLocaleLowerCase())) {
      setError((error) => ({
        ...error,
        emailError: "Only work emails are allowed",
      }));
      hasError();
      return false;
    }

    return true;
  }
  function checkDesignation() {
    if (checkIsEmpty(designation)) {
      setError((error) => ({
        ...error,
        designationError: "Designation cannot be empty",
      }));
      hasError();
      return false;
    }
    if (isValidName(designation, false)) {
      setError((error) => ({
        ...error,
        designationError: "should contain alphabets only!",
      }));
      hasError();
      return false;
    }
    return true;
  }
  // TODO: check if all the if conditions are needed or not
  function checkPhoneNumber() {
    if (orgCountryCode !== "IN") {
      if (!isNumeric(phoneNumber) && phoneNumber !== "") {
        setError((error) => ({
          ...error,
          phoneNumberError: "Please enter a valid phone number",
        }));
        hasError();
        return false;
      }
      return true;
    }
    if (checkIsEmpty(phoneNumber)) {
      setError((error) => ({
        ...error,
        phoneNumberError: "Phone number cannot be empty",
      }));
      hasError();
      return false;
    }
    if (!validPhoneNumber(phoneNumber)) {
      setError((error) => ({
        ...error,
        phoneNumberError: "Please enter a valid phone number",
      }));
      hasError();
      return false;
    }
    if (phoneNumber[0] === "0") {
      setError((error) => ({
        ...error,
        phoneNumberError: "Exclude 0 from the beginning",
      }));
      hasError();
      return false;
    }
    if (phoneNumber.length > 10) {
      setError((error) => ({
        ...error,
        phoneNumberError: "Phone Number should be 10 digits",
      }));
      hasError();
      return false;
    }
    return true;
  }
  function handelFormSubmit(event: any) {
    event.preventDefault();
    const allCheckPassed = [
      checkName(),
      checkEmail(),
      checkDesignation(),
      checkPhoneNumber(),
    ].every(Boolean);
    if (!allCheckPassed) {
      return;
    }
    click({
      name,
      email,
      designation,
      phoneNumber,
    });
    return;
  }
  useTitle("Zoop Customer Platform | POC Details");
  return (
    <div className={Styles.formBG}>
      <form>
        <div>
          <Input
            type="text"
            name="name"
            id="name"
            maxLength={50} // Razorpay validation: 3 - 50 characters
            value={name}
            autoComplete="off"
            hasError={error.nameError}
            placeholder={"Enter Name"}
            labelname="Name"
            onChange={(event: any) => {
              setName(event.target.value);
              setError((error) => ({
                ...error,
                nameError: "",
              }));
            }}
            noshadow="true"
            onBlur={checkName}
          />
          {error.nameError && <ErrorMessage>{error.nameError}</ErrorMessage>}
        </div>
        <div>
          <Input
            type="email"
            name="email"
            id="email"
            maxLength={64} // Razorpay validation: max 64 characters
            value={email}
            autoComplete="off"
            hasError={error.emailError}
            placeholder={"Enter Email"}
            labelname="Email"
            onChange={(event: any) => {
              setEmail(event.target.value);
              setError((error) => ({
                ...error,
                emailError: "",
              }));
            }}
            noshadow="true"
            onBlur={checkEmail}
          />
          {error.emailError && <ErrorMessage>{error.emailError}</ErrorMessage>}
        </div>
        <div>
          <Input
            type="text"
            name="designation"
            id="designation"
            maxLength={50} // Razorpay validation: max 50 characters
            value={designation}
            autoComplete="off"
            hasError={error.designationError}
            placeholder={"Enter Designation"}
            labelname="Designation"
            onChange={(event: any) => {
              setDesignation(event.target.value);
              setError((error) => ({
                ...error,
                designationError: "",
              }));
            }}
            noshadow="true"
            onBlur={checkDesignation}
          />
          {error.designationError && (
            <ErrorMessage>{error.designationError}</ErrorMessage>
          )}
        </div>
        <div>
          <Input
            type="text"
            name="phoneNumber"
            id="phoneNumber"
            maxLength={10}
            value={phoneNumber}
            autoComplete="off"
            hasError={error.phoneNumberError}
            placeholder={"Enter Phone Number"}
            labelname="Phone Number"
            onChange={(event: any) => {
              setPhoneNumber(event.target.value);
              setError((error) => ({
                ...error,
                phoneNumberError: "",
              }));
            }}
            noshadow="true"
            onBlur={checkPhoneNumber}
          />
          {error.phoneNumberError && (
            <ErrorMessage>{error.phoneNumberError}</ErrorMessage>
          )}
        </div>
        <div className="py-3 d-flex align-items-center justify-content-center">
          <div className="px-2" style={{ margin: "0 auto" }}>
            <Button
              id="poc-form"
              size={MEDIUM}
              disabled={
                name === "" ||
                error.nameError !== "" ||
                email === "" ||
                error.emailError !== "" ||
                designation === "" ||
                error.designationError !== "" ||
                (phoneNumber === "" && orgCountryCode === "IN") ||
                error.phoneNumberError !== "" ||
                (!isNumeric(phoneNumber) && phoneNumber !== "")
              }
              hoveredStyle={borderButtonHoverStyle}
              style={borderButtonStyle}
              onClick={handelFormSubmit}
            >
              Submit
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default PocForm;
