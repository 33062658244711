//First step of organisation creation before businessTech POC Information.
//  Adding name and type of business

// Hooks
import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTitle } from "../../../Shared/useTitle";

// Functions
import validator from "validator";
import { isValidName } from "../../../utils/validation";
import { callApi } from "../../../api/fetch";
import { onboardingSidebarContext } from "../../../utils/onboardingSidebarContext";

// Styles
import "flag-icons/css/flag-icons.min.css";
import Styles from "./createOrganization.module.scss";
import {
  primaryButtonStyle,
  primaryButtonHoverStyle,
} from "../../../Shared/buttonStyles";
import { SECONDARYCOLOR, WHITE } from "../../../Shared/colors";
import dropdownStyles from "../../../Shared/input-dropdown/inputDropdown.module.scss";

// Components
import Button from "../../../Shared/button/button";
import Input from "../../../Shared/input/input";
import ErrorMessage from "../../../Shared/error-message/errorMessage";
import InputDropdown from "../../../Shared/input-dropdown/inputDropdown";
import BusinessTechPOC from "../business-tech-poc/businessTechPOC";

// Constants
import {
  business_info,
  poc_info,
} from "../../../constants/onboardingConstants";
import ArrowDown from "../../../Shared/svg/arrowDown";
import { LARGE } from "../../../Shared/buttonSize";
import CrossSvg from "../../../Shared/svg/cross";
import TickSvg from "../../../Shared/svg/tickSvg";

function CreateOrganization() {
  const history = useHistory();
  window.addEventListener("popstate", () => {
    history.go(1);
  });
  const [togglePocInfoPage, setTogglePocInfoPage] = React.useState(false);
  const [orgNameError, setOrgNameError] = React.useState("");
  const [orgTradeNameError, setOrgTradeNameError] = React.useState("");
  const [orgCategoryError, setOrgCategoryError] = React.useState("");
  const [orgName, setOrgName] = React.useState("");
  const [orgTradeName, setOrgTradeName] = React.useState("");
  const [orgCategory, setOrgCategory] = React.useState("");
  const [orgCountryCode, setOrgCountryCode] = React.useState({
    code: "",
    name: "",
  });
  const [countryCodeError, setCountryCodeError] = React.useState("");
  const sidebarContext = useContext(onboardingSidebarContext);
  const [validOrgData, setValidOrgData] = React.useState({
    orgName: false,
    tradeName: false,
    orgCategory: false,
    countryCode: false,
  });
  const [categories, setCategories] = React.useState<string[]>([]);
  const [countryCodes, setCountryCodes] = React.useState([]);
  const [searchCountryCode, setSearchCountryCode] = React.useState("");
  const [
    toggleTradeNameAsBusinessNameCheckBox,
    setToggleTradeNameAsBusinessNameCheckBox,
  ] = React.useState(false);

  useEffect(() => {
    callApi("/organisation/categories", {
      method: "GET",
    })
      .then((response) => {
        const categoryName = response.map((response: any) => {
          const { sub_category, _id } = response;
          return { label: sub_category, value: _id };
        });

        setCategories(Array.from(new Set(categoryName)));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    localStorage.removeItem("resend_link_counter");
    callApi("/country", {
      method: "GET",
    })
      .then((response) => {
        let countryCodeArray = response.map((country: any) => ({
          name: country.name,
          code: country.code,
          id: country._id,
        }));
        // to make IN - India first element in an array.
        const countryNameAsIndia = countryCodeArray.find(
          (country: any) => country.name === "India"
        );
        countryCodeArray = countryCodeArray.filter(
          (country: any) => country.id !== countryNameAsIndia.id
        );
        countryCodeArray.unshift(countryNameAsIndia);
        setCountryCodes(countryCodeArray);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function checkOrgName() {
    if (validator.isEmpty(orgName, { ignore_whitespace: true })) {
      setValidOrgData((validOrgData) => ({
        ...validOrgData,
        orgName: false,
      }));
      setOrgNameError("Organization name cannot be empty");
      return false;
    }
    if (isValidName(orgName, false)) {
      setValidOrgData((validOrgData) => ({
        ...validOrgData,
        orgName: false,
      }));
      setOrgNameError("should contain alphabets only!");
      return false;
    }
    if (validator.isNumeric(orgName[0], { no_symbols: true })) {
      setValidOrgData((validOrgData) => ({
        ...validOrgData,
        orgName: false,
      }));
      setOrgNameError("Organization name Should not start with a number");
      return false;
    }
    // Razorpay validation: 3 - 50 characters
    if (orgName.length < 3) {
      setValidOrgData((validOrgData) => ({
        ...validOrgData,
        orgName: false,
      }));
      setOrgNameError("must be at least 3 characters long");
      return false;
    }
    return true;
  }

  function checkOrgTradeName() {
    if (toggleTradeNameAsBusinessNameCheckBox) return true;
    if (validator.isEmpty(orgTradeName, { ignore_whitespace: true })) {
      setValidOrgData((validOrgData) => ({
        ...validOrgData,
        tradeName: false,
      }));
      setOrgTradeNameError("Trade name cannot be empty");
      return false;
    }
    if (isValidName(orgTradeName, false)) {
      setValidOrgData((validOrgData) => ({
        ...validOrgData,
        tradeName: false,
      }));
      setOrgTradeNameError("should contain alphabets only!");
      return false;
    }
    if (validator.isNumeric(orgTradeName[0], { no_symbols: true })) {
      setValidOrgData((validOrgData) => ({
        ...validOrgData,
        tradeName: false,
      }));
      setOrgTradeNameError("Trade name Should not start with a number");
      return false;
    }
    // Razorpay validation: 3 - 50 characters
    if (orgTradeName.length < 3) {
      setValidOrgData((validOrgData) => ({
        ...validOrgData,
        tradeName: false,
      }));
      setOrgTradeNameError("must be at least 3 characters long");
      return false;
    }
    return true;
  }

  function checkOrgCategory() {
    if (!orgCategory) {
      setValidOrgData((validOrgData) => ({
        ...validOrgData,
        orgCategory: false,
      }));
      setOrgCategoryError("Please select one category");
      return false;
    }
    return true;
  }

  function checkCountryCode() {
    if (!orgCountryCode.code && !orgCountryCode.name) {
      setValidOrgData((validOrgData) => ({
        ...validOrgData,
        countryCode: false,
      }));
      setCountryCodeError("Please select A country code");
      return false;
    }
    return true;
  }

  function handelCreateOrganization(event: any) {
    event.preventDefault();
    const validOrgName = checkOrgName();
    const validTradeName = checkOrgTradeName();
    const validOrgCategory = checkOrgCategory();
    const validCountryCode = checkCountryCode();
    if (
      validOrgName &&
      validTradeName &&
      validOrgCategory &&
      validCountryCode
    ) {
      sidebarContext.setStepCompleted([
        ...sidebarContext.step_completed,
        business_info,
      ]);
      sidebarContext.setNextStep(poc_info);
      sidebarContext.setCreateOrgProgress(50);
      setTogglePocInfoPage(true);
    }
    return;
  }

  useTitle("Zoop Customer Platform | Create Organisation");
  return (
    <React.Fragment>
      {togglePocInfoPage ? (
        <BusinessTechPOC
          orgName={orgName.trim()}
          orgCategory={orgCategory}
          orgCountryCode={orgCountryCode.code}
          orgTradeName={
            toggleTradeNameAsBusinessNameCheckBox
              ? orgName.trim()
              : orgTradeName.trim()
          }
          categories={categories}
        />
      ) : (
        <div className={`${Styles.authActionsBackground}`}>
          <div
            style={{ height: "100%" }}
            className="d-flex align-items-center justify-content-center"
          >
            <div>
              <div className="py-5">
                <p className={`${Styles.headingText} mb-0`}>
                  Create your Organisation
                </p>
              </div>
              <div className={Styles.formWidth}>
                <div className="py-1">
                  <Input
                    type="text"
                    name="orgName"
                    id="orgName-org-create"
                    value={orgName}
                    autoComplete="off"
                    maxLength={50} // Razorpay validation: 3 - 50 characters
                    hasError={orgNameError}
                    placeholder="Enter Business Legal Name"
                    labelname="Business Legal Name"
                    isValid={validOrgData.orgName}
                    onChange={(event: any) => {
                      const value = event.target.value;
                      setOrgName(value);
                      setValidOrgData((validOrgData) => ({
                        ...validOrgData,
                        orgName: true,
                      }));
                      setOrgNameError("");
                      if (toggleTradeNameAsBusinessNameCheckBox) {
                        setOrgTradeNameError("");
                      }
                    }}
                    onBlur={checkOrgName}
                    clear={false}
                  />
                  {orgNameError && <ErrorMessage>{orgNameError}</ErrorMessage>}
                </div>
                <div className="py-1">
                  <Input
                    type="text"
                    name="tradeName"
                    id="tradeName-org-create"
                    value={
                      toggleTradeNameAsBusinessNameCheckBox
                        ? orgName
                        : orgTradeName
                    }
                    autoComplete="off"
                    maxLength={50} // Razorpay validation: 3 - 50 characters
                    hasError={orgTradeNameError}
                    placeholder="Enter Trade Name"
                    labelname="Business Trade Name"
                    isValid={validOrgData.tradeName}
                    onChange={(event: any) => {
                      const value = event.target.value;
                      setOrgTradeName(value);
                      setValidOrgData((validOrgData) => ({
                        ...validOrgData,
                        tradeName: true,
                      }));
                      setOrgTradeNameError("");
                    }}
                    onBlur={checkOrgTradeName}
                    clear={false}
                  />
                  {orgTradeNameError && (
                    <ErrorMessage>{orgTradeNameError}</ErrorMessage>
                  )}
                </div>
                <div
                  id="set-trade-name-to-bussiness-name"
                  className="py-1 px-2 d-flex align-items-center"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setToggleTradeNameAsBusinessNameCheckBox(
                      !toggleTradeNameAsBusinessNameCheckBox
                    );
                    setOrgTradeNameError("");
                  }}
                >
                  <div className="px-1">
                    <div
                      className={
                        toggleTradeNameAsBusinessNameCheckBox
                          ? Styles.checkedCheckBox
                          : Styles.uncheckedCheckBox
                      }
                    >
                      {toggleTradeNameAsBusinessNameCheckBox && (
                        <TickSvg width="10" height="10" color={WHITE} />
                      )}
                    </div>
                  </div>
                  <div className="px-1">
                    <p className={`mb-0 ${Styles.sameAsBusinessNameText}`}>
                      Same as Business Legal Name
                    </p>
                  </div>
                </div>
                <div className={!orgCategoryError ? "py-2" : ""}>
                  <InputDropdown
                    labelname="Business Category"
                    hasError={orgCategoryError}
                    isValid={validOrgData.orgCategory}
                    optionsArray={categories.sort()}
                    default_value={orgCategory}
                    click={(value: any) => {
                      setValidOrgData((validOrgData) => ({
                        ...validOrgData,
                        orgCategory: true,
                      }));
                      setOrgCategory(() => value);
                      setOrgCategoryError("");
                    }}
                    resetSelection={() => {
                      setValidOrgData((validOrgData) => ({
                        ...validOrgData,
                        orgCategory: false,
                      }));
                      setOrgCategory("");
                    }}
                  />
                  {orgCategoryError && (
                    <ErrorMessage>{orgCategoryError}</ErrorMessage>
                  )}
                </div>
                <div className={!countryCodeError ? "py-2" : ""}>
                  <div>
                    <p className={`${dropdownStyles.formLabel} m-0 pb-2 `}>
                      Country
                    </p>
                    <div className="dropdown" style={{ position: "relative" }}>
                      <div
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <div
                          className={`${
                            countryCodeError !== ""
                              ? dropdownStyles.dropdownError
                              : validOrgData.countryCode
                              ? dropdownStyles.validDropdown
                              : dropdownStyles.dropdown
                          } d-flex align-items-center`}
                        >
                          <div className="flex-grow-1">
                            <p
                              className={`mb-0 ${dropdownStyles.selectedText}`}
                            >
                              {orgCountryCode.code !== "" &&
                              orgCountryCode.name !== "" ? (
                                <span>
                                  {orgCountryCode.code} - {orgCountryCode.name}
                                </span>
                              ) : (
                                <span style={{ color: SECONDARYCOLOR }}>
                                  Select Option
                                </span>
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className={dropdownStyles.indicator}>
                        {orgCountryCode.code !== "" &&
                        orgCountryCode.name !== "" ? (
                          <div
                            id="set-org-country-code"
                            className="px-2"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setOrgCountryCode({
                                code: "",
                                name: "",
                              });
                              setValidOrgData((validOrgData) => ({
                                ...validOrgData,
                                countryCode: false,
                              }));
                            }}
                          >
                            <CrossSvg width="10" height="10" />
                          </div>
                        ) : (
                          <div className="px-2">
                            <ArrowDown color={SECONDARYCOLOR} />
                          </div>
                        )}
                      </div>
                      <div
                        style={{
                          borderRadius: "8px",
                          padding: "10px",
                          margin: "5px 0",
                          border: 0,
                          overflowX: "hidden",
                        }}
                        className={`dropdown-menu dropdown-menu-right ${dropdownStyles.dropdownMenu}`}
                        aria-labelledby="dropdownMenuButton"
                      >
                        <input
                          type="text"
                          className={Styles.formControl}
                          placeholder="Search country code here"
                          onChange={(event: any) =>
                            setSearchCountryCode(event.target.value)
                          }
                        />
                        <div
                          style={{
                            maxHeight: "185px",
                            overflowY: "auto",
                            height: "100%",
                          }}
                        >
                          {countryCodes
                            .filter((countryCode: any) => {
                              return countryCode.name
                                .toLowerCase()
                                .includes(searchCountryCode.toLowerCase());
                            })
                            .map((countryCode: any) => {
                              return (
                                <div key={`${countryCode.id}`}>
                                  <div
                                    id={`set-country-code-${countryCode.id}`}
                                    className={`${dropdownStyles.dropdownLink} d-flex align-items-center`}
                                    style={{ padding: "10px" }}
                                    onClick={() => {
                                      setOrgCountryCode((orgCountryCode) => ({
                                        ...orgCountryCode,
                                        code: countryCode.code,
                                        name: countryCode.name,
                                      }));
                                      setValidOrgData((validOrgData) => ({
                                        ...validOrgData,
                                        countryCode: true,
                                      }));
                                      setCountryCodeError("");
                                    }}
                                  >
                                    {/* <img
                                      src={`https://flagsapi.com/${countryCode.code}/flat/64.png`}
                                      className="mx-1"
                                      alt={countryCode.name}
                                      style={{ width: "20px" }}
                                    /> */}

                                    <span
                                      className={`fi fi-${countryCode?.code?.toLocaleLowerCase()} fis ${
                                        Styles.countrySelect
                                      }`}
                                    ></span>

                                    <div className="px-2">
                                      <p
                                        className="mb-0"
                                        style={{ fontSize: "12px" }}
                                      >
                                        {countryCode.code}
                                      </p>
                                    </div>
                                    <div
                                      className="px-2"
                                      style={{ width: "100%" }}
                                    >
                                      <p
                                        className="mb-0"
                                        style={{
                                          fontSize: "12px",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        {countryCode.name}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                  {countryCodeError && (
                    <ErrorMessage>{countryCodeError}</ErrorMessage>
                  )}
                </div>
                <div className="py-4">
                  <Button
                    id="next-step"
                    size={LARGE}
                    hoveredStyle={primaryButtonHoverStyle}
                    style={primaryButtonStyle}
                    onClick={handelCreateOrganization}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default CreateOrganization;
