import React from "react";
//Component rendered as soon as user wishes to choose a new plan

// styles
import Styles from "../../../../Shared/popup.module.scss";
import classes from "./bankDetailsPopUp.module.scss";
import { PRIMARYCOLOR } from "../../../../Shared/colors";
import bankDetailsClass from "./bank-details-key-value/bankDetailsKeyValue.module.scss";

// Constants
import moneyTransferIllustration from "../../../../assets/images/moneyTransferDetails.svg";
import RupeeSVG from "../../../../Shared/svg/rupeeSVG";

// components
import BankDetailsKeyValue from "./bank-details-key-value/bankDetailsKeyValue";

function BankDetailsPopUp({ bankDetails, ...props }: any) {
  return (
    <div
      className={`d-flex align-items-center justify-content-center ${Styles.overlay}`}
    >
      <div
        className={`p-4 ${Styles.popUpContent} px-5`}
        style={{ width: "500px" }}
      >
        <div className="py-2 pb-4 text-center">
          <img
            src={moneyTransferIllustration}
            alt="money-transfer"
            style={{ width: "100px" }}
          />
        </div>
        <div className="py-2 text-center mb-2">
          <p className={"mb-0 title_semibold_text"}>
            Transfer money to the account below:
          </p>
        </div>
        <div className="container-fluid px-2 mb-4">
          <div className="py-2 px-4 row no-gutters">
            <div className="col-6 py-3">
              <BankDetailsKeyValue
                label="Bank Name"
                value={bankDetails.bankName}
              />
            </div>
            <div className="col-6 py-3">
              <BankDetailsKeyValue
                label="Account Name"
                value={bankDetails.accountName}
              />
            </div>
            <div className="col-6 py-3">
              <BankDetailsKeyValue
                label="Account Number"
                value={bankDetails.accountNumber}
              />
            </div>
            <div className="col-6 py-3">
              <BankDetailsKeyValue label="IFSC Code" value={bankDetails.IFSC} />
            </div>
            <div className="col-6 py-3">
              <BankDetailsKeyValue
                label="Payment Mode"
                value={bankDetails.paymentMode}
              />
            </div>
            <div className="col-6 py-3">
              <p className={`mb-0 ${bankDetailsClass.key}`}>
                {bankDetails.amountLabel}
              </p>
              <div className="d-flex align-items-center">
                <div className="pr-1">
                  <RupeeSVG color={PRIMARYCOLOR} width="7" height="14" />
                </div>
                <div className="px-1">
                  <p className={`mb-0 ${bankDetailsClass.value}`}>
                    {bankDetails.amount}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`${classes.note}`}>
          <div className="d-flex align-items-start">
            <div className="px-1">
              <p className={`mb-0 ${classes.noteLabel}`}>Note</p>
            </div>
          </div>
          <div className="px-1">
            <p className={`mb-0 ${classes.noteText}`}>{bankDetails.noteText}</p>
          </div>
        </div>
        {props.children}
      </div>
    </div>
  );
}

export default BankDetailsPopUp;
