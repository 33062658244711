import React from "react";
import { useState } from "react";
import ErrorMessage from "../../../../../Shared/error-message/errorMessage";
import Styles from "../../Shared/sharedStyles.module.scss";
import Button from "../../../../../Shared/button/button";
import {
  primaryButtonHoverStylePopup,
  primaryButtonStylePopup,
} from "../../../../../Shared/buttonStyles";
import { MEDIUM } from "../../../../../Shared/buttonSize";
import InputField from "../../Shared/inputField/inputField";
import { validEmail } from "../../../../../utils/validation";
import inputcss from "../../Shared/inputField/inputField.module.scss";

function ESignPopup({ handleResponse, name }: any) {
  const [base64, setBase64] = useState<string>("");
  const [statex, setStatex] = useState(0);
  const [statey, setStatey] = useState(0);

  const signerNameRef = React.useRef<any>("");
  const signerEmailRef = React.useRef<any>("");
  const signerCityRef = React.useRef<any>("");

  function isValidName(name: any) {
    const pattern = /[^a-zA-Z\s]/g;
    if (pattern.test(name)) {
      return false;
    }
    if (!name.replace(/\s/g, "").length) {
      return false;
    }
    if (name.length < 2) {
      return false;
    }
    return true;
  }

  function isValidCity(cityName: any) {
    const cityRegex = /^[a-zA-Z\s]+$/;
    return cityRegex.test(cityName);
  }

  function isValid() {
    setError({ ...errorObject });
    const signer_name = signerNameRef.current.value.trim();
    const signer_email = signerEmailRef.current.value.trim();
    const signer_city = signerCityRef.current.value.trim();
    if (
      isValidName(signer_name) &&
      validEmail(signer_email) &&
      isValidCity(signer_city)
    ) {
      setError(errorObject);
      return setIsDisabled(false);
    }
    if (!isValidName(signer_name)) {
      setError((error) => ({
        ...error,
        nameError: true,
      }));
    } else if (!validEmail(signer_email)) {
      setError((error) => ({
        ...error,
        emailError: true,
      }));
    } else {
      setError((error) => ({
        ...error,
        cityError: true,
      }));
    }

    return setIsDisabled(true);
  }

  const errorObject = {
    esignError: false,
    consentError: false,
    nameError: false,
    emailError: false,
    cityError: false,
  };

  const [error, setError] = React.useState({ ...errorObject });
  const [isChecked, setIsChecked] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(true);
  const [loading, setLoading] = React.useState(false);

  function handleData() {
    if (!isChecked) {
      setError(() => ({
        ...error,
        consentError: true,
      }));
      return false;
    }

    const consent = isChecked === true ? "Y" : "N";
    setLoading(true);
    const esignRequest = {
      document: {
        data: base64,
        info: "Bad Habits",
      },
      signers: [
        {
          signer_name: signerNameRef.current.value,
          signer_email: signerEmailRef.current.value,
          signer_city: signerCityRef.current.value,
          signer_purpose: "Demo",
          sign_coordinates: [
            {
              page_num: 0,
              x_coord: statex === 0 ? 0 : statex,
              y_coord: statey === 0 ? 0 : statey,
            },
          ],
        },
      ],
      txn_expiry_min: "10080",
      response_url: "https://webhook.site/e7d48f25-1a26-4c7b-8019-da4952a3cafe",
    };
    setLoading(true);
    handleResponse({ ...esignRequest }, setLoading);
  }

  const uploadImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    if (file.type !== "application/pdf") {
      alert("Only PDF files are allowed");
      e.target.value = "";
      return;
    }

    let baseValue64 = (await convertBase64(file)) as string;

    const char = ",";
    const index = baseValue64.indexOf(char);

    if (index !== -1) {
      baseValue64 = baseValue64.substring(index + 1);
    }
    setBase64(baseValue64);
  };

  const convertBase64 = (file: Blob) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result as string);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  return (
    <div className={`${Styles.content}`}>
      <div className={`${Styles.trialText} pl-2`}>Runnning Trial on</div>
      <div className={`${Styles.headingText} pl-2`}>{name} </div>
      <div className={`${Styles.infoForm} pl-2`}>
        <hr />
        <div className="d-flex flex-column">
          <div className="d-flex flex-column mb-4 ">
            <label className={`${Styles.label} flex-grow-1`}>
              eSign (Zoop Sign)
            </label>
          </div>
          <div className="app">
            <div className="d-flex flex-column mb-4 ">
              <label className={`${Styles.label} flex-grow-1`}>Document:</label>
              <input
                className="sharedStyles_label__MGkmp flex-grow-1"
                type="file"
                name="document"
                onChange={(event) => {
                  uploadImage(event);
                }}
              />
            </div>
            <div className="d-flex flex-column mb-4 ">
              <label className={`${Styles.label} flex-grow-1`}>
                Signer Name:
              </label>
              <InputField
                id="id-input-voter"
                text="lowerCase"
                onChange={isValid}
                inputRef={signerNameRef}
                isError={error.nameError}
                purpose="forVoter"
              />
              {error.nameError && (
                <ErrorMessage>Signer Name is not valid</ErrorMessage>
              )}
            </div>

            <div className="d-flex flex-column mb-4">
              <div className="d-flex flex-row">
                <div style={{ width: "50%", marginRight: "10px" }}>
                  <label className={`${Styles.label}`}>Signer Email:</label>
                  <InputField
                    id="id-input-voter"
                    text="lowerCase"
                    onChange={isValid}
                    inputRef={signerEmailRef}
                    isError={error.emailError}
                    purpose="forVoter"
                  />
                  {error.emailError && (
                    <ErrorMessage>Signer email is not valid</ErrorMessage>
                  )}
                </div>
                <div style={{ width: "50%" }}>
                  <label className={`${Styles.label}`}>Signer City:</label>
                  <InputField
                    id="id-input-voter"
                    text="lowerCase"
                    onChange={isValid}
                    inputRef={signerCityRef}
                    isError={error.cityError}
                    purpose="forVoter"
                  />
                  {error.cityError && (
                    <ErrorMessage>City required</ErrorMessage>
                  )}
                </div>
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div style={{ width: "20%", marginRight: "10px" }}>
                <label className={`${Styles.label} flex-grow-1`}>
                  Variable X
                </label>
                <input
                  className={inputcss.inputFieldVoter}
                  type="text"
                  id="numberx"
                  value={statex}
                  onChange={(e: any) => {
                    const onlyNumbers = /^[0-9]+$/;
                    if (
                      e.target.value === "" ||
                      onlyNumbers.test(e.target.value)
                    ) {
                      const parsedValue = parseInt(e.target.value);
                      setStatex(isNaN(parsedValue) ? 0 : parsedValue);
                    }
                  }}
                  required
                />
              </div>

              <div style={{ width: "20%" }}>
                <label className={`${Styles.label} flex-grow-1`}>
                  Variable Y
                </label>
                <input
                  className={inputcss.inputFieldVoter}
                  type="text"
                  id="numbery"
                  value={statey}
                  onChange={(e: any) => {
                    const onlyNumbers = /^[0-9]+$/;
                    if (
                      e.target.value === "" ||
                      onlyNumbers.test(e.target.value)
                    ) {
                      const parsedValue = parseInt(e.target.value);
                      setStatey(isNaN(parsedValue) ? 0 : parsedValue);
                    }
                  }}
                  required
                />
              </div>
            </div>
          </div>
          <div className="pt-4 pl-4 pb-4 mt-4 d-flex flex-column">
            <div>
              <input
                type="checkbox"
                className="form-check-input"
                onChange={(e: any) => {
                  setIsChecked(e.target.checked);
                  isValid();
                }}
              />
              <p className={`${Styles.ackText}`}>
                I hearby agree, to let zoop.one verify my data for verification
              </p>
            </div>
            {error.consentError && (
              <ErrorMessage>Please provide consent!</ErrorMessage>
            )}
          </div>
          <div className={`${Styles.buttonGroup}`}>
            <Button
              id="id-submit-button-esign"
              hoveredStyle={primaryButtonHoverStylePopup}
              disabled={isDisabled}
              size={MEDIUM}
              isLoading={loading}
              style={primaryButtonStylePopup}
              onClick={() => {
                setTimeout(() => setError({ ...errorObject }), 2000);
                handleData();
              }}
            >
              Run Verification
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ESignPopup;
