// Tab component rendered as child of main screen for displaying filters applied
import React from "react";

// Styles
import Styles from "./filterTabs.module.scss";

// Constants
import CloseSvg from "../../../../Shared/svg/close";

// Filters
import { ALL } from "../../../../constants/filters";

function FilterTabs({ filteredString, click, category, hideCross }: any) {
  return (
    <div
      className={`d-flex align-items-center justify-content-center ${
        filteredString === "" && category === ALL
          ? Styles.activeTavBackground
          : filteredString === category
          ? Styles.activeTavBackground
          : Styles.filterTabBackground
      }`}
      id={`filter-tabs-${category}`}
      onClick={() => click(category)}
    >
      <div className="px-3">
        <p className={`mb-0 ${Styles.tabColor}`}>{category}</p>
      </div>
      {hideCross ? null : (
        <div className={`${Styles.showCrossMark}`}>
          <CloseSvg classes={Styles.svgColor} />
        </div>
      )}
    </div>
  );
}

export default FilterTabs;
