import React from "react";
import ErrorMessage from "../../../../../Shared/error-message/errorMessage";
import Styles from "../../Shared/sharedStyles.module.scss";
import InputField from "../../Shared/inputField/inputField";
import Button from "../../../../../Shared/button/button";
import {
  primaryButtonHoverStylePopup,
  primaryButtonStylePopup,
} from "../../../../../Shared/buttonStyles";
import { MEDIUM } from "../../../../../Shared/buttonSize";

import { checkGSTIN } from "../../../../../utils/validation";
import { checkPAN } from "../../../../../utils/validation";
import { validCinNumber } from "../../../../../utils/validation";

function MerchantServicePopup({ handleResponse, name }: any) {
  const serviceName = name.replaceAll(" ", "").toLowerCase();

  const errorObject = {
    fssaiNumberError: false,
    cinNumberError: false,
    gstNumberError: false,
    panNumberError: false,
    consentError: false,
  };
  const handleChange = (event: any) => {
    setSelectedValue(event.target.value);
  };

  const [error, setError] = React.useState({ ...errorObject });
  const [isChecked, setIsChecked] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState("PAN & CIN");

  const pan_cin_Ref = React.useRef<any>("");
  const pan_gst_cin_Ref = React.useRef<any>("");
  const pan_gst_Ref = React.useRef<any>("");
  const cin_gst_Ref = React.useRef<any>("");
  const gst_cin_Ref = React.useRef<any>("");
  const business_profile_Ref = React.useRef<any>("");

  const [reportid, setReportid] = React.useState("");

  const reportIdMap: { [key: string]: string } = {
    "PAN & CIN": "w-vUHgaJDXtPwtvdkPIUgLNBdxjK4byT",
    "PAN & GST & CIN": "w-vUHgaJDXtPwtvdkPIUgsfejgWDJNqYi",
    "PAN & GST": "w-UiUYGEfrkeyIsDMTwO0fR4r1uXk3Cr",
    "CIN & GST": "w-Lk5IwlJofI4zAvqPnp",
    "GST & CIN": "w-JjsbcknkqowfQKqieoQWEppY",
    "Business Profile": "w-TY67FusbgtEPbbdjssuwi986",
  };

  React.useEffect(() => {
    setReportid(reportIdMap[selectedValue] || "");
  }, [selectedValue]);

  function isValid() {
    if (
      pan_cin_Ref.current &&
      pan_cin_Ref.current.value &&
      checkPAN(pan_cin_Ref.current.value.trim().toUpperCase())
    ) {
      setError(errorObject);
      return setIsDisabled(false);
    }

    if (
      pan_gst_cin_Ref.current &&
      pan_gst_cin_Ref.current.value &&
      checkPAN(pan_gst_cin_Ref.current.value.trim().toUpperCase())
    ) {
      setError(errorObject);
      return setIsDisabled(false);
    }

    if (
      pan_gst_Ref.current &&
      pan_gst_Ref.current.value &&
      checkPAN(pan_gst_Ref.current.value.trim().toUpperCase())
    ) {
      setError(errorObject);
      return setIsDisabled(false);
    }

    if (
      cin_gst_Ref.current &&
      cin_gst_Ref.current.value &&
      validCinNumber(cin_gst_Ref.current.value.trim().toUpperCase())
    ) {
      setError(errorObject);
      return setIsDisabled(false);
    }

    if (
      gst_cin_Ref.current &&
      gst_cin_Ref.current.value &&
      checkGSTIN(gst_cin_Ref.current.value.trim().toUpperCase())
    ) {
      setError(errorObject);
      return setIsDisabled(false);
    }

    if (
      business_profile_Ref.current &&
      business_profile_Ref.current.value &&
      validCinNumber(business_profile_Ref.current.value.trim().toUpperCase())
    ) {
      setError(errorObject);
      return setIsDisabled(false);
    }

    setError(() => ({
      ...error,
      panNumberError: true,
      cinNumberError: true,
      gstNumberError: true,
    }));
    return setIsDisabled(true);
  }

  function handleCheck() {
    const customerPanNumberLookup: { [key: string]: string } = {
      "PAN & CIN": pan_cin_Ref.current
        ? pan_cin_Ref.current.value.toUpperCase()
        : null,
      "PAN & GST & CIN": pan_gst_cin_Ref.current
        ? pan_gst_cin_Ref.current.value.toUpperCase()
        : null,
      "PAN & GST": pan_gst_Ref.current
        ? pan_gst_Ref.current.value.toUpperCase()
        : null,
    };
    const customerPanNumber = customerPanNumberLookup[selectedValue] || null;

    const cinNumber = cin_gst_Ref.current
      ? cin_gst_Ref.current.value.toUpperCase()
      : business_profile_Ref.current
      ? business_profile_Ref.current.value.toUpperCase()
      : null;

    const businessGstinNumber = gst_cin_Ref.current
      ? gst_cin_Ref.current.value.toUpperCase()
      : null;
    const reportId = reportid;
    const consent = isChecked === true ? "Y" : "N";

    if (!isChecked) {
      setError(() => ({
        ...error,
        consentError: true,
      }));
      return false;
    }
    setLoading(true);

    if (
      selectedValue === "PAN & CIN" ||
      selectedValue === "PAN & GST & CIN" ||
      selectedValue === "PAN & GST"
    ) {
      handleResponse({ customerPanNumber, reportId, consent }, setLoading);
    } else if (
      selectedValue === "CIN & GST" ||
      selectedValue === "Business Profile"
    ) {
      handleResponse({ cinNumber, reportId, consent }, setLoading);
    } else if (selectedValue === "GST & CIN") {
      handleResponse({ businessGstinNumber, reportId, consent }, setLoading);
    }
  }
  return (
    <div className={`${Styles.content}`}>
      <div className={`${Styles.trialText} pl-2`}>Runnning Trial on</div>
      <div className={`${Styles.headingText} pl-2`}>{name} </div>
      <div className={`${Styles.infoForm} pl-2`}>
        <hr />
        <p className="mt-4">Fill in the below details to run verification</p>
        <div className="d-flex flex-column">
          <form>
            <div>
              <input
                type="radio"
                value="PAN & CIN"
                checked={selectedValue === "PAN & CIN"}
                onChange={handleChange}
              />
              <label
                style={{ marginLeft: "10px" }}
                className={`${Styles.label} flex-grow-2`}
              >
                PAN & CIN
              </label>
            </div>

            <div>
              <input
                type="radio"
                value="PAN & GST & CIN"
                checked={selectedValue === "PAN & GST & CIN"}
                onChange={handleChange}
              />
              <label
                style={{ marginLeft: "10px" }}
                className={`${Styles.label} flex-grow-1`}
              >
                PAN & GST & CIN
              </label>
            </div>
            <div>
              <input
                type="radio"
                value="PAN & GST"
                checked={selectedValue === "PAN & GST"}
                onChange={handleChange}
              />
              <label
                style={{ marginLeft: "10px" }}
                className={`${Styles.label} flex-grow-1`}
              >
                PAN & GST{" "}
              </label>
            </div>
            <div>
              <input
                type="radio"
                value="CIN & GST"
                checked={selectedValue === "CIN & GST"}
                onChange={handleChange}
              />
              <label
                style={{ marginLeft: "10px" }}
                className={`${Styles.label} flex-grow-1`}
              >
                CIN & GST{" "}
              </label>
            </div>
            <div>
              <input
                type="radio"
                value="GST & CIN"
                checked={selectedValue === "GST & CIN"}
                onChange={handleChange}
              />
              <label
                style={{ marginLeft: "10px" }}
                className={`${Styles.label} flex-grow-1`}
              >
                {" "}
                GST & CIN{" "}
              </label>
            </div>

            <div>
              <input
                type="radio"
                value="Business Profile"
                checked={selectedValue === "Business Profile"}
                onChange={handleChange}
              />
              <label
                style={{ marginLeft: "10px" }}
                className={`${Styles.label} flex-grow-1`}
              >
                Business Profile{" "}
              </label>
            </div>

            {selectedValue == "PAN & CIN" && (
              <div className="d-flex flex-column mb-4 ">
                <label className={`${Styles.label} flex-grow-1`}>
                  PAN Number
                </label>
                <InputField
                  id="id-input-pan"
                  onChange={isValid}
                  maxLength={10}
                  autocomplete="off"
                  inputRef={pan_cin_Ref}
                  isError={error.panNumberError}
                  purpose="forPan"
                />
                {error.panNumberError && (
                  <ErrorMessage>Please enter a valid Pan number</ErrorMessage>
                )}
              </div>
            )}

            {selectedValue == "PAN & GST & CIN" && (
              <div className="d-flex flex-column mb-4 ">
                <label className={`${Styles.label} flex-grow-1`}>
                  PAN Number
                </label>
                <InputField
                  id="id-input-pan"
                  onChange={isValid}
                  maxLength={10}
                  autocomplete="off"
                  inputRef={pan_gst_cin_Ref}
                  isError={error.panNumberError}
                  purpose="forPan"
                />
                {error.panNumberError && (
                  <ErrorMessage>Please enter a valid Pan number</ErrorMessage>
                )}
              </div>
            )}

            {selectedValue == "PAN & GST" && (
              <div className="d-flex flex-column mb-4 ">
                <label className={`${Styles.label} flex-grow-1`}>
                  PAN Number
                </label>
                <InputField
                  id="id-input-pan"
                  onChange={isValid}
                  maxLength={10}
                  autocomplete="off"
                  inputRef={pan_gst_Ref}
                  isError={error.panNumberError}
                  purpose="forPan"
                />
                {error.panNumberError && (
                  <ErrorMessage>Please enter a valid Pan number</ErrorMessage>
                )}
              </div>
            )}

            {selectedValue === "CIN & GST" && (
              <div className="d-flex flex-column mb-4 ">
                <label className={`${Styles.label} flex-grow-1`}>
                  CIN Number
                </label>
                <InputField
                  onChange={isValid}
                  maxLength={21}
                  inputRef={cin_gst_Ref}
                  autocomplete="off"
                  isError={error.cinNumberError}
                  purpose="forPan"
                  id="id-input-cin"
                />
                {error.cinNumberError && (
                  <ErrorMessage>Please enter a valid CIN number</ErrorMessage>
                )}
              </div>
            )}

            {selectedValue === "GST & CIN" && (
              <div className="d-flex flex-column mb-4 ">
                <label className={`${Styles.label} flex-grow-1`}>
                  {serviceName === "gstpan"
                    ? "Business PAN Number"
                    : "GSTIN Number"}
                </label>
                <InputField
                  id="id-input-gstin"
                  onChange={isValid}
                  maxLength={serviceName === "gstpan" ? 10 : 15}
                  inputRef={gst_cin_Ref}
                  autocomplete="off"
                  isError={error.gstNumberError}
                  purpose="forGst"
                />
                {error.gstNumberError && (
                  <ErrorMessage>{`Please enter a valid ${
                    serviceName === "gstpan" ? "Business PAN " : "GSTIN"
                  } number`}</ErrorMessage>
                )}
              </div>
            )}

            {selectedValue === "Business Profile" && (
              <div className="d-flex flex-column mb-4 ">
                <label className={`${Styles.label} flex-grow-1`}>
                  CIN Number
                </label>
                <InputField
                  onChange={isValid}
                  maxLength={21}
                  inputRef={business_profile_Ref}
                  autocomplete="off"
                  isError={error.cinNumberError}
                  purpose="forPan"
                  id="id-input-cin"
                />
                {error.cinNumberError && (
                  <ErrorMessage>Please enter a valid CIN number</ErrorMessage>
                )}
              </div>
            )}
          </form>

          <div className="pt-4 pl-4 pb-4 mt-4 d-flex flex-column">
            <div>
              <input
                type="checkbox"
                className="form-check-input"
                onChange={(e: any) => {
                  setIsChecked(e.target.checked);
                  isValid();
                }}
              />
              <p className={`${Styles.ackText}`}>
                I hearby agree, to let zoop.one verify my data for verification
              </p>
            </div>
            {error.consentError && (
              <ErrorMessage>Please provide consent!</ErrorMessage>
            )}
          </div>
          <div className={`${Styles.buttonGroup}`}>
            <Button
              id="id-submit-button-fssai"
              hoveredStyle={primaryButtonHoverStylePopup}
              disabled={isDisabled}
              size={MEDIUM}
              isLoading={loading}
              style={primaryButtonStylePopup}
              onClick={() => {
                setTimeout(() => setError({ ...errorObject }), 2000);
                handleCheck();
              }}
            >
              Run Verification
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MerchantServicePopup;
